import { useTranslation } from 'react-i18next';
import MappedComponent from '../../../../../BOWL-Common/components/MappedComponent';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../../../config/mapping';

const ConnectedPosts = ({ connectedPosts = []}) => {
	const { t } = useTranslation();

	const renderConnectedPosts = () => connectedPosts.map((connectedPost) => (
		<MappedComponent key={`connected_post_${connectedPost.id}`} typeComponentMap={mapping} mapName="postItem" type={connectedPost.template} post={connectedPost} />
	));

	if (connectedPosts.length > 0) {
		return (
			<div className="flex flex-col print:hidden">
				<div className="py-4 pt-10 text-xl font-bold leading-6 font-secondary text-bowl-typo">
					{t('related_posts')}
				</div>
				{renderConnectedPosts()}
			</div>
		);
	}

	return null;
};

export default ConnectedPosts;
