import { makeVar } from '@apollo/client';
import { BowlStorage } from '../../storage/storage';

const initialState = {
	isAuthenticated: false,
	authToken: null,
};

export const isAuthenticatedVar = makeVar(initialState.isAuthenticated);
export const authTokenVar = makeVar(initialState.authToken);

export const setIsAuthenticatedVar = (isAuthenticated) => {
	BowlStorage.storeData('isAuth', isAuthenticated);
	isAuthenticatedVar(isAuthenticated);
};

export const setAuthTokenVar = (authToken) => {
	BowlStorage.storeData('authToken', authToken);
	authTokenVar(authToken);
};

let initialStateSet = false;
export const createAuthExtension = (initialStateIsAuthenticated, initialStateAuthToken) => ({
	name: 'Auth',
	initialState: () => {
		if (initialStateSet === false) {
			isAuthenticatedVar(initialStateIsAuthenticated);
		}
		authTokenVar(initialStateAuthToken);
		initialStateSet = true;
	},
	typePolicies: {
		Query: {
			fields: {
				isAuthenticated: {
					read: () => isAuthenticatedVar(),
				},
				authToken: {
					read: () => authTokenVar(),
				},
			},
		},
	},
});

export default createAuthExtension;
