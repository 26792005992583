import { useEffect } from 'react';
import { setOpenCategoriesVar } from '../../apollo/extensions';

const useOpenCategory = (category, navigationShowTopLevel) => {
	useEffect(() => {
		if (category && navigationShowTopLevel) {
			const parentCategoryId = category?.parentCategory?.id ?? null;
			setOpenCategoriesVar(parentCategoryId);
		}
	}, [category, navigationShowTopLevel]);
};

export default useOpenCategory;
