// eslint-disable-next-line import/no-cycle
import DefaultList from '../components/private/screens/posts/lists/DefaultList';
// eslint-disable-next-line import/no-cycle
import FeaturedItemList from '../components/private/screens/posts/lists/FeaturedItemList';
// eslint-disable-next-line import/no-cycle
import PostDetail from '../components/private/screens/posts/detail/PostDetail';
import PostClinic from '../components/private/screens/posts/tiles/PostClinic';
import PostDefault from '../components/private/screens/posts/tiles/PostDefault';
import PostPlaner from '../components/private/screens/posts/tiles/PostPlaner';
import PostCollapsible from '../components/private/screens/posts/tiles/PostCollapsible';
import PostColleague from '../components/private/screens/posts/tiles/PostColleague';
import PostLatest from '../components/private/screens/posts/tiles/PostLatest';
import { Empty, Content, Table, Contact, Image, JwPlayer, YtPlayer, H1, H2 } from '../components/private/screens/posts/detail/Elements';


const mapping = {
	postItem: {
		default: PostDefault,
		press: PostDefault,
		alert: PostDefault,
		clinic: PostClinic,
		collapsible: PostCollapsible,
		colleague: PostColleague,
		planertile: PostPlaner,
		latest: PostLatest,
	},
	postList: {
		default: DefaultList,
		list_default: DefaultList,
		list_featured_item: FeaturedItemList,
	},
	post: {
		default: PostDetail,
		LINK: PostDetail,
		PDF: PostDetail,
		CONTENT: PostDetail,
	},
	postDetail: {
		default: Empty,
		text: Content,
		table: Table,
		contact: Contact,
		img: Image,
		jwp: JwPlayer,
		youtube: YtPlayer,
		h1: H1,
		h2: H2,
	},
};

export default mapping;
