import BackgroundPattern from '../../partials/BackgroundPattern';

const PublicLayout = ({ children }) => (
	<div className="relative overflow-hidden bg-bowl-public">
		<BackgroundPattern color="text-gray-200" />
		<div className="relative min-h-screen">
			{children}
		</div>
	</div>
);

export default PublicLayout;
