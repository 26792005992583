import LazyLoad from 'react-lazyload';
import useOpenPost from '../../../../../hooks/useOpenPost';
import { ArrowIcon } from '../../../../partials/Icons';
import ChannelWrapper from './partials/ChannelWrapper';
import PostMenu from './partials/PostMenu';
import PostUnreadBadge from './partials/PostUnreadBadge';

const PostColleague = ({ post }) => {
	const openPost = useOpenPost();

	return (
		<div className="bowl-post-button-wrapper">
			<div className="absolute ml-auto top-1 right-1">
				<PostMenu post={post} showPostId={!post.channel} />
			</div>
			<button type="button" onClick={() => openPost(post)} className="bowl-post-button-inner">
				<div className="flex flex-col sm:flex-row">
					<div className="self-center flex-shrink-0">
						{post.teaserImageUrl && <TeaserImage post={post} />}
					</div>
					<div className="flex flex-row items-center flex-grow">
						<div className="flex flex-col flex-1">
							<div className="flex-grow">
								<PostUnreadBadge post={post} />
								<h3 className="self-start bowl-post-headline">
									{post.name}
								</h3>
							</div>
							{post.teaserText && (
								<p className="mt-2 bowl-post-teaser-text">
									{post.teaserText}
								</p>
							)}
						</div>
						<span className="flex-shrink-0 hidden mt-2 ml-4 sm:block">
							<ArrowIcon color="text-bowl-typo" />
						</span>
					</div>
				</div>
			</button>
			{post.channel && <ChannelWrapper post={post} />}
		</div>
	);
};

const TeaserImage = ({ post }) => (
	<div className="mb-3 mr-0 rounded-full bowl-post-teaser-image-wrapper sm:mr-4 sm:mb-0">
		<LazyLoad height="9rem" once placeholder={(<div className="lazy-load-wrapper-colleague"><img src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt={post.name} /></div>)}>
			<div className="lazy-load-wrapper-colleague">
				<img src={`${process.env.REACT_APP_BASE_URL}/api/img/${post.teaserImageUrl}?p=small`} alt={post.name} />
			</div>
		</LazyLoad>
	</div>
);

export default PostColleague;
