import { useEffect } from 'react';
import { useAlerts } from './useAlerts';

const useOpenLatestAlert = (openAlertModal) => {
	const { alerts } = useAlerts();

	useEffect(() => {
		if (alerts?.[0]?.lastSeen === null) {
			openAlertModal(alerts?.[0]?.id);
		}
	}, [alerts, openAlertModal]);
};

export default useOpenLatestAlert;
