import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAllSurveys } from '../../../../BOWL-Common/hooks/surveys/useAllSurveys';
import { SurveyIcon } from '../../../partials/Icons';

const SurveyDisruptor = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { surveys, loading: loadingSurveys } = useAllSurveys();

	const buttonClicked = useCallback(() => {
		if (!loadingSurveys && surveys?.length > 1) {
			navigate('/surveys');
		} else if (!loadingSurveys && surveys?.length === 1) {
			navigate(`/surveys/${surveys[0].id}`);
		}
	}, [loadingSurveys, surveys, navigate]);

	if (!loadingSurveys && surveys?.length > 0) {
		return (
			<button type="button" onClick={() => buttonClicked()} className="text-left shadow-lg bg-bowl-surveys-disruptor-bg fixed bottom-5 z-50 right-5 text-white font-secondary leading-[1.05em] text-lg w-20 h-20 rounded-lg p-2 print:hidden">
				<div className="w-full flex justify-end">
					<SurveyIcon w="w-8" h="h-8" color="text-white" />
				</div>
				<div className="-mt-1.5">
					{t('survey_new')}
					<br />
					{t('survey')}
				</div>
			</button>
		);
	}

	return null;
};

export default SurveyDisruptor;
