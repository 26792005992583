import { useQuery } from '@apollo/client';
import { QUERY_ALERTS } from '../../graphql/alerts';

export const useAlerts = () => {
	const { data, loading, error } = useQuery(QUERY_ALERTS, {
		pollInterval: 120000,
	});

	const alerts = data?.alerts ?? [];
	return { alerts, loading, error };
};
