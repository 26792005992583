import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { useResetOldPasswordMutation } from '../../../BOWL-Common/hooks/auth/useResetOldPasswordMutation';
import { setIsChangePasswordModalOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import { useIsChangePasswordModalOpen } from '../../../BOWL-Common/hooks/global/useIsChangePasswordModalOpen';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../hooks/useOnKeydownEscape';
import { CloseIcon } from '../../partials/Icons';
import SubmitButton from '../../public/screens/partials/SubmitButton';
import ErrorMessage from '../../public/screens/partials/ErrorMessage';
import InputField from '../../public/screens/partials/InputField';
import { H2, H3 } from '../../public/screens/partials/Headlines';
import Modal from '../../general/Modal';

const ChangePasswordModalInner = () => {
	const { t } = useTranslation();

	const ref = useRef();
	useOnClickOutside(ref, () => setIsChangePasswordModalOpenVar(false));
	useOnKeydownEscape(() => setIsChangePasswordModalOpenVar(false));

	useTrackPageViewEffect(t('change_password_title'));

	const [resetOldPassword, { data, loading, error }] = useResetOldPasswordMutation();

	const [oldPassword, setOldPassword] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const [uiState, setUiState] = useState('idle');
	const [errorMessage, setErrorMessage] = useState(null);
	const status = data?.resetOldPassword?.status ?? '';

	useEffect(() => {
		if (loading === true) {
			setUiState('loading');
		} else if (status === '') {
			setUiState('idle');
		} else if (status === 'PASSWORD_UPDATED') {
			setUiState('success');
		} else if (status === 'PASSWORD_NOT_UPDATED') {
			setUiState('error');
			setErrorMessage(data.resetOldPassword.message);
		}
	}, [status, data, loading]);

	useEffect(() => {
		if (error) {
			const { graphQLErrors } = error;
			if (graphQLErrors[0].extensions.category === 'validation') {
				const validationErrors = graphQLErrors[0].extensions.validation;
				setErrorMessage(Object.values(Object.values(validationErrors).map((validationError) => validationError.join('<br />'))).join('<br />'));
			} else {
				setErrorMessage(error.message);
			}
		} else {
			setErrorMessage(null);
		}
	}, [error]);

	const handleResetOldPassword = () => {
		if (oldPassword !== password) {
			setErrorMessage(null);
			resetOldPassword({ variables: { old_password: oldPassword, password, password_confirmation: passwordConfirmation }});
		} else {
			setErrorMessage(t('change_password_error_same'));
		}
	};

	return (
		<div ref={ref} className="inline-block w-full py-4 my-8 overflow-hidden align-middle transition-all transform rounded-lg shadow-xl bg-bowl-light sm:max-w-sm sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<CloseIconButton clickHandler={() => setIsChangePasswordModalOpenVar(false)} />
			<div className="flex flex-col justify-center">
				<div className="px-6 sm:max-w-sm">
					{ uiState !== 'success' && (
						<>
							<H2 title={t('change_password_title')} textAlign="text-left" className="text-bowl-typo-secondary sm:mr-4" />
							<H3 title={t('change_password_subtitle')} textAlign="text-left" className="text-bowl-typo-secondary sm:mr-4" />
						</>
					)}
					{ uiState === 'success' && (
						<>
							<H3 title={t('change_password_success')} textAlign="text-left" className="text-bowl-typo-secondary sm:mr-4" />
							<div className="mt-4 text-center">
								<SubmitButton type="button" title={t('alert_close')} handleClick={() => setIsChangePasswordModalOpenVar(false)} />
							</div>
						</>
					)}
				</div>

				{ uiState !== 'success' && (
					<div className="relative sm:mx-auto sm:w-full sm:max-w-md">
						<div className="p-4 mx-4 mt-4 text-left bg-white rounded-lg shadow">
							<form action="#" method="POST" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
								<InputField fieldName="old_password" label={t('change_password_old_password')} type="password" value={oldPassword} placeholder={t('change_password_old_password_placeholder')} handleChange={setOldPassword} />

								<div className="mt-4">
									<InputField fieldName="password" label={t('change_password_password')} type="password" value={password} placeholder={t('change_password_password_placeholder')} handleChange={setPassword} />
								</div>

								<div className="mt-4">
									<InputField fieldName="password_confirmation" label={t('change_password_password_confirmation')} type="password" value={passwordConfirmation} placeholder={t('change_password_password_confirmation_placeholder')} handleChange={setPasswordConfirmation} />
								</div>
								<ErrorMessage message={errorMessage} isHtml />
								<div className="mt-6">
									<SubmitButton type="submit" title={t('change_password_button')} loading={loading} disabled={oldPassword === '' || password === '' || passwordConfirmation === ''} handleClick={handleResetOldPassword} />
								</div>
								<div className="mt-2">
									<SubmitButton type="button" title={t('alert_close')} handleClick={() => setIsChangePasswordModalOpenVar(false)} />
								</div>
							</form>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const CloseIconButton = ({ clickHandler }) => (
	<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
		<button type="button" onClick={clickHandler} className="transition duration-150 ease-in-out text-bowl-dark hover-hover:hover:text-bowl-main focus:text-bowl-main focus:outline-none " aria-labelledby="close">
			<CloseIcon />
		</button>
	</div>
);

const ChangePasswordModal = () => {
	const { isChangePasswordModalOpen } = useIsChangePasswordModalOpen();

	return (
		<Modal show={isChangePasswordModalOpen}>
			{/* This element is to trick the browser into centering the modal contents. */}
			<span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
			<ChangePasswordModalInner />
		</Modal>
	);
};


export default ChangePasswordModal;
