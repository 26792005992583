import { useTranslation } from 'react-i18next';
import { H2 } from '../public/screens/partials/Headlines';

const LoadingScreen = ({ bgColor = 'bg-transparent' }) => {
	const { t } = useTranslation();

	return (
		<div className={`flex flex-col items-center justify-center w-full h-screen ${bgColor}`}>
			<img src={`${process.env.REACT_APP_BASE_URL}/logo`} alt="Logo" className="object-contain w-24 h-24" />
			<div className="flex items-center justify-center">
				<H2 className="pl-1 mr-2 text-bowl-typo-secondary" title={t('loading')} />
				<div className="w-6 h-6 spinner" />
			</div>
		</div>
	);
};

export default LoadingScreen;
