import { useQuery } from '@apollo/client';
import { QUERY_SURVEY_BY_ID } from '../../graphql/surveys';

export const useSurveyById = ({ surveyId }) => {
	const { data, loading, error } = useQuery(QUERY_SURVEY_BY_ID, {
		variables: { id: surveyId },
		skip: surveyId === null,
	});

	const survey = data?.survey ?? null;
	return { survey, loading, error };
};
