import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Languages from './languages';

i18n
	.use(initReactI18next)
	.init({
		compatibilityJSON: 'v3',
		resources: {
			en: {
				translations: Languages.en,
			},
			de: {
				translations: Languages.de,
			},
		},
		lng: 'de',
		fallbackLng: 'en',
		// debug: process.env.NODE_ENV !== 'production',
		debug: false,

		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false,
		},

		react: {
			useSuspense: false,
		},
	});

export default i18n;
