const environment = () => {
	if (typeof document !== 'undefined') {
		return 'WEB';
	}
	if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
		return 'MOBILE';
	}

	return 'OTHER';
};

export default environment();
