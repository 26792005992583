import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTrackPageViewEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { useSurveyById } from '../../../../BOWL-Common/hooks/surveys/useSurveyById';
import { formatDatetime } from '../../../../BOWL-Common/utils/dates';
import { useSubmitSurvey } from '../../../../BOWL-Common/hooks/surveys/useSubmitSurvey';
import SubmitButton from '../../../public/screens/partials/SubmitButton';

const SurveyDetail = () => {
	const { id: surveyId } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();

	useTrackPageViewEffect(t('surveys_detail'));

	const { survey, loading, error } = useSurveyById({ surveyId });
	const [submitSurvey, { data: submitData, loading: submitLoading }] = useSubmitSurvey();

	const surveyCompleted = submitData?.answerSurvey?.success === true;

	const [answers, setAnswers] = useState(null);

	const answerQuestion = useCallback((answer) => {
		setAnswers((a) => ({ ...a, ...answer }));
	}, []);

	const submitForm = useCallback(() => {
		const answersJson = JSON.stringify({ answers });
		submitSurvey({
			variables: { surveyId, answers: answersJson },
		});
	}, [submitSurvey, surveyId, answers]);

	useEffect(() => {
		if (!survey && loading === false && !error) {
			navigate('/404');
		}
	}, [survey, loading, error, navigate]);

	return (
		<>
			{surveyCompleted && (
				<div className="flex items-center justify-center w-full h-full p-8">
					<div className="text-6xl font-bold text-bowl-main font-secondary">
						{t('survey_completed')}
					</div>
				</div>
			)}
			{survey && !loading && !error && !surveyCompleted && (
				<div className="relative h-full px-4 py-3 pb-8 sm:px-2 lg:px-4 max-w-165">
					<div>
						<div className="pb-4 text-xs text-bowl-surveys-detail-date">
							{survey.startDate && formatDatetime(survey.startDate)}
						</div>
						<div className="pb-4 text-xl font-bold leading-6 text-bowl-main font-secondary">
							{survey.title}
						</div>
						<div className="pb-12 text-base leading-6 text-bowl-main">
							{survey.description}
						</div>
						<div>
							{survey.questions && survey.questions.map((question, index) => (
								<QuestionWrapper key={question.key} question={question} number={index + 1} answerCallback={answerQuestion}>
									<div />
								</QuestionWrapper>
							))}
						</div>
						<div className="flex justify-center">
							<div className="max-w-[250px] w-full">
								<SubmitButton type="submit" title={t('survey_submit')} loading={submitLoading} disabled={answers === null} handleClick={submitForm} />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SurveyDetail;

const QuestionWrapper = ({ question, number, answerCallback }) => {
	const questionText = question?.attributes?.question ?? '';
	const questionLayout = question?.layout;
	const questionKey = question?.key;
	const questionAttributes = question?.attributes;

	return (
		<div className="flex pb-6 mb-6 border-b border-bowl-gray-100 last:border-none">
			<div className="text-bowl-surveys-numbers text-[44px] font-bold font-secondary pr-5 min-w-[80px]">
				{number !== null && (
					`${number}.`
				)}
			</div>
			<div className="flex-grow">
				<div className="text-bowl-main text-[19px] leading-6 font-bold font-secondary pb-8 pt-2">
					{questionText}
				</div>
				<div>
					{questionLayout === 'survey-question-text' && (
						<SurveyTextAnswer questionKey={questionKey} answerCallback={answerCallback} />
					)}
					{questionLayout === 'survey-question-choice' && (
						<SurveyChoiceAnswer questionKey={questionKey} questionAttributes={questionAttributes} answerCallback={answerCallback} />
					)}
				</div>
			</div>
		</div>
	);
};

const SurveyTextAnswer = ({ questionKey, answerCallback }) => {
	const { t } = useTranslation();
	const [textAnswer, setTextAnswer] = useState('');

	useEffect(() => {
		if (questionKey && answerCallback) {
			const answerObject = {
				[questionKey]: {
					key: questionKey,
					answer: textAnswer,

				},
			};
			answerCallback(answerObject);
		}
	}, [textAnswer, questionKey, answerCallback]);

	return (
		<textarea
			className="border border-bowl-main resize-none text-bowl-main h-[80px] rounded w-full px-2 py-1 text-[14px] leading-tight"
			placeholder={t('survey_answer')}
			value={textAnswer}
			onChange={(e) => setTextAnswer(e.target.value)}
		/>
	);
};

const SurveyChoiceAnswer = ({ questionKey, questionAttributes, answerCallback }) => {
	const type = questionAttributes?.type;
	const options = questionAttributes?.options;

	const [selectedOptions, setSelectedOptions] = useState([]);
	const optionClicked = useCallback(({ optionKey }) => {
		if (optionKey !== null) {
			setSelectedOptions((selOptions) => {
				let newSelectedOptions = [...selOptions];
				if (newSelectedOptions.includes(optionKey)) {
					newSelectedOptions = newSelectedOptions.filter((selOption) => selOption !== optionKey);
				} else if (type === 'single_choice') {
					newSelectedOptions = [optionKey];
				} else if (type === 'multiple_choice') {
					newSelectedOptions.push(optionKey);
				}
				return newSelectedOptions;
			});
		}
	}, [type]);

	useEffect(() => {
		if (questionKey && answerCallback) {
			const answerObject = {
				[questionKey]: {
					key: questionKey,
					options: selectedOptions,

				},
			};
			answerCallback(answerObject);
		}
	}, [selectedOptions, questionKey, answerCallback]);

	return (
		<div className="flex flex-col flex-wrap w-full gap-2">
			{options && options.map((option) => (
				<div key={option?.key} className="flex-grow min-w-[80px] w-full sm:w-auto">
					{option.attributes?.option && (
						<button
							type="button"
							className={`border border-bowl-main rounded px-4 py-2 w-full h-full text-sm leading-4
							${selectedOptions.includes(option.key) ? 'bg-bowl-main text-white' : 'bg-white text-bowl-main'}
							`}
							onClick={() => optionClicked({ optionKey: option?.key })}
						>
							{option?.attributes?.option}
						</button>
					)}
				</div>
			))}

		</div>
	);
};
