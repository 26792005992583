import { format, addDays, parseISO, isMatch } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import i18next from 'i18next';

export const formatDate = (datetime) => {
	const currentLocale = i18next.language === 'de' ? de : enUS;
	return format(parseISO(datetime), 'dd.MM.yyyy', { locale: currentLocale });
};

export const formatDatetime = (datetime) => {
	const currentLocale = i18next.language === 'de' ? de : enUS;
	if (isMatch(datetime, "yyyy-MM-dd'T'HH:mm:ss") || isMatch(datetime, 'yyyy-MM-dd HH:mm:ss')) {
		return format(parseISO(datetime), 'dd.MM.yyyy HH:mm', { locale: currentLocale });
	}
	return format(parseISO(datetime), 'dd.MM.yyyy', { locale: currentLocale });
};

export const getExclusiveDate = (datetime) => {
	const currentLocale = i18next.language === 'de' ? de : enUS;
	return format(addDays(parseISO(datetime), -1), 'dd.MM.yyyy', { locale: currentLocale });
};

export const getIsoString = (datetime, exclusive = false) => {
	let date = new Date(datetime);
	if (exclusive) {
		date = addDays(date, -1);
	}
	return date.toISOString();
};
