import { useQuery } from '@apollo/client';
import { QUERY_IS_NOTE_MODAL_OPEN } from '../../graphql/global';

export const useIsNoteModalOpen = () => {
	const { data, loading, error } = useQuery(QUERY_IS_NOTE_MODAL_OPEN);
	const isNoteModalOpen = data?.isNoteModalOpen ?? false;
	const noteId = data?.noteModalId ?? null;
	const postId = data?.noteModalPostId ?? null;
	return {
		isNoteModalOpen, noteId, postId, loading, error,
	};
};
