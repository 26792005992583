import { useEffect, useState } from 'react';
import { BowlStorage } from '../../storage/storage';

const useInitializeLocalStorage = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	const [authToken, setAuthToken] = useState(null);

	useEffect(() => {
		if (isAuthenticated === null) {
			BowlStorage.loadData('isAuth').then((value) => {
				setIsAuthenticated(value === true);
			}).catch((err) => {
				setIsAuthenticated(false);
			});
		}

		if (authToken === null) {
			BowlStorage.loadData('authToken').then((value) => {
				setAuthToken(value);
			}).catch((err) => {
				setAuthToken(false);
			});
		}
	}, []);

	return { isAuthenticated, authToken };
};

export default useInitializeLocalStorage;
