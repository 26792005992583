import MappedComponent from '../../../../../BOWL-Common/components/MappedComponent';
// eslint-disable-next-line import/no-cycle
import PostList from './DefaultList';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../../../config/mapping';

const FeaturedItemList = ({ posts }) => {
	if (posts.length > 0) {
		return (
			<div className="PostListFeaturedItem">
				<div className="pb-2 mb-4">
					<MappedComponent typeComponentMap={mapping} mapName="postItem" type="standard" post={posts[0]} />
				</div>
				<PostList posts={posts.slice(1)} />
			</div>
		);
	}

	return (
		<span>No contents available</span>
	);
};

export default FeaturedItemList;
