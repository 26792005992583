import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackPageViewEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { useAllSurveys } from '../../../../BOWL-Common/hooks/surveys/useAllSurveys';
import { DashboardContentLoader } from '../../../partials/ContentLoader';
import SurveyListItem from './SurveyListItem';

const Surveys = () => {
	const { t } = useTranslation();

	useTrackPageViewEffect(t('surveys_title'));

	const { surveys, loading: loadingSurveys } = useAllSurveys();

	return (
		<div className="px-2 sm:px-4">
			<div className="flex justify-between">
				<h2 className="pt-2 pb-4 text-2xl text-bowl-typo-secondary">
					<span className="font-bold font-secondary text-33">{t('surveys_title')}</span>
				</h2>
			</div>
			<div className="p-2 px-4 mb-4 overflow-hidden bg-white rounded-lg shadow">
				{loadingSurveys && (
					<>
						<DashboardContentLoader />
						<DashboardContentLoader />
						<DashboardContentLoader />
						<DashboardContentLoader />
					</>
				)}
				{!loadingSurveys && surveys?.map((survey) => <SurveyListItem survey={survey} key={survey.id} />)}
				{!loadingSurveys && surveys?.length === 0 && (
					<div className="py-4">{t('no_surveys')}</div>
				)}
			</div>
		</div>
	);
};

export default Surveys;
