import { gql } from '@apollo/client';

export const FRAGMENT_ALERT_FIELDS = gql`
	fragment AlertFields on Alert {
		id
		title
		message
		date
		lastSeen
		pinned
		post {
			id
			postId
			name
			teaserText
			teaserImageUrl
			template
			displayDate
			releasedAt
			publishedAt
			type
			isVideo
			content
			url
			pdfUrl
			docUrl
			channel {
				id
				name
				imageUrl
			}
			category {
				id
				name
				imageUrl
				showAzList
				increasesBadgeCount
				singlePost {
					id
				}
				parentCategory {
					id
					name
					imageUrl
				}
			}
			filters {
				id
				title
			}
		}
		event {
			id
			title
			description
			content
			start
			end
			endCorrect
			allDay
			color
			subcalendarSortOrder
			pinned
			subcalendar {
				id
				name
				color
			}
			connectedPosts {
				id
				postId
				name
				teaserText
				teaserImageUrl
				template
				displayDate
				releasedAt
				publishedAt
				type
				isVideo
				content
				url
				pdfUrl
				docUrl
				channel {
					id
					name
					imageUrl
				}
				category {
					id
					increasesBadgeCount
				}
			}
		}
	}
`;

export const QUERY_ALERTS = gql`
	query Alerts {
		alerts(orderBy: [{
			field: RELEASED_AT
			order: DESC
		}]) {
			...AlertFields
		}
	}
	${FRAGMENT_ALERT_FIELDS}
`;

export const QUERY_ALERT_BY_ID = gql`
	query Alert($id: ID!) {
		alert(id: $id) {
			...AlertFields
		}
	}
	${FRAGMENT_ALERT_FIELDS}
`;

export const QUERY_LATEST_ALERT = gql`
	query latestAlert {
		latestAlert {
			...AlertFields
		}
	}
	${FRAGMENT_ALERT_FIELDS}
`;

export const QUERY_LAST_ALERT_TIMESTAMP = gql`
	query latestAlertTimestamp {
		latestAlertTimestamp @client
	}
`;
