import { useTranslation } from 'react-i18next';
import { setIsNoteModalOpenVar } from '../../../../BOWL-Common/apollo/extensions';
import { useAllNotes } from '../../../../BOWL-Common/hooks/notes/useAllNotes';
import { useTrackPageViewEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { DashboardContentLoader } from '../../../partials/ContentLoader';
import { PlusCircleBorderIcon } from '../../../partials/Icons';
import NoteListItem from './NoteListItem';

const NoteOverview = () => {
	const { t } = useTranslation();

	useTrackPageViewEffect(t('notes_title'));

	const { notes, loading: loadingNotes } = useAllNotes();

	return (
		<div className="px-2 sm:px-4">
			<div className="flex justify-between">
				<h2 className="py-2 text-2xl text-bowl-typo-secondary">
					<span className="font-bold font-secondary text-33">{t('notes_title')}</span>
				</h2>
				<div className="flex">
					<button
						type="button"
						onClick={() => setIsNoteModalOpenVar(true)}
						className="self-center p-2 transition duration-150 ease-in-out rounded-md ml-14 text-bowl-topbar hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar print:hidden"
						aria-labelledby="open-note"
						aria-expanded="false"
					>
						<PlusCircleBorderIcon w="w-6" h="h-6" color="text-bowl-topbar" />
					</button>
				</div>
			</div>

			<div className="p-2 px-4 mb-4 overflow-hidden bg-white rounded-lg shadow">
				{loadingNotes && (
					<>
						<DashboardContentLoader />
						<DashboardContentLoader />
						<DashboardContentLoader />
						<DashboardContentLoader />
					</>
				)}
				{!loadingNotes && notes?.map((note) => <NoteListItem note={note} key={note.id} />)}
				{!loadingNotes && notes?.length === 0 && (
					<div className="py-4">{t('no_notes')}</div>
				)}
			</div>
		</div>
	);
};

export default NoteOverview;
