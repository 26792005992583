import { setIsSidebarOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import { useIsSidebarOpen } from '../../../BOWL-Common/hooks/global/useIsSidebarOpen';
import Navigation from './Navigation';
import Transition from '../../partials/Transition';
import useOnKeydownEscape from '../../../hooks/useOnKeydownEscape';
import { CloseIcon } from '../../partials/Icons';

const Sidebar = () => (
	<>
		<MobileSidebar />
		<DesktopSidebar />
	</>
);

const DesktopSidebar = () => (
	<div className="fixed bottom-0 z-20 hidden border-r shadow border-bowl-topbar top-16 md:flex print:hidden">
		<div className="flex flex-col flex-1 bg-bowl-navigation w-72">
			<div className="flex-1 h-0 overflow-y-auto">
				<nav className="flex-1 pb-4">
					<Navigation />
				</nav>
			</div>
		</div>
	</div>
);

const MobileSidebar = () => {
	const { isSidebarOpen } = useIsSidebarOpen();
	const closeSidebar = () => setIsSidebarOpenVar(false);
	useOnKeydownEscape(() => closeSidebar());

	return (
		<Transition show={isSidebarOpen}>
			<div className="md:hidden print:hidden">
				<div className="fixed inset-0 z-40 flex">
					{/* Off-canvas menu overlay, show/hide based on off-canvas menu state. */}
					<Transition
						enter="transition-opacity ease-linear duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div role="button" onClick={() => closeSidebar()} onKeyDown={() => closeSidebar()} tabIndex={0} className="fixed inset-0">
							<div className="absolute inset-0 bg-gray-600 opacity-75" />
						</div>
					</Transition>
					{/* Off-canvas menu, show/hide based on off-canvas menu state. */}
					<Transition
						enter="transition ease-in-out duration-500 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-500 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<div className="relative flex flex-col flex-1 w-full max-w-xs pb-4 shadow bg-bowl-navigation">
							<div className="pt-2">
								<button type="button" onClick={() => closeSidebar()} className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none" aria-labelledby="close-sidebar">
									<CloseIcon color="text-bowl-categorytypo" />
								</button>
							</div>
							<div className="flex-1 h-0 overflow-y-auto">
								<nav className="">
									<Navigation />
								</nav>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</Transition>
	);
};

export default Sidebar;
