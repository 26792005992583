import { useTranslation } from 'react-i18next';
import { setIsNoteModalOpenVar } from '../../../../../BOWL-Common/apollo/extensions';
import { useAllNotes } from '../../../../../BOWL-Common/hooks/notes/useAllNotes';
import { DashboardContentLoader } from '../../../../partials/ContentLoader';
import { NotesIcon, PlusCircleIcon } from '../../../../partials/Icons';
import NoteListItem from '../../notes/NoteListItem';
import WidgetBox from '../partials/WidgetBox';

const LatestNotes = () => {
	const { t } = useTranslation();

	const { notes, loading: loadingNotes, error: errorNotes } = useAllNotes();

	const actionButton = () => (
		<button type="button" onClick={() => setIsNoteModalOpenVar(true)}>
			<PlusCircleIcon w="w-8" h="h-8" color="text-white" />
		</button>
	);

	const icon = () => (
		<NotesIcon w="w-6" h="h-6" color="text-white" />
	);

	return (
		<WidgetBox title="notes_and_bookmarks" icon={icon} moreText="all_notes" moreLink="/notes" actionButton={actionButton} backgroundColor="bg-bowl-notes-box-bg">
			<div className="p-2 px-4 mb-4 overflow-hidden bg-white rounded-lg shadow">
				{loadingNotes && (
					<>
						<DashboardContentLoader />
						<DashboardContentLoader />
						<DashboardContentLoader />
						<DashboardContentLoader />
					</>
				)}
				{!loadingNotes && !errorNotes && notes && notes.length > 0 && (
					<div className="focus:outline-none">
						<div className="">
							{notes.filter((item, idx) => idx < 4).map((note) => <NoteListItem note={note} key={note.id} />)}
						</div>
					</div>
				)}
				<div className="">
					{!loadingNotes && !errorNotes && notes && notes.length === 0 && <CategoryMessage title={t('no_notes')} />}
				</div>
			</div>
		</WidgetBox>
	);
};

const CategoryMessage = ({ title }) => (
	<div className="m-2">
		<p className="text-base bowl-post-teaser-text">{title}</p>
	</div>
);

export default LatestNotes;
