import { useRef } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConnectedPosts from '../posts/detail/ConnectedPosts';
import { ArrowIcon, CalendarIcon, PrintIcon } from '../../../partials/Icons';
import MappedComponent from '../../../../BOWL-Common/components/MappedComponent';
import useParseContentRows from '../../../../BOWL-Common/hooks/posts/useParseContentRows';
import mapping from '../../../../config/mapping';
import { formatDatetime } from '../../../../BOWL-Common/utils/dates';
import EventShareMenu from './EventShareMenu';

// TODO: Better layout?
const EventDetail = ({ event }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { subcalendar } = event;

	const componentRef = useRef();
	const handlePrint = () => window.print();
	const { contentRows, parsingError } = useParseContentRows({ post: event });

	const renderContent = () => contentRows?.map((contentRow) => (
		<MappedComponent key={uuidv1()} typeComponentMap={mapping} mapName="postDetail" type={contentRow.type} {...contentRow} />
	));

	// TODO: Pinned style?
	// TODO: Create/Updated date?
	return (
		<div className="p-6 pt-0 bowl-post-button-wrapper print:p-0">
			{parsingError && (
				<h4>
					Error occured parsing post:
					<br />
					{parsingError}
					<br />
					{event?.content}
				</h4>
			)}
			{!parsingError && (
				<>
					<div className="flex py-2 -mx-4 print:p-0">
						<button
							type="button"
							onClick={() => navigate('/calendar')}
							className="p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-light focus:outline-none focus:bg-bowl-light focus:text-bowl-topbar print:hidden"
							aria-labelledby={t('back')}
							aria-expanded="false"
							title={t('back')}
						>
							<div className="flex flex-row items-center text-sm">
								<ArrowIcon direction="rotate-90" />
								<span className="mb-px mr-1">{t('back')}</span>
							</div>
						</button>
						<div className="flex items-center justify-between ml-auto">
							{/* TODO: Event Id? */}
							{/* <PostId postId={post.postId} big /> */}
							<div className="flex flex-row items-center justify-center gap-x-1">
								<button
									type="button"
									onClick={() => handlePrint()}
									className="p-2 ml-1 transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-light focus:outline-none focus:bg-bowl-light focus:text-bowl-topbar print:hidden"
									aria-labelledby="print"
									aria-expanded="false"
									title="Print"
								>
									<PrintIcon />
								</button>
								<EventShareMenu event={event} />
							</div>
						</div>
					</div>
					<div ref={componentRef}>
						<div className="flex items-start">
							<div style={{ backgroundColor: subcalendar.color }} className="flex items-center justify-center flex-shrink-0 w-10 h-10 mt-1 text-white rounded-full">
								<CalendarIcon />
							</div>
							<div className="ml-4 text-left text-bowl-typo-secondary">
								<p className="text-2xl leading-7 font-secondary text-bowl-typo">
									{`${formatDatetime(event?.start)}${event?.end ? ` - ${formatDatetime(event.end)}` : ''} `}
								</p>
								{event.title && (
									<h1 className="mt-1 text-2xl font-bold leading-7 font-secondary text-bowl-typo">{event.title}</h1>
								)}
								{event.subcalendar?.name && (
									<p style={{ color: subcalendar.color }} className="mt-1 text-sm font-bold leading-4">
										{subcalendar.name}
									</p>
								)}
							</div>
						</div>
						{contentRows && (
							<div className="flex flex-col max-w-165 print:max-w-none">
								{renderContent()}
							</div>
						)}
					</div>
					<ConnectedPosts connectedPosts={event.connectedPosts} />
				</>
			)}
		</div>
	);
};

export default EventDetail;
