import { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import MappedComponent from '../../../BOWL-Common/components/MappedComponent';
import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { useTrackInternalPostOpenEffect } from '../../../BOWL-Common/hooks/tracking/useTrackInternalPostOpenEffect';
import { QUERY_POST_BY_ID, QUERY_TRANSLATED_POST } from '../../../BOWL-Common/graphql/posts';
import { setActiveCategoryVar } from '../../../BOWL-Common/apollo/extensions/categories';
import { PostContentLoader } from '../../partials/ContentLoader';
import { OpenCategoryEffect } from '../../partials/OpenCategoryEffect';
import ErrorScreen from '../../general/ErrorScreen';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../config/mapping';

const Post = ({ post, category }) => {
	const client = useApolloClient();

	const parentCategoryId = category?.parentCategory?.id;

	useEffect(() => {
		setActiveCategoryVar(category.id);
	}, [category.id]);

	useEffect(() => () => {
		setActiveCategoryVar(null);
		client.query({
			query: QUERY_TRANSLATED_POST,
			variables: { postId: post.id, language: 'de' },
			fetchPolicy: 'no-cache',
		}).then(({ data }) => {
			const translatedPost = data?.translatePost ?? null;
			client.writeQuery({
				query: QUERY_TRANSLATED_POST,
				variables: { postId: post.id, language: 'de' },
				data: { translatePost: translatedPost },
			});
		});
	}, []); // Don't add dependencies

	useTrackPageViewEffect(`Post: ${post.name}`);
	useTrackInternalPostOpenEffect(post);

	return (
		<>
			<OpenCategoryEffect parentCategoryId={parentCategoryId} />
			<MappedComponent typeComponentMap={mapping} post={post} mapName="post" type={post.type} />
		</>
	);
};

const PostWrapper = () => {
	const { id } = useParams();
	const { data, loading, error, called } = useQuery(QUERY_POST_BY_ID, { variables: { id }});
	const post = data?.post;
	const category = post?.category;

	if (error) {
		return (<ErrorScreen error={error} />);
	}

	if (called && !loading && !error && post && category) {
		return <Post post={post} category={category} />;
	}

	if (called && !loading && !post && !error) {
		return (<Navigate to="/" replace />);
	}

	return (<PostContentLoader />);
};

export default PostWrapper;
