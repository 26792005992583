import { useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { QUERY_CATEGORY_DEFAULT } from '../../../../BOWL-Common/graphql/categories';
import useSetActiveCategory from '../../../../BOWL-Common/hooks/categories/useSetActiveCategory';
import ErrorScreen from '../../../general/ErrorScreen';
import LoadingScreen from '../../../general/LoadingScreen';
import CategoryWrapper from './CategoryWrapper';
import useOpenPost from '../../../../hooks/useOpenPost';

const DefaultCategory = () => {
	const openPost = useOpenPost();

	const { data, loading, error, called } = useQuery(QUERY_CATEGORY_DEFAULT);
	const category = data?.categoryDefault?.[0] ?? null;
	const singlePost = category?.singlePost?.[0] ?? null;

	useEffect(() => {
		if (called && !loading && !error && singlePost) {
			openPost(singlePost);
		}
	}, [called, loading, error, singlePost, openPost]);

	useSetActiveCategory({ categoryId: category?.id });

	if (error) {
		return (<ErrorScreen error={error} />);
	}

	if (called && !loading && !singlePost && !error) {
		if (category) {
			return <CategoryWrapper category={category} />;
		}
		return (<Navigate to="/overview" replace />);
	}

	return (<LoadingScreen />);
};

export default DefaultCategory;
