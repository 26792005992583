import { useEffect } from 'react';
import { openCategoriesVar, setOpenCategoriesVar } from '../../BOWL-Common/apollo/extensions/categories';

export const OpenCategoryEffect = ({ parentCategoryId }) => {
	useEffect(() => {
		const openCategories = openCategoriesVar();
		if (process.env.REACT_APP_NAVIGATION_SHOW_TOPLEVEL === 'true' && parentCategoryId && !openCategories.includes(parentCategoryId)) {
			setOpenCategoriesVar(parentCategoryId);
		}
	}, [parentCategoryId]);

	return null;
};

export default {
	OpenCategoryEffect,
};
