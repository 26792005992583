import { memo } from 'react';

const InputField = ({
	fieldName = '', label = '', type = 'text', value = '', placeholder = '', handleChange,
}) => (
	<div>
		<label htmlFor={fieldName} className="block mx-1 text-sm leading-5 text-bowl-typo">
			{label}
		</label>
		<div className="mt-1 rounded-md shadow-sm">
			<input
				id={fieldName}
				type={type}
				required
				className="block w-full px-3 py-2 text-gray-900 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-orange focus:border-orange-300 sm:text-sm sm:leading-5"
				onChange={(e) => handleChange(e.target.value)}
				value={value}
				placeholder={placeholder}
			/>
		</div>
	</div>
);

export default memo(InputField);
