import { memo } from 'react';

const SubmitButton = ({
	title = '', loading = false, handleClick, disabled = false, type = 'button',
}) => (
	<span className="relative block w-full rounded-md shadow-sm">
		{ loading && <LoadingSpinner /> }
		<button
			disabled={disabled}
			// eslint-disable-next-line react/button-has-type
			type={type}
			onClick={() => handleClick()}
			className="flex justify-center w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-bowl-dark hover-hover:hover:bg-bowl-main focus:outline-none focus:ring-orange disabled:opacity-50 disabled:cursor-not-allowed"
		>
			{title}
		</button>
	</span>
);

const LoadingSpinner = () => (
	<div className="absolute inset-0 z-10 flex items-center justify-center rounded bg-white-90">
		<div className="w-6 h-6 spinner" />
	</div>
);

export default memo(SubmitButton);
