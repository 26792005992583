import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFilterUnreadPosts from '../../../../../BOWL-Common/hooks/posts/useFilterUnreadPosts';
import { useNewestPosts } from '../../../../../BOWL-Common/hooks/posts/useNewestPosts';
import { CategoryContentLoader } from '../../../../partials/ContentLoader';
import WidgetBox from '../partials/WidgetBox';
import { LatestIcon } from '../../../../partials/Icons';
import PostMinimal from '../../posts/tiles/PostMinimal';

const LatestPosts = () => {
	const { t } = useTranslation();
	const [isFilterActive, setIsFilterActive] = useState(true);

	const { posts: newestPosts, loading, error } = useNewestPosts({ count: 30 });
	const unreadPosts = useFilterUnreadPosts({ posts: newestPosts }).filter((p, i) => i < 3);

	const posts = isFilterActive ? unreadPosts : newestPosts.filter((p, i) => i < 3);

	const icon = () => (
		<LatestIcon w="w-8" h="h-8" color="text-white" />
	);

	return (
		<WidgetBox title="latest_title" icon={icon} moreText="latest_title" moreLink="/latest" backgroundColor="bg-bowl-posts-box-bg">
			<div className="">
				<div className="flex pb-4">
					<button
						type="button"
						role="tab"
						aria-selected={isFilterActive}
						onClick={() => setIsFilterActive(true)}
						className={`px-1 text-center border-b-2 border-solid focus:outline-none ${isFilterActive ? ' border-white text-white' : 'border-transparent text-white-60 hover-hover:hover:text-white-90 hover-hover:hover:border-white-60'}`}
						aria-labelledby={t('unread_posts')}
						aria-controls={t('unread_posts')}
						title={t('unread_posts')}
					>
						<h2 className="pb-0.5 font-bold font-secondary text-xl">
							{t('unread_posts')}
						</h2>
					</button>
					<button
						type="button"
						role="tab"
						aria-selected={!isFilterActive}
						onClick={() => setIsFilterActive(false)}
						className={`ml-6 px-1 text-center border-b-2 border-solid focus:outline-none ${!isFilterActive ? ' border-white text-white' : 'border-transparent text-white-60 hover-hover:hover:text-white-90 hover-hover:hover:border-white-60'}`}
						aria-labelledby={t('all_posts')}
						aria-controls={t('all_posts')}
						title={t('all_posts')}
					>
						<h2 className="pb-0.5 font-bold font-secondary text-xl">
							{t('all_posts')}
						</h2>
					</button>
				</div>
				<div className="p-2 px-4 mb-4 overflow-hidden bg-white rounded-lg shadow">
					{loading && (
						<>
							<CategoryContentLoader />
							<CategoryContentLoader />
							<CategoryContentLoader />
							<CategoryContentLoader />
							<CategoryContentLoader />
						</>
					)}
					{!loading && !error && posts && posts.length > 0 && (
						<div className="focus:outline-none">
							<div className="">
								{posts.map((post) => (
									<div key={`post_${post.id}`} className="pb-1 mb-1 border-b border-gray-100 last:pb-0 last:mb-0 last:border-none">
										<PostMinimal post={post} />
									</div>
								))}
							</div>
						</div>
					)}
					<div className="">
						{!loading && !error && posts && posts.length === 0 && isFilterActive && <CategoryMessage title={t('category_no_unread_posts')} />}
						{!loading && !error && posts && posts.length === 0 && !isFilterActive && <CategoryMessage title={t('category_no_posts')} />}
					</div>
				</div>
			</div>
		</WidgetBox>
	);
};

const CategoryMessage = ({ title }) => (
	<div className="m-2">
		<p className="text-base bowl-post-teaser-text">{title}</p>
	</div>
);

export default LatestPosts;
