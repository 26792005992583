import { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setIsNoteModalOpenVar } from '../../../../BOWL-Common/apollo/extensions';
import { useIsNoteModalOpen } from '../../../../BOWL-Common/hooks/global/useIsNoteModalOpen';
import { useCreateNote } from '../../../../BOWL-Common/hooks/notes/useCreateNote';
import { useDeleteNote } from '../../../../BOWL-Common/hooks/notes/useDeleteNote';
import { useEditNote } from '../../../../BOWL-Common/hooks/notes/useEditNote';
import { useNoteWithId } from '../../../../BOWL-Common/hooks/notes/useNoteWithId';
import { usePostWithId } from '../../../../BOWL-Common/hooks/posts/usePostWithId';
import { formatDatetime } from '../../../../BOWL-Common/utils/dates';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../../hooks/useOnKeydownEscape';
import Modal from '../../../general/Modal';
import { CheckmarkIcon, CloseIcon, NoteIcon } from '../../../partials/Icons';
import PostMinimal from '../posts/tiles/PostMinimal';

const NoteModalInner = ({ noteId, postId }) => {
	const { t } = useTranslation();

	const creationModeActive = noteId === null;

	const [editModeActive, setEditModeActive] = useState(creationModeActive);
	const [noteText, setNoteText] = useState('');

	const { note, loading: loadingNote, errorNote } = useNoteWithId({ noteId });
	const { post: loadedPost } = usePostWithId({ postId });
	const post = note?.post ?? loadedPost;

	const [editNote, { data, loading: loadingEditNote, error: errorEditNote }] = useEditNote({ noteId: note?.id, text: noteText });
	const [createNote, { data: createdNote, loading: loadingCreateNote, error: errorCreateNote }] = useCreateNote({ text: noteText, postId });
	const [deleteNote, { data: deletedData, loading: loadingDeleteNote, error: errorDeleteNote }] = useDeleteNote({ noteId });

	useEffect(() => {
		if (note?.text) {
			setNoteText(note.text);
		}
	}, [note]);

	const cancelEdit = useCallback(() => {
		setNoteText(note?.text || '');
		setEditModeActive(false);
	}, [note]);

	const saveEdit = useCallback(() => {
		editNote();
		setEditModeActive(false);
	}, [editNote]);

	const saveNewNote = useCallback(() => {
		if (post?.id || noteText !== '') {
			createNote();
			setIsNoteModalOpenVar(false);
		}
	}, [createNote, noteText, post]);

	const eraseNote = useCallback(() => {
		deleteNote();
		setIsNoteModalOpenVar(false);
	}, [deleteNote]);

	const ref = useRef();
	useOnClickOutside(ref, () => setIsNoteModalOpenVar(false));
	useOnKeydownEscape(() => setIsNoteModalOpenVar(false));

	return (
		<div ref={ref} className="inline-block w-full px-4 pt-5 pb-4 my-8 overflow-hidden align-middle transition-all transform rounded-lg shadow-xl bg-bowl-notes-modal-bg sm:min-w-25 sm:max-w-lg sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<NoteCloseIconButton clickHandler={() => setIsNoteModalOpenVar(false)} />

			{post && (
				<>
					<div className="w-full text-left text-white">
						<SmallHeadline title="bookmark" />
					</div>

					<div className="px-4 py-2 mb-4 bg-white rounded-md">
						<PostMinimal post={post} />
					</div>
				</>
			)}

			{(note || creationModeActive) && (
				<div>
					<div className="w-full text-left text-white">
						{creationModeActive && <SmallHeadline title="create_note" />}
						{!creationModeActive && (
							<div className="flex items-center">
								<SmallHeadline title="note" />
								<div className="pb-1 ml-2 text-xs">
									{note?.updatedAt ? `(${t('note_last_update')} ${formatDatetime(note.updatedAt)})` : ''}
								</div>
							</div>
						)}
					</div>
					<div className="p-4 bg-white rounded-md">
						<div className="w-full border-b border-gray-100">
							{editModeActive && (
								<div className="w-full">
									<textarea
										className="h-[200px] resize-none rounded-md block p-2.5 w-full text-sm text-bowl-typo-secondary bg-gray-50 border border-gray-300 focus:ring-bowl-main focus:border-bowl-main"
										type="text"
										value={noteText}
										onChange={(e) => setNoteText(e.target.value)}
										placeholder={t('note_placeholder')}
									/>
								</div>
							)}
							{!editModeActive && (
								<p className="text-left text-sm text-bowl-typo-secondary leading-5 py-2 whitespace-pre-line max-h-[200px] overflow-y-scroll">
									{noteText}
									{noteText === '' && t('no_note')}
								</p>
							)}
						</div>
						{!creationModeActive && (
							<div className="flex justify-end mt-4">
								{!editModeActive && <SmallButton title="note_edit" onClick={() => setEditModeActive(true)} />}
								{editModeActive && <SmallButton title="note_cancel" onClick={() => cancelEdit()} />}
								{editModeActive && <SmallButton title="note_save" onClick={() => saveEdit()} disabled={false} />}
							</div>
						)}
					</div>
				</div>
			)}
			{loadingNote && (
				<div className="min-h-[200px] flex items-center justify-center text-white animate-pulse">
					{t('loading')}
				</div>
			)}
			<div>
				<div className="w-full mt-6 sm:flex sm:space-x-6">
					{!creationModeActive && note && <MainButton title="note_delete" onClick={() => eraseNote()} />}
					{!creationModeActive && <MainButton title="note_close" onClick={() => setIsNoteModalOpenVar(false)} />}
					{creationModeActive && <MainButton title="note_cancel" onClick={() => setIsNoteModalOpenVar(false)} />}
					{creationModeActive && <MainButton title="note_save" onClick={() => saveNewNote()} disabled={false} />}
				</div>
			</div>
		</div>
	);
};

const SmallButton = ({ title, onClick, disabled }) => {
	const { t } = useTranslation();

	return (
		<button type="button" disabled={disabled} className={`flex items-center px-3 py-2 ml-2 border rounded-full ${disabled ? 'opacity-50' : ''}`} onClick={onClick}>
			<div className="mr-2">
				{title === 'note_edit' && (<NoteIcon w="w-4" h="h-4" />)}
				{title === 'note_cancel' && (<CloseIcon w="w-4" h="h-4" />)}
				{title === 'note_save' && (<CheckmarkIcon w="w-4" h="h-4" />)}
			</div>
			<div className="text-xs text-bowl-typo-secondary">
				{t(title)}
			</div>
		</button>
	);
};

const SmallHeadline = ({ title }) => {
	const { t } = useTranslation();
	return (
		<h3 className="mb-2 text-lg leading-6 text-left text-white font-secondary">
			{t(title)}
		</h3>
	);
};

const MainButton = ({ title, onClick, disabled }) => {
	const { t } = useTranslation();
	return (
		<div className="flex w-full">
			<span className="flex w-full mt-3 rounded-md shadow-sm sm:mt-0">
				<button
					type="button"
					disabled={disabled}
					onClick={onClick}
					className={`inline-flex justify-center w-full px-4 py-2 text-base leading-6 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm text-bowl-typo sm:text-sm sm:leading-5 ${disabled ? 'opacity-50' : 'hover-hover:hover:bg-orange-100 focus:bg-orange-100 hover-hover:hover:text-bowl-typo-secondary focus:outline-none focus:text-bowl-typo-secondary'}`}
				>
					{t(title)}
				</button>
			</span>
		</div>
	);
};
const NoteCloseIconButton = ({ clickHandler }) => (
	<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
		<button type="button" onClick={clickHandler} className="transition duration-150 ease-in-out text-bowl-dark hover-hover:hover:text-bowl-main focus:text-bowl-main focus:outline-none " aria-labelledby="close">
			<CloseIcon color="text-white" />
		</button>
	</div>
);

const NoteModal = () => {
	const { isNoteModalOpen, noteId, postId } = useIsNoteModalOpen();
	return (
		<Modal show={isNoteModalOpen}>
			{/* This element is to trick the browser into centering the modal contents. */}
			<span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
			<NoteModalInner noteId={noteId} postId={postId} />
		</Modal>
	);
};

export default NoteModal;
