// import i18next from 'i18next';

// method stolen from axios
export const readCookie = (name) => {
	const cookie = `${name}`;
	if (window.document) {
		const match = window.document.cookie.match(
			new RegExp(`(^|;\\s*)(${name})=([^;]*)`),
		);
		return match ? decodeURIComponent(match[3]) : '';
	}

	return cookie;
};

export const xsrfHeader = () => {
	const xsrfToken = readCookie(process.env.REACT_APP_XSRF_COOKIE_NAME);
	return xsrfToken
		? {
			'X-Requested-With': 'XMLHttpRequest',
			[process.env.REACT_APP_XSRF_HEADER_NAME]: xsrfToken,
		}
		: {};
};

export const localeHeader = () => ({
	// 'X-Langauge': i18next.language || window.localStorage.i18nextLng,
	'X-Langauge': 'en',
});
