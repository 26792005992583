import { useQuery } from '@apollo/client';
import { QUERY_NEWEST_POSTS } from '../../graphql/posts';

export const useNewestPosts = ({ count = 30 }) => {
	const {
		data, loading, error, refetch, networkStatus,
	} = useQuery(QUERY_NEWEST_POSTS, {
		notifyOnNetworkStatusChange: true,
		variables: { count },
	});

	const posts = data?.newestPosts ?? [];

	return {
		posts, loading, error, refetch, networkStatus,
	};
};
