import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import { setIsEventShareMenuOpenVar } from '../../../../BOWL-Common/apollo/extensions';
import { useIsEventShareMenuOpen } from '../../../../BOWL-Common/hooks/calendar/useIsEventShareMenuOpen';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../../hooks/useOnKeydownEscape';
import { EventShareIcon } from '../../../partials/Icons';
import Transition from '../../../partials/Transition';

const EventShareMenu = ({ event }) => {
	const { t } = useTranslation();

	const { isEventShareMenuOpen, eventId } = useIsEventShareMenuOpen();
	const isThisEventShareMenuOpen = isEventShareMenuOpen && eventId === event.id;

	const ref = useRef();
	useOnClickOutside(ref, () => setIsEventShareMenuOpenVar(false));
	useOnKeydownEscape(() => setIsEventShareMenuOpenVar(false));

	const shareEvent = {
		title: event.title,
		start: event.allDay ? `${event.start}T00:00:00.000Z` : event.start,
		end: event.allDay ? `${event.end}T00:00:00.000Z` : event.end,
		description: event.description,
		allDay: event.allDay,
	};

	return (
		<>
			<Transition
				show={isThisEventShareMenuOpen}
				enter="transition ease-out duration-200"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<div ref={ref} className="absolute z-10 origin-top-right rounded-md shadow-xl right-1 top-1 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby={`event-share-menu-${eventId}`}>
					<div className="bg-white rounded-md shadow-xs" role="none">
						<div className="py-1" role="none">
							<EventShareMenuItemWrapper>
								<EventShareMenuItemButton title={t('Apple')} shareUrl={ics(shareEvent)} filename={event.title} />
							</EventShareMenuItemWrapper>
							<EventShareMenuItemWrapper>
								<EventShareMenuItemButton title={t('iCal File')} shareUrl={ics(shareEvent)} filename={event.title} />
							</EventShareMenuItemWrapper>
							<EventShareMenuItemWrapper>
								<EventShareMenuItemButton title={t('Google')} shareUrl={google(shareEvent)} />
							</EventShareMenuItemWrapper>
							<EventShareMenuItemWrapper>
								<EventShareMenuItemButton title={t('Outlook.com')} shareUrl={outlook(shareEvent)} />
							</EventShareMenuItemWrapper>
							<EventShareMenuItemWrapper>
								<EventShareMenuItemButton title={t('Microsoft 365')} shareUrl={office365(shareEvent)} />
							</EventShareMenuItemWrapper>
							<EventShareMenuItemWrapper>
								<EventShareMenuItemButton title={t('Yahoo')} shareUrl={yahoo(shareEvent)} />
							</EventShareMenuItemWrapper>
						</div>
					</div>
				</div>
			</Transition>
			<ButtonOpenEventShareMenu event={event} />
		</>
	);
};

// DONE: Change to link
const EventShareMenuItemButton = ({ title, shareUrl, filename = '' }) => {
	const handleClick = () => {
		setIsEventShareMenuOpenVar(false);
	};

	return (
		<a href={shareUrl} target="_blank" rel="noreferrer" download={filename} onClick={() => handleClick()} className="flex flex-row items-center flex-grow h-full px-4 py-2 focus:outline-none">
			{title}
		</a>
	);
};

const EventShareMenuItemWrapper = ({ children }) => (
	<div
		role="menuitem"
		className="flex items-center w-full h-10 text-sm text-left group text-bowl-typo hover:bg-bowl-light"
	>
		{children}
	</div>
);

const ButtonOpenEventShareMenu = ({ event }) => (
	<button
		type="button"
		onClick={() => setIsEventShareMenuOpenVar(true, event.id)}
		className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar focus:text-bowl-topbar hover-hover:hover:bg-bowl-light focus:bg-bowl-light focus:outline-none print:hidden"
		aria-labelledby={`open-bk-${event.id}-event-share-menu`}
		aria-expanded="false"
	>
		<EventShareIcon />
	</button>
);


export default EventShareMenu;
