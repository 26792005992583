import deepmerge from 'deepmerge';
import { gql } from '@apollo/client';

export const callInitalState = (extension) => {
	try {
		extension.initialState();
	} catch (err) {
		if (process.env.NODE_ENV === 'development') {
			console.warn(`extension has no initialState: ${extension.name}`);
		}
	}
};

export const composeCacheExtensions = (cacheExtensions = {}) => {
	const composedExtensions = Object.values(cacheExtensions).reduce(
		({ typePolicies, typeDefs }, extension) => {
			callInitalState(extension);

			return ({
				typePolicies: deepmerge(
					typePolicies,
					extension.typePolicies || {},
				),
				typeDefs: extension.typeDefs
					? `${typeDefs} ${extension.typeDefs}`
					: typeDefs,
			});
		},
		{
			typePolicies: {},
			typeDefs: '',
		},
	);

	const composedTypeDefs = composedExtensions.typeDefs !== ''
		? gql`
			${composedExtensions.typeDefs}
		`
		: composedExtensions.typeDefs;

	return {
		...composedExtensions,
		typeDefs: composedTypeDefs,
	};
};

export const composeCacheInitalState = (cacheExtensions) => {
	Object.values(cacheExtensions).map((extension) => callInitalState(extension));
};

export default {
	composeCacheExtensions,
	composeCacheInitalState,
};
