import useTopTextLine from '../../../../../BOWL-Common/hooks/posts/useTopTextLine';
import useOpenPost from '../../../../../hooks/useOpenPost';
import PostUnreadBadge from './partials/PostUnreadBadge';
import { ArrowIcon } from '../../../../partials/Icons';

const PostMinimal = ({ post }) => {
	const openPost = useOpenPost();
	const { topTextLine } = useTopTextLine({ post });

	return (
		<div className="">
			<button type="button" onClick={() => openPost(post)} className="w-full text-left flex items-center">
				<div className="flex flex-col flex-grow w-full overflow-hidden">
					<div className="flex flex-row items-center mb-1">
						{topTextLine && <PostUnreadBadge post={post} />}
						{topTextLine && <div className="text-xs leading-4 text-bowl-date">{topTextLine}</div>}
					</div>
					<div className="flex flex-row items-center">
						{!topTextLine && <PostUnreadBadge post={post} />}
						<div className="text-xs font-secondary text-bowl-main">
							{post.category?.name ?? ''}
						</div>
					</div>
					<div className="flex items-center mb-1">
						<h3 className="flex-grow font-secondary font-bold text-bowl-main text-lg whitespace-nowrap overflow-hidden text-ellipsis">
							{post.name}
						</h3>
					</div>
				</div>
				<div className="">
					<ArrowIcon />
				</div>
			</button>
		</div>
	);
};

export default PostMinimal;
