import { useAllCategories } from './useAllCategories';

export const useAllCategoriesForNavigation = () => {
	const result = useAllCategories();
	const patchedData = {
		categories: [],
		categoriesById: {},
		topLevelCategories: [],
	};

	if (result.data) {
		const {
			data: { categories = []},
		} = result;

		patchedData.categories = categories;

		patchedData.categoriesById = categories.reduce(
			(prev, category) => ({
				...prev,
				[category.id]: category,
			}),
			{},
		);

		patchedData.topLevelCategories = categories.filter(
			(category) => category.parentCategory === null,
		);
	}

	return {
		...result,
		data: {
			...result.data,
			...patchedData,
		},
	};
};
