import { setContext } from '@apollo/link-context';
import { xsrfHeader, localeHeader } from '../utils';

const setupComposeHeadersLink = () => setContext((_, { headers }) => ({
	headers: {
		...headers,
		...xsrfHeader(),
		...localeHeader(),
	},
}));

export default setupComposeHeadersLink;
