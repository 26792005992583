import { memo } from 'react';

const ErrorMessage = ({ message, isHtml = false }) => (
	<>
		{message && !isHtml && (
			<div className="text-sm text-red-500 mt-2">
				{message}
			</div>
		)}
		{message && isHtml && (
			<div className="text-sm text-red-500 mt-2" dangerouslySetInnerHTML={{ __html: message }} />
		)}
	</>
);

export default memo(ErrorMessage);
