import { useQuery } from '@apollo/client';
import { QUERY_LATEST_EVENTS_TODAY } from '../../graphql/calendar';

export const useLatestEventsToday = () => {
	const {
		data, loading, error, refetch, networkStatus,
	} = useQuery(QUERY_LATEST_EVENTS_TODAY, {
		notifyOnNetworkStatusChange: true,
	});

	const events = data?.latestEventsToday ?? [];

	return {
		events, loading, error, refetch, networkStatus,
	};
};
