import { gql } from '@apollo/client';

export const QUERY_APP_META = gql`
	query AppMeta {
		appMeta {
			imprint
			privacy
			instructions_url
		}
	}
`;
