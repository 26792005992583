export class BowlStorage {
	static storage = null;

	static storeData(key, value) {
		return BowlStorage.storage.save({ key, data: value });
	}

	static loadData(key) {
		return BowlStorage.storage.load({ key, syncInBackground: false });
	}
}
