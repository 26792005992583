import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSetReadAllPostsOfCategory } from '../../../../BOWL-Common/hooks/user/useSetReadAllPostsOfCategory';
import { useTrackCategoryOpenEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackCategoryOpenEffect';
import { useTrackPageViewEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { useIndices } from '../../../../BOWL-Common/hooks/categories/useIndices';
import MappedComponent from '../../../../BOWL-Common/components/MappedComponent';
import useFilterUnreadPosts from '../../../../BOWL-Common/hooks/posts/useFilterUnreadPosts';
import { QUERY_ALL_POSTS_OF_CATEGORY } from '../../../../BOWL-Common/graphql/posts';
import { CategoryContentLoader } from '../../../partials/ContentLoader';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../../config/mapping';
import AzIndex from '../posts/lists/partials/AzIndex';
import { FilterActiveIcon, FilterInactiveIcon, CheckAllIcon } from '../../../partials/Icons';

// TODO: Filter Logik in DefaultList.jsx machen, damit es wie im Mobile ist?
const Category = ({ category }) => {
	const { t } = useTranslation();
	const { data, loading, error } = useQuery(QUERY_ALL_POSTS_OF_CATEGORY, { variables: { category_id: category.id }});
	// TODO: Set true/false, when coming back with the <- Zurück Button
	const [isFilterActive, setIsFilterActive] = useState(false);

	const postsByCategoryId = data?.postsByCategoryId ?? [];
	const unreadPosts = useFilterUnreadPosts({ posts: postsByCategoryId });

	const posts = isFilterActive ? unreadPosts : postsByCategoryId;

	useEffect(() => {
		setIsFilterActive(false);
	}, [category.id]);

	const [userHasReadAllPosts] = useSetReadAllPostsOfCategory();

	useTrackPageViewEffect(`Category: ${category.name}`);
	useTrackCategoryOpenEffect(category);

	const indices = useIndices(category, posts);

	return (
		<div className="px-2 sm:px-4">
			<div className="flex justify-between">
				<h2 className="py-2 text-2xl text-bowl-typo-secondary">
					<span className="font-bold font-secondary text-33">{category.name}</span>
				</h2>
				{category.increasesBadgeCount && (
					<div className="flex">
						{isFilterActive && posts && posts.length > 0 && (
							<button
								type="button"
								onClick={() => { userHasReadAllPosts({ variables: { categoryId: category.id }}); setIsFilterActive(!isFilterActive); }}
								className="self-center p-2 ml-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar print:hidden"
								aria-labelledby="read-all"
								aria-expanded="false"
								title={t('mark_all_as_read')}
							>
								<CheckAllIcon />
							</button>
						)}
						<button
							type="button"
							onClick={() => setIsFilterActive(!isFilterActive)}
							className={`self-center p-2 ${isFilterActive ? 'ml-2' : 'ml-14'} transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar print:hidden`}
							aria-labelledby="filter-read-unread"
							aria-expanded="false"
							title={isFilterActive ? t('show_all') : t('filter_by_unread')}
						>
							{isFilterActive && <FilterActiveIcon />}
							{!isFilterActive && <FilterInactiveIcon />}
						</button>
					</div>
				)}
			</div>
			{indices.length > 0 && (
				<div className="py-2">
					<AzIndex indices={indices} />
				</div>
			)}
			{loading && (
				<>
					<CategoryContentLoader />
					<CategoryContentLoader />
					<CategoryContentLoader />
					<CategoryContentLoader />
					<CategoryContentLoader />
				</>
			)}
			{!loading && !error && posts && posts.length > 0 && <MappedComponent typeComponentMap={mapping} posts={posts} mapName="postList" type={category.type} />}
			{!loading && !error && posts && posts.length === 0 && isFilterActive && <CategoryMessage title={t('category_no_unread_posts')} />}
			{!loading && !error && posts && posts.length === 0 && !isFilterActive && <CategoryMessage title={t('category_no_posts')} />}
		</div>
	);
};

const CategoryMessage = ({ title }) => (
	<div className="my-8">
		<p className="text-base bowl-post-teaser-text">{title}</p>
	</div>
);

export default Category;
