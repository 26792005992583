import React from 'react';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { useNavigate } from 'react-router-dom';
import { useTrackPageViewEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { setIsCalendarFilterOpenVar } from '../../../../BOWL-Common/apollo/extensions';
import CalendarFilter from './CalendarFilter';
import { useGetEvents } from '../../../../BOWL-Common/hooks/calendar/useGetEvents';

// TODO: en
const Calendar = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	useTrackPageViewEffect(t('calendar'));

	// TODO: loading/error ?
	const { data, loading, error } = useGetEvents();
	const events = data ?? [];

	return (
		<div className="relative h-full">
			{/* TODO: Fix transition */}
			<CalendarFilter />
			<div className="relative px-2 sm:px-4 text-bowl-typo">
				<FullCalendar
					viewClassNames="bg-white"
					weekNumberClassNames="!bg-bowl-background !text-bowl-typo font-bold"
					allDayClassNames={['all-day-class-names']}
					eventOrderStrict
					eventOrder="subcalendarSortOrder,start,-duration,allDay,title"
					height="auto"
					allDayContent="" // TODO: Allday/24h icon?
					// expandRows ???
					nowIndicator
					locale={deLocale}
					plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
					initialView="dayGridMonth"
					headerToolbar={{
						left: 'prev,today,next',
						center: 'title',
						// TODO: Fix borders
						right: 'dayGridMonth,listWeek myCustomButton', // listYear, listMonth, listWeek, listDay
					}}
					buttonText={{
						list: t('weekly overview'),
					}}
					weekNumbers
					slotEventOverlap={false}
					eventDisplay="block"
					events={events}
					eventTimeFormat={{
						hour: '2-digit',
						minute: '2-digit',
						hour12: false,
					}}
					slotLabelFormat={{
						hour: '2-digit',
						minute: '2-digit',
						hour12: false,
					}}
					customButtons={{
						myCustomButton: {
							text: t('Filter'),
							click() {
								setIsCalendarFilterOpenVar(true);
							},
						},
					}}
					eventClick={(info) => {
						navigate(`/calendar/${info.event.id}`);
					}}
				/>
			</div>
		</div>
	);
};

export default Calendar;
