import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ErrorScreen = ({ error, retry, goHome, showHeadline = true }) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col items-center justify-center w-full h-screen bg-bowl-public">
			<div className="flex flex-col items-center justify-center p-4 px-6 text-white bg-red-500 rounded shadow">
				<img src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt="Logo" className="w-16 h-16 mb-4" />
				{showHeadline && <span className="">{t('error')}</span>}
				<span className="mt-2 mb-2 text-xs italic">{error?.message}</span>
				{goHome && <Link to="/">{t('go_home')}</Link>}
				{retry && <button type="button" onClick={() => retry()}>RETRY</button>}
			</div>
		</div>
	);
};

export default ErrorScreen;
