import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useQuery } from '@apollo/client';
import { QUERY_APP_META } from '../../../BOWL-Common/graphql/appmeta';
import { setIsChangePasswordModalOpenVar, setIsUserMenuOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import { logout } from '../../../BOWL-Common/graphql/auth';
import { useUserMe } from '../../../BOWL-Common/hooks/user/useUserMe';
import { useIsUserMenuOpen } from '../../../BOWL-Common/hooks/global/useIsUserMenuOpen';
import { ButtonTopBar } from './ButtonTopBar';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../hooks/useOnKeydownEscape';
import Transition from '../../partials/Transition';

const UserMenu = () => {
	const { t } = useTranslation();
	const { data } = useQuery(QUERY_APP_META);
	const ref = useRef();

	const { user } = useUserMe();
	const { isUserMenuOpen } = useIsUserMenuOpen();

	useOnClickOutside(ref, () => setIsUserMenuOpenVar(false));
	useOnKeydownEscape(() => setIsUserMenuOpenVar(false));

	const client = useApolloClient();

	return (
		<Transition
			show={isUserMenuOpen}
			enter="transition ease-out duration-200"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<div ref={ref} className="absolute right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg">
				<div className="rounded-md shadow-xs bg-bowl-topbar">
					<div className="px-2 pt-2 md:hidden">
						{data?.appMeta?.instructions_url && <ButtonTopBar route={data?.appMeta?.instructions_url} title={t('instructions_title')} type="pdf" /> }
						{data?.appMeta?.privacy && <ButtonTopBar route="/privacy-notice" title={t('privacy_title')} /> }
						{data?.appMeta?.imprint && <ButtonTopBar route="/disclaimer" title={t('imprint_title')} /> }
					</div>
					<div className="pt-4 pb-3 border-t border-bowl-topbar-secondary md:border-0">
						<div className="flex items-center px-5">
							<div>
								<div className="text-base leading-6 text-bowl-topbar-secondary">
									{user.name}
								</div>
								<div className="text-sm leading-5 text-bowl-topbar-secondary">
									{user.email}
								</div>
							</div>
						</div>
						<div className="px-2 mt-5 last:-mb-2">
							<UserMenuItemButton title={t('change_password')} callback={() => setIsChangePasswordModalOpenVar(true)} />
							<UserMenuItemButton title={t('top_bar_sign_out')} callback={() => logout(client)} />
						</div>
					</div>
				</div>
			</div>
		</Transition>
	);
};

const UserMenuItemButton = ({ title, callback }) => {
	const handleClick = () => {
		setIsUserMenuOpenVar(false);
		callback();
	};

	return (
		<button
			type="button"
			onClick={() => handleClick()}
			className="block w-full px-3 py-2 mb-2 text-base text-left transition duration-150 ease-in-out rounded-md text-bowl-topbar-secondary hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:text-bowl-topbar focus:bg-bowl-topbar-secondary"
		>
			{title}
		</button>
	);
};

export default UserMenu;
