import { gql } from '@apollo/client';

export const FRAGMENT_SURVEY_FIELDS = gql`
	fragment SurveyFields on Survey {
		id
		uuid
		version
		title
		description
		startDate
		endDate
		questions {
			layout
			key
			attributes {
				question
				type
				options {
					layout
					key
					attributes {
						option
					}
				}
			}
		}
		pinned
	}
`;

export const QUERY_ALL_SURVEYS = gql`
	query Surveys {
		surveys {
			...SurveyFields
		}
	}
	${FRAGMENT_SURVEY_FIELDS}
`;

export const QUERY_SURVEY_BY_ID = gql`
	query Survey($id: ID!) {
		survey(id: $id) {
			...SurveyFields
		}
	}
	${FRAGMENT_SURVEY_FIELDS}
`;

export const MUTATE_ANSWER_SURVEY = gql`
	mutation AnswerSurvey($surveyId: ID!, $answers: String!) {
		answerSurvey(input: { surveyId: $surveyId, answers: $answers }) {
			success
		}
	}
`;
