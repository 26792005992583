import Transition from '../partials/Transition';

// TODO: Transition is wrong
const Modal = ({ children, show }) => (
	<ModalFadeInTransition show={show}>
		<ModalWrapper>
			<ModalBackground />
			{children}
		</ModalWrapper>
	</ModalFadeInTransition>
);

export default Modal;

export const ModalWrapper = ({ children }) => (
	<div className="fixed inset-0 z-40 overflow-y-auto">
		<div className="block min-h-screen px-4 text-center sm:p-0">
			{children}
		</div>
	</div>
);

export const ModalBackground = () => (
	<div className="fixed inset-0 transition-opacity">
		<div className="absolute inset-0 bg-gray-500 opacity-75" />
	</div>
);

export const ModalFadeInTransition = ({ children, show }) => (
	<Transition
		show={show}
		enter="ease-out duration-300"
		enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		enterTo="opacity-100 translate-y-0 sm:scale-100"
		leave="ease-in duration-200"
		leaveFrom="opacity-100 translate-y-0 sm:scale-100"
		leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
	>
		{children}
	</Transition>
);
