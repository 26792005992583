import { useQuery } from '@apollo/client';
import { QUERY_LATEST_EVENTS_SOON } from '../../graphql/calendar';

export const useLatestEventsSoon = () => {
	const {
		data, loading, error, refetch, networkStatus,
	} = useQuery(QUERY_LATEST_EVENTS_SOON, {
		notifyOnNetworkStatusChange: true,
	});

	const events = data?.latestEventsSoon ?? [];

	return {
		events, loading, error, refetch, networkStatus,
	};
};
