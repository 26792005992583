import { setIsImageZoomModalOpenVar, setIsSidebarOpenVar } from '../../../../../BOWL-Common/apollo/extensions';
import { AddressIcon, EmailIcon, MagnifyingGlassIcon, PhoneIcon } from '../../../../partials/Icons';

const getVideoUrlByType = (type, videoId) => {
	if (type === 'jw') {
		return `https://cdn.jwplayer.com/players/${videoId}.html`;
	}
	if (type === 'yt') {
		return `https://www.youtube.com/embed/${videoId}`;
	}

	return '';
};

const Video = ({ type, value }) => (
	<div itemScope itemType="https://schema.org/VideoObject" className="mb-6 print:hidden">
		<div className="relative overflow-hidden" style={{ paddingBottom: `${56.25}%` }}>
			<iframe src={getVideoUrlByType(type, value)} width="100%" height="100%" frameBorder="0" scrolling="auto" title="Wolle_60" className="absolute" allowFullScreen />
		</div>
	</div>
);

export const JwPlayer = ({ value }) => <Video type="jw" value={value} />;
export const YtPlayer = ({ value }) => <Video type="yt" value={value} />;

export const Image = ({ path }) => (
	<button
		type="button"
		onClick={() => setIsImageZoomModalOpenVar(true, path)}
		className="relative w-full mb-6 border border-gray-100 rounded-lg print:h-full print:border-0 print:rounded-none"
		aria-labelledby="open-sidebar"
	>
		<img className="object-cover w-full h-full" src={`${process.env.REACT_APP_BASE_URL}/api/img/${path}?p=big`} alt="" />
		<MagnifyingGlassIcon misc="absolute right-0 bottom-0 m-3" />
	</button>
);

export const H1 = ({ value }) => (
	<div
		className="mb-4 text-2xl font-bold leading-7 font-secondary text-bowl-typo"
		dangerouslySetInnerHTML={{ __html: value }}
	/>
);

export const H2 = ({ value }) => (
	<div
		className="mb-4 text-xl leading-7 font-secondary text-bowl-typo"
		dangerouslySetInnerHTML={{ __html: value }}
	/>
);

export const Contact = ({ phone, email, address }) => (
	<div className="contact-wrapper">
		{phone !== '' && (
			<div>
				<span>
					<PhoneIcon />
				</span>
				<a href={`tel:${phone}`}>{phone}</a>
			</div>
		)}
		{email !== '' && (
			<div>
				<span>
					<EmailIcon />
				</span>
				<a href={`mailto:${email}`}>{email}</a>
			</div>
		)}
		{address !== '' && (
			<div>
				<span>
					<AddressIcon />
				</span>
				<p dangerouslySetInnerHTML={{ __html: address }} />
			</div>
		)}
	</div>
);

export const Content = ({ value }) => (
	<div
		className="mb-4 text-base leading-7 text-bowl-typo post-detail-text overflow-x-scroll"
		dangerouslySetInnerHTML={{ __html: value }}
	/>
);

export const Table = ({ value }) => (
	<div
		className="mb-4 text-base leading-7 text-bowl-typo post-detail-text overflow-x-scroll"
		dangerouslySetInnerHTML={{ __html: value }}
	/>
);
export const Empty = () => <span />;

export default {
	YtPlayer,
	JwPlayer,
	Image,
	H1,
	H2,
	Contact,
	Content,
	Table,
	Empty,
};
