import { useCallback, useEffect, useState } from 'react';
import setupApolloClient from '../../apollo';
import { alerts, azindex, category, createAuthExtension, global, notifications, calendar } from '../../apollo/extensions';

import setupXsrfRetryLink from '../../apollo/links/web/xsrfRetryLink';
import setupHttpLinkWeb from '../../apollo/links/web/httpLink';
import setupFetchXsrfCookieLink from '../../apollo/links/web/fetchXsrfCookieLink';
import setupComposeHeadersLink from '../../apollo/links/web/composeHeadersLink';
import setupXsrfResponseLink from '../../apollo/links/web/xsrfResponseLink';
import setupCatchLink from '../../apollo/links/catchLink';
import environment from '../../utils/environment';
import useInitializeLocalStorage from '../storage/useInitializeLocalStorage';
import setupComposeHeadersLinkMobile from '../../apollo/links/mobile/composeHeadersLink';
import setupHttpLinkMobile from '../../apollo/links/mobile/httpLink';

const useInitApollo = ({ gqlEndpointUrl, csrfTokenEndpointPath }) => {
	const [apolloClient, setApolloClient] = useState(null);
	const [apolloExtensions, setApolloExtensions] = useState(null);

	const { isAuthenticated, authToken } = useInitializeLocalStorage();

	const createApolloExtensions = useCallback((isAuthenticatedResult, authTokenResult) => {
		const authExtension = createAuthExtension(isAuthenticatedResult, authTokenResult);
		return {
			alerts,
			authExtension,
			azindex,
			category,
			global,
			notifications,
			calendar,
		};
	}, []);

	const createApolloLinks = useCallback(() => {
		if (environment === 'MOBILE') {
			return [
				setupCatchLink(),
				setupComposeHeadersLinkMobile(),
				setupHttpLinkMobile({ gqlEndpointUrl, authToken }),
			];
		}
		if (environment === 'WEB') {
			return [
				setupCatchLink(),
				setupXsrfRetryLink(),
				setupFetchXsrfCookieLink({ csrfTokenEndpointPath }),
				setupComposeHeadersLink(),
				setupXsrfResponseLink(),
				setupHttpLinkWeb({ gqlEndpointUrl }),
			];
		}

		return [];
	}, [gqlEndpointUrl, authToken, csrfTokenEndpointPath]);

	const initApolloClient = useCallback(async () => {
		const apolloOptions = {
			name: 'bowl',
			version: '1.0.0',
			connectToDevTools: true,
		};

		const client = await setupApolloClient(apolloOptions, createApolloLinks(), apolloExtensions);
		setApolloClient(client);
	}, [apolloExtensions, createApolloLinks]);

	useEffect(() => {
		if (!apolloExtensions) {
			if (isAuthenticated !== null && authToken !== null) {
				setApolloExtensions(createApolloExtensions(isAuthenticated === true, authToken === false ? null : authToken));
			}
		}
	}, [apolloExtensions, isAuthenticated, authToken, createApolloExtensions]);

	useEffect(() => {
		if (apolloExtensions && !apolloClient) {
			initApolloClient();
		}
	}, [apolloExtensions, apolloClient, initApolloClient]);


	return apolloClient;
};

export default useInitApollo;
