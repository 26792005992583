import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { removeAllInactiveSubcalendarsVar, removeInactiveSubcalendarsVar, setAllInactiveSubcalendarsVar, setInactiveSubcalendarsVar, setIsCalendarFilterOpenVar } from '../../../../BOWL-Common/apollo/extensions';
import { useIsCalendarFilterOpen } from '../../../../BOWL-Common/hooks/calendar/useIsCalendarFilterOpen';
import Transition from '../../../partials/Transition';
import useOnKeydownEscape from '../../../../hooks/useOnKeydownEscape';
import { CheckmarkIcon, UncheckmarkedIcon, CloseIcon } from '../../../partials/Icons';
import { useInactiveSubcalendars } from '../../../../BOWL-Common/hooks/calendar/useInactiveSubcalendars';
import { QUERY_ALL_SUBCALENDARS } from '../../../../BOWL-Common/graphql/calendar';

const CalendarFilter = () => {
	const { t } = useTranslation();

	const { isCalendarFilterOpen } = useIsCalendarFilterOpen();
	const closeCalendarFilter = () => setIsCalendarFilterOpenVar(false);
	useOnKeydownEscape(() => closeCalendarFilter());

	const { data: inactiveSubcalendarsData } = useInactiveSubcalendars();

	const allSubcalendarsAreActive = inactiveSubcalendarsData?.inactiveSubcalendars.length === 0;

	// TODO: loading/error ?
	const { data, loading, error } = useQuery(QUERY_ALL_SUBCALENDARS);
	const subcalendars = data?.subcalendars ?? [];

	const subcalendarClicked = (subcalendarId, isInactiveSubcalendar) => {
		if (isInactiveSubcalendar) {
			removeInactiveSubcalendarsVar(subcalendarId);
		} else {
			setInactiveSubcalendarsVar(subcalendarId);
		}
	};

	const allSubcalendarsClicked = () => {
		if (allSubcalendarsAreActive) {
			const allSubcalendarIds = subcalendars.map((subcalendar) => subcalendar.id);
			setAllInactiveSubcalendarsVar(allSubcalendarIds);
		} else {
			removeAllInactiveSubcalendarsVar();
		}
	};

	return (
		<Transition show={isCalendarFilterOpen}>
			<div className="print:hidden">
				<div className="absolute inset-0 z-40 flex justify-end -mt-4 sm:-mx-4">
					{/* Off-canvas menu overlay, show/hide based on off-canvas menu state. */}
					<Transition
						enter="transition-opacity ease-linear duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div role="button" onClick={() => setIsCalendarFilterOpenVar(false)} onKeyDown={() => setIsCalendarFilterOpenVar(false)} tabIndex={0}>
							<div className="fixed inset-0 bg-black opacity-25" />
						</div>
					</Transition>
					{/* Off-canvas menu, show/hide based on off-canvas menu state. */}
					<Transition
						enter="transition ease-in-out duration-500 transform"
						enterFrom="translate-x-full"
						enterTo="-translate-x-0"
						leave="transition ease-in-out duration-500 transform"
						leaveFrom="-translate-x-0"
						leaveTo="translate-x-full"
					>
						<div className="fixed flex flex-col pb-4 shadow bg-bowl-navigation h-fit">
							<div className="pt-2 ml-auto">
								<button type="button" onClick={() => setIsCalendarFilterOpenVar(false)} className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none" aria-labelledby="close-calendar-filter">
									<CloseIcon color="text-bowl-categorytypo" />
								</button>
							</div>
							<div className="flex flex-col px-2 gap-y-2">
								<button type="button" className="flex flex-row items-center justify-between w-full p-2 rounded-md font-secondary text-bowl-typo-secondary gap-x-4 focus:opacity-90 hover-hover:hover:opacity-90" onClick={() => allSubcalendarsClicked()}>
									<div className="text-xl">{t('sub_calendars')}</div>
									{allSubcalendarsAreActive && <CheckmarkIcon />}
									{!allSubcalendarsAreActive && <UncheckmarkedIcon />}
								</button>
								{subcalendars.map((subcalendar) => {
									const isInactiveSubcalendar = inactiveSubcalendarsData?.inactiveSubcalendars.includes(subcalendar.id);
									return (
										<button key={subcalendar.id} type="button" style={{ backgroundColor: subcalendar.color }} className="flex flex-row items-center justify-between w-full p-2 text-white rounded-md gap-x-4 focus:opacity-90 hover-hover:hover:opacity-90" onClick={() => subcalendarClicked(subcalendar.id, isInactiveSubcalendar)}>
											<div className="text-base">{subcalendar.name}</div>
											{!isInactiveSubcalendar && <CheckmarkIcon />}
											{isInactiveSubcalendar && <UncheckmarkedIcon />}
										</button>
									);
								})}
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</Transition>
	);
};

export default CalendarFilter;
