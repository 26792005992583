import { ApolloLink, Observable } from '@apollo/client';
import { getClient } from '..';
import { logout } from '../../graphql/auth';

const gqlErrorMessageToErrorKey = (error) => `${error.message.replace('.', '').toLowerCase()}_${
	error.path ? error.path.join('_') : ''
}`;

export const onCatch = (errorHandler) => new ApolloLink((operation, forward) => new Observable((observer) => {
	let subscription;

	try {
		subscription = forward(operation).subscribe({
			next: (result) => {
				if (result.errors) {
					errorHandler({
						graphQlErrors: result.errors,
						operation,
						forward,
					});
				}

				observer.next(result);
			},
			error: (error) => {
				errorHandler({
					error,
					operation,
					forward,
				});
				observer.error(error);
				observer.complete();
			},
			complete: () => {
				observer.complete.bind(observer)();
			},
		});
	} catch (error) {
		errorHandler({
			error,
			operation,
			forward,
		});
		observer.error(error);
		observer.complete();
	}

	return () => {
		if (subscription) subscription.unsubscribe();
	};
}));

const setupCatchLink = () => onCatch(({ error, graphQlErrors }) => {
	console.log('catch link', error, graphQlErrors);
	if (graphQlErrors) {
		graphQlErrors.forEach((graphQlError) => {
			const errorKey = gqlErrorMessageToErrorKey(graphQlError);
			console.log('errorkey', errorKey);
			if (errorKey.startsWith('unauthenticated') && errorKey !== 'unauthenticated_login') {
				logout(getClient());
			} else if (errorKey === 'unauthenticated_login') {
				// handled by component in Login.jsx
				// invalidCredentials();
			} else if (errorKey === 'unauthenticated_csrf_token_missmatch') {
				logout(getClient());
				// invalidToken();
			} else if (process.env.NODE_ENV === 'development') {
				console.error('GraphQL Error:', graphQlErrors);
				// unknownGraphQlError();
			}
		});
	}

	if (error) {
		switch (error.statusCode) {
		case 419:
			// logout(getClient());
			// invalidToken();
			break;
		case 401:
			logout(getClient());
			break;
		default:
			// unknownNetworkError();
			break;
		}
	}

	console.error('CatchLink: unkown error.', error, graphQlErrors);
});

export default setupCatchLink;

export class CatchLink extends ApolloLink {
	constructor(errorHandler) {
		super();
		this.link = onCatch(errorHandler);
	}

	request(operation, forward) {
		return this.link.request(operation, forward);
	}
}
