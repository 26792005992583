import { setContext } from '@apollo/link-context';
import { authTokenVar } from '../../extensions';

const setupComposeHeadersLink = () => setContext((_, { headers }) => {
	const authToken = authTokenVar();

	return {
		headers: {
			...headers,
			authorization: authToken ? `Bearer ${authToken}` : '',
		},
	};
});

export default setupComposeHeadersLink;
