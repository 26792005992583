import LazyLoad from 'react-lazyload';
import useTopTextLine from '../../../../../BOWL-Common/hooks/posts/useTopTextLine';
import useOpenPost from '../../../../../hooks/useOpenPost';
import { ArrowIcon } from '../../../../partials/Icons';
import ChannelWrapper from './partials/ChannelWrapper';
import PostUnreadBadge from './partials/PostUnreadBadge';
import PostMenu from './partials/PostMenu';

const PostLatest = ({ post }) => {
	const openPost = useOpenPost(true);
	// TODO: Gets rendered late
	const { topTextLine } = useTopTextLine({ post });
	const isAlert = post.template === 'alert';

	return (
		<div className={`bowl-post-button-wrapper ${isAlert ? 'bg-bowl-alert ' : ''}`}>
			<div className="absolute ml-auto top-1 right-1">
				<PostMenu post={post} />
			</div>
			<button type="button" onClick={() => openPost(post)} className="bowl-post-button-inner">
				<div className="flex flex-row overflow-hidden">
					<div className="flex-shrink-0">
						<TeaserImage post={post} />
					</div>
					<div className="flex flex-row items-center flex-grow overflow-hidden">
						<div className="flex flex-col self-start flex-grow overflow-hidden">
							<div className="flex flex-row items-center mb-1">
								<PostUnreadBadge post={post} />
								{topTextLine && <div className={`mr-8 text-xs leading-4 ${isAlert ? 'text-white' : 'text-bowl-date'}`}>{topTextLine}</div>}
							</div>
							<p className={`text-xs leading-4 text-left font-secondary mt-0.5 -mb-0.5 ${isAlert ? 'text-white' : 'text-bowl-typo'}`}>
								{post.category.name}
							</p>
							<div className="flex flex-grow overflow-hidden">
								<h3 className={` overflow-hidden bowl-post-headline ${isAlert ? 'text-white' : ''}`}>
									{post.name}
								</h3>
							</div>
						</div>
						<span className="flex-shrink-0 inline mt-2 ml-4">
							<ArrowIcon color={`${isAlert ? 'text-white' : 'text-bowl-typo'}`} />
						</span>
					</div>
				</div>
			</button>
			<div className={`${post.template === 'alert' ? 'bg-white rounded-lg rounded-t-none' : ''}`}>
				<ChannelWrapper post={post} showCat />
			</div>
		</div>
	);
};

const TeaserImage = ({ post }) => (
	<div className={`rounded-lg bowl-post-teaser-image-wrapper ${post?.teaserImageUrl ? '' : 'bg-bowl-iconbg bg-clip-padding'} ${post.template === 'alert' ? 'border-bowl-alert' : ''}`}>
		{post?.teaserImageUrl && (
			<LazyLoad height={72} once placeholder={(<div className="lazy-load-wrapper-clinic w-18 h-18"><img src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt={post.name} /></div>)}>
				<div className="lazy-load-wrapper-clinic w-18 h-18">
					<img src={`${process.env.REACT_APP_BASE_URL}/api/img/${post.teaserImageUrl}?p=small`} alt={post.name} />
				</div>
			</LazyLoad>
		)}
		{!post?.teaserImageUrl && <img className="object-contain p-2 w-18 h-18" src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt="Logo" />}
	</div>
);

export default PostLatest;
