import { useLazyQuery } from '@apollo/client';
import { QUERY_TRANSLATED_POST } from '../../graphql/posts';

export const useTranslatePost = () => {
	const [translatePost, {
		called,
		loading,
		data,
		error,
		refetch,
	}] = useLazyQuery(
		QUERY_TRANSLATED_POST,
		{
			fetchPolicy: 'network-only',
		},
	);

	const translatedPost = data?.translatePost ?? null;
	return [translatePost, {
		called,
		loading,
		translatedPost,
		error,
		refetch,
	}];
};
