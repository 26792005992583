import { useEffect } from 'react';
import { usePrevious } from '../usePrevious';
import { useTrackInternalPostOpen } from './useTrackInternalPostOpen';

export const useTrackInternalPostOpenEffect = (post) => {
	const trackInternalPostOpen = useTrackInternalPostOpen();
	const prevPost = usePrevious(post);

	useEffect(() => {
		if (post) {
			if (prevPost?.id !== post?.id) {
				trackInternalPostOpen(post);
			}
		}
	}, [post, prevPost?.id, trackInternalPostOpen]);
};
