import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { setIsSearchOverlayOpenVar, setIsAlertModalOpenVar, setIsAlertWindowOpenVar, setIsUserMenuOpenVar, setIsSidebarOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import { useIsAlertWindowOpen } from '../../../BOWL-Common/hooks/global/useIsAlertWindowOpen';
import { useIsUserMenuOpen } from '../../../BOWL-Common/hooks/global/useIsUserMenuOpen';
import { useIsSearchOverlayOpen } from '../../../BOWL-Common/hooks/global/useIsSearchOverlayOpen';
import { QUERY_APP_META } from '../../../BOWL-Common/graphql/appmeta';
import { useAlerts } from '../../../BOWL-Common/hooks/alerts/useAlerts';
import UserMenu from './UserMenu';
import SearchBar from '../search/SearchBar';
import { ButtonTopBar } from './ButtonTopBar';
import { NotificationBellIcon, BurgerMenuIcon, ThreeDotsIcon, CloseIcon, ArrowIcon } from '../../partials/Icons';

const TopBar = () => {
	const { t } = useTranslation();
	const { data } = useQuery(QUERY_APP_META);
	const { isUserMenuOpen } = useIsUserMenuOpen();
	const { isAlertWindowOpen } = useIsAlertWindowOpen();
	const { isSearchOverlayOpen } = useIsSearchOverlayOpen();

	return (
		<div className="fixed top-0 left-0 right-0 z-40 flex flex-shrink-0 h-16 shadow bg-bowl-topbar print:hidden">
			<div className="hidden sm:flex">
				<ButtonOpenSidebarOnMobile />
			</div>
			<div className="flex sm:hidden">
				{(isAlertWindowOpen || isSearchOverlayOpen) && <ButtonBack />}
				{!isAlertWindowOpen && !isSearchOverlayOpen && <ButtonOpenSidebarOnMobile />}
			</div>
			<nav className="flex-1 m-auto max-w-7xl">
				<div className="flex-1 px-2">
					<div className="flex items-center justify-between h-16">
						<div className="items-center hidden px-2 md:flex md:px-0">
							<div className="flex items-center flex-shrink-0 w-64 h-16 px-4">
								<img className="w-auto" style={{ maxHeight: '40px' }} src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt="Logo" />
							</div>
							<div className="flex ml-6">
								{data?.appMeta?.instructions_url && <ButtonTopBar route={data?.appMeta?.instructions_url} title={t('instructions_title')} type="pdf" /> }
								{data?.appMeta?.privacy && <ButtonTopBar route="/privacy-notice" title={t('privacy_title')} /> }
								{data?.appMeta?.imprint && <ButtonTopBar route="/disclaimer" title={t('imprint_title')} /> }
							</div>
						</div>
						<div className="flex justify-center flex-1 px-2 md:ml-6 md:justify-end">
							<div className="w-full max-w-72">
								<SearchBar />
							</div>
						</div>
						<div className="ml-2 md:ml-4">
							<div className="flex items-center">
								<div>
									{!isAlertWindowOpen && <ButtonOpenAlertWindow />}
									{isAlertWindowOpen && <ButtonCloseAlertWindow />}
								</div>
								<div className="relative flex-shrink-0 ml-2 md:ml-4">
									<div>
										{!isUserMenuOpen && <ButtonOpenUserMenu />}
										{isUserMenuOpen && <ButtonClose />}
										<UserMenu />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
};

const ButtonOpenSidebarOnMobile = () => (
	<button
		type="button"
		onClick={() => setIsSidebarOpenVar(true)}
		className="px-4 border-r border-bowl-topbar-secondary text-bowl-topbar-secondary hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar md:hidden"
		aria-labelledby="open-sidebar"
	>
		<BurgerMenuIcon />
	</button>
);

const ButtonOpenUserMenu = () => (
	<button
		type="button"
		onClick={() => setIsUserMenuOpenVar(true)}
		className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar-secondary hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar"
		aria-labelledby="open-user-menu"
		aria-expanded="false"
	>
		<ThreeDotsIcon />
	</button>
);

const ButtonOpenAlertWindow = () => (
	<button
		type="button"
		onClick={() => setIsAlertWindowOpenVar(true)}
		className="relative flex-shrink-0 p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar-secondary hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar"
		aria-labelledby="open-notifications"
	>
		<NotificationBellIcon />
		<AlertBadge />
	</button>
);

const ButtonCloseAlertWindow = () => (
	<button
		type="button"
		onClick={() => setIsAlertWindowOpenVar(false)}
		className="relative inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar-secondary hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:bg-bowl-topbar-secondary focus:text-bowl-topbar sm:flex-shrink-0 sm:text-bowl-topbar sm:bg-bowl-topbar-secondary sm:cursor-default focus:outline-none"
		aria-labelledby="close-notifications"
	>
		<div className="sm:hidden">
			<CloseIcon />
		</div>
		<div className="hidden sm:block">
			<NotificationBellIcon />
			<AlertBadge />
		</div>
	</button>
);

const ButtonClose = () => (
	<button
		type="button"
		className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar-secondary hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar"
		aria-labelledby="close-user-menu"
		aria-expanded="false"
	>
		<CloseIcon />
	</button>
);

const ButtonBack = () => {
	const { isAlertWindowOpen } = useIsAlertWindowOpen();
	const { isSearchOverlayOpen } = useIsSearchOverlayOpen();

	const goBack = () => {
		if (isAlertWindowOpen) {
			setIsAlertModalOpenVar(false);
		}
		if (isSearchOverlayOpen) {
			setIsSearchOverlayOpenVar(false);
		}
	};

	return (
		<button
			type="button"
			onClick={() => goBack()}
			className="px-4 border-r border-bowl-topbar-secondary text-bowl-topbar-secondary hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:bg-bowl-topbar-secondary focus:text-bowl-topbar md:hidden"
			aria-labelledby="back"
		>
			<ArrowIcon direction="rotate-90" />
		</button>
	);
};

const AlertBadge = () => {
	const { alerts, loading, error } = useAlerts();
	const badgeCount = alerts.filter((alert) => alert.lastSeen === null).length;

	if (!loading && !error && badgeCount > 0) {
		return (
			<div className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 p-1 -mt-1 -mr-1 text-white bg-red-600 rounded-full text-2xs">{badgeCount}</div>
		);
	}

	return null;
};

export default TopBar;
