import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useResetOldPasswordMutation } from '../../../BOWL-Common/hooks/auth/useResetOldPasswordMutation';
import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';

import SubmitButton from './partials/SubmitButton';
import InputField from './partials/InputField';
import ErrorMessage from './partials/ErrorMessage';
import { H2, H3 } from './partials/Headlines';

const PasswordExpired = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	useTrackPageViewEffect(t('change_password_expired_title'));

	const [resetOldPassword, { data, loading, error }] = useResetOldPasswordMutation();

	const [oldPassword, setOldPassword] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const [uiState, setUiState] = useState('idle');
	const [errorMessage, setErrorMessage] = useState(null);
	const status = data?.resetOldPassword?.status ?? '';

	useEffect(() => {
		if (loading === true) {
			setUiState('loading');
		} else if (status === '') {
			setUiState('idle');
		} else if (status === 'PASSWORD_UPDATED') {
			setUiState('success');
		} else if (status === 'PASSWORD_NOT_UPDATED') {
			setUiState('error');
			setErrorMessage(data.resetOldPassword.message);
		}
	}, [status, data, loading]);

	useEffect(() => {
		if (error) {
			const { graphQLErrors } = error;
			if (graphQLErrors[0].extensions.category === 'validation') {
				const validationErrors = graphQLErrors[0].extensions.validation;
				setErrorMessage(Object.values(Object.values(validationErrors).map((validationError) => validationError.join('<br />'))).join('<br />'));
			} else {
				setErrorMessage(error.message);
			}
		} else {
			setErrorMessage(null);
		}
	}, [error]);

	const handleResetOldPassword = () => {
		if (oldPassword !== password) {
			setErrorMessage(null);
			resetOldPassword({ variables: { old_password: oldPassword, password, password_confirmation: passwordConfirmation }});
		} else {
			setErrorMessage(t('change_password_error_same'));
		}
	};

	return (
		<div className="flex flex-col justify-center min-h-screen py-12 sm:pb-48 sm:px-6 lg:px-8">
			<div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
				<img className="w-auto h-12 mx-auto" src={`${process.env.REACT_APP_BASE_URL}/logo`} alt="Logo" />
				{ uiState !== 'success' && (
					<>
						<H2 title={t('change_password_title')} className="mt-8 text-bowl-typo-secondary" />
						<H3 title={t('change_password_expired_subtitle')} className="mt-2 text-bowl-typo-secondary" />
					</>
				)}
				{/* TODO: Wird nie angezeigt */}
				{ uiState === 'success' && (
					<>
						<H3 title={t('change_password_success')} className="mt-8 text-bowl-typo-secondary" />
						<div className="mt-8 text-center">
							<SubmitButton type="button" title={t('change_password_continue')} handleClick={() => { navigate('/', { replace: true }); }} />
						</div>
					</>
				)}
			</div>

			{ uiState !== 'success' && (
				<div className="relative mx-4 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
						<form action="#" method="POST" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
							<InputField fieldName="old_password" label={t('change_password_old_password')} type="password" value={oldPassword} placeholder={t('change_password_old_password_placeholder')} handleChange={setOldPassword} />

							<div className="mt-6">
								<InputField fieldName="password" label={t('change_password_password')} type="password" value={password} placeholder={t('change_password_password_placeholder')} handleChange={setPassword} />
							</div>

							<div className="mt-6">
								<InputField fieldName="password_confirmation" label={t('change_password_password_confirmation')} type="password" value={passwordConfirmation} placeholder={t('change_password_password_confirmation_placeholder')} handleChange={setPasswordConfirmation} />
							</div>
							<ErrorMessage message={errorMessage} isHtml />
							<div className="mt-6">
								<SubmitButton type="submit" title={t('change_password_button')} loading={loading} disabled={oldPassword === '' || password === '' || passwordConfirmation === ''} handleClick={handleResetOldPassword} />
							</div>

						</form>
					</div>
				</div>
			)}
		</div>
	);
};

export default PasswordExpired;
