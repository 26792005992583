import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../BOWL-Common/utils/dates';
import { useIsAlertWindowOpen } from '../../../BOWL-Common/hooks/global/useIsAlertWindowOpen';
import { setIsAlertModalOpenVar, setIsAlertWindowOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import { useAlerts } from '../../../BOWL-Common/hooks/alerts/useAlerts';
import Transition from '../../partials/Transition';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../hooks/useOnKeydownEscape';
import { CloseIcon } from '../../partials/Icons';
import { H2 } from '../../public/screens/partials/Headlines';

const AlertWindow = () => {
	const { t } = useTranslation();
	const { alerts, loading, error } = useAlerts();

	return (
		<AlertWindowWrapper>
			{loading && (
				<span className="block px-6 py-2 text-sm leading-5 text-center text-bowl-topbar-secondary" role="listitem">{t('loading')}</span>
			)}
			{!loading && error && (
				<span className="block px-6 py-2 text-sm leading-5 text-center text-bowl-topbar-secondary" role="listitem">{t('error')}</span>
			)}
			{!loading && !error && alerts.length === 0 && (
				<span className="block px-6 py-2 text-sm leading-5 text-center text-bowl-topbar-secondary" role="listitem">{t('alert_no_notifications')}</span>
			)}
			{!loading && !error && alerts.length > 0 && (
				<>
					{alerts.map((alert) => (
						<AlertCell key={`alert_${alert.id}`} alert={alert} />
					))}
				</>
			)}
		</AlertWindowWrapper>
	);
};

const AlertWindowWrapper = ({ children }) => {
	const ref = useRef();
	const { t } = useTranslation();

	useOnClickOutside(ref, () => setIsAlertWindowOpenVar(false));
	useOnKeydownEscape(() => setIsAlertWindowOpenVar(false));

	return (
		<AlertWindowTransition>
			<div ref={ref} className="fixed left-0 right-0 z-20 overflow-hidden text-right rounded-b shadow-inner sm:right-2 bg-bowl-topbar sm:left-auto sm:w-96 sm:max-w-md sm:float-right h-searchOverlay sm:h-auto sm:max-h-topBarList">
				<div className="overflow-x-hidden overflow-y-auto h-searchOverlay sm:h-auto sm:max-h-topBarList">
					<header className="p-3 pb-0 sm:px-6">
						<div className="flex items-center justify-between space-x-3">
							<H2 className="mx-auto text-lg leading-7 text-bowl-light sm:ml-0" title={t('alert_window_title')} />
							<button onClick={() => setIsAlertWindowOpenVar(false)} type="button" className="items-center justify-center hidden transition duration-150 ease-in-out text-bowl-topbar-secondary sm:inline-flex focus:outline-none hover-hover:hover:text-bowl-light focus:text-bowl-light">
								<CloseIcon />
							</button>
						</div>
					</header>
					<div className="p-4 pt-0" role="list" aria-labelledby="alert-list">
						{children}
					</div>
				</div>
			</div>
		</AlertWindowTransition>
	);
};

const AlertCell = ({ alert }) => {
	const { id, title, date, lastSeen } = alert;

	const openAlert = (alertId) => {
		setIsAlertWindowOpenVar(false);
		setIsAlertModalOpenVar(true, alertId);
	};

	let bgColor = 'bg-bowl-topbar-secondary hover-hover:hover:bg-bowl-light focus:bg-bowl-light ';
	let textColor = 'text-bowl-topbar';
	let dateColor = 'text-bowl-date';
	let titleClasses = '';
	if (alert.pinned) {
		bgColor = 'bg-bowl-alert hover-hover:hover:bg-bowl-alert-secondary focus:bg-bowl-alert-secondary';
		textColor = 'text-white';
		dateColor = 'text-white';
	} else if (!lastSeen) {
		bgColor = 'bg-bowl-light';
		titleClasses = 'font-bold';
	}

	return (
		<div role="listitem" className="relative">
			<button type="button" onClick={() => openAlert(id)} className={`w-full px-6 py-2 mt-4 text-sm text-left leading-5 ${textColor} transition duration-150 ease-in-out rounded-md focus:outline-none ${bgColor}`}>
				<div className={`text-sm truncate font-secondary ${titleClasses}`}>{title}</div>
				<div className={`text-xs ${dateColor}`}>{`${formatDate(date)}`}</div>
			</button>
			{!lastSeen && (<AlertBadge />)}
		</div>
	);
};

const AlertBadge = () => {
	const { t } = useTranslation();

	return (
		<div style={{ filter: 'drop-shadow(0 0 0.25rem black)' }} className="absolute top-0 right-0 flex items-center justify-center flex-shrink-0 mx-auto mt-2 -mr-2 bg-red-600 rounded">
			<span className="text-white text-2xs px-2 pt-0.5">{t('alert_new')}</span>
		</div>
	);
};

const AlertWindowTransition = ({ children }) => {
	const { isAlertWindowOpen } = useIsAlertWindowOpen();
	return (
		<Transition
			show={isAlertWindowOpen}
			enter="transition ease-in-out duration-500 transform"
			enterFrom="-translate-y-full"
			enterTo="translate-y-0"
			leave="transition ease-in-out duration-500 transform"
			leaveFrom="translate-y-0"
			leaveTo="-translate-y-full"
		>
			{children}
		</Transition>
	);
};

export default AlertWindow;
