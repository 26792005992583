import { useQuery } from '@apollo/client';
import { QUERY_POST_BY_ID } from '../../graphql/posts';

export const usePostWithId = ({ postId }) => {
	const { data, loading, error } = useQuery(QUERY_POST_BY_ID, {
		variables: { id: postId },
		skip: postId === null,
	});

	const post = data?.post ?? null;
	return { post, loading, error };
};
