import { useEffect, createRef, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import usePinnedAlerts from '../../../../../BOWL-Common/hooks/alerts/usePinnedAlerts';
import MappedComponent from '../../../../../BOWL-Common/components/MappedComponent';
import { clearActiveAzIndexVar } from '../../../../../BOWL-Common/apollo/extensions/azindex';
import { useActiveAzIndex } from '../../../../../BOWL-Common/hooks/azindex/useActiveAzIndex';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../../../config/mapping';
import { usePinnedEvents } from '../../../../../BOWL-Common/hooks/calendar/usePinnedEvents';

const DefaultList = ({ posts }) => {
	const { data } = useActiveAzIndex();
	const activeAzIndex = data?.activeAzIndex ?? null;

	const postRefs = useMemo(() => [], []);

	const { state: locationState } = useLocation();
	const scrollToPostId = locationState?.scrollToPost?.id;

	const { alerts: pinnedAlerts } = usePinnedAlerts();
	const { events: pinnedEvents } = usePinnedEvents();
	const pinnedPadding = (pinnedAlerts?.length > 0 || pinnedEvents?.length > 0) ? 64 : 0;

	const scrollToPost = useCallback((postId) => {
		const ref = postRefs[`post_${postId}`];
		if (ref) {
			const y = ref.current.getBoundingClientRect().top + window.pageYOffset - 74 - pinnedPadding;
			window.scrollTo({ top: y, behavior: 'smooth' });
		}
	}, [postRefs, pinnedPadding]);

	useEffect(() => {
		if (scrollToPostId) {
			scrollToPost(scrollToPostId);
		}
	}, [scrollToPostId, scrollToPost]);

	useEffect(() => {
		if (activeAzIndex && posts) {
			const firstPost = posts.find(
				(post) => post.name && post.name[0] === activeAzIndex,
			);

			if (firstPost) {
				scrollToPost(firstPost.id);
			} else {
				window.scrollTo({ top: 74 + pinnedPadding, behavior: 'smooth' });
			}
			clearActiveAzIndexVar(null);
		}
	}, [activeAzIndex, posts, scrollToPost, pinnedPadding]);

	return (
		<div className="focus:outline-none">
			<div className="">
				{posts.map((post) => {
					const postRef = createRef();
					const key = `post_${post.id}`;
					postRefs[key] = postRef;

					return (
						<div key={key} ref={postRef}>
							<MappedComponent typeComponentMap={mapping} post={post} mapName="postItem" type={post.template} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DefaultList;
