import { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import MappedComponent from '../../../BOWL-Common/components/MappedComponent';
import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { useTrackInternalPostOpenEffect } from '../../../BOWL-Common/hooks/tracking/useTrackInternalPostOpenEffect';
import { QUERY_POST_PREVIEW_BY_ID } from '../../../BOWL-Common/graphql/posts';
import { setActiveCategoryVar } from '../../../BOWL-Common/apollo/extensions/categories';
import { PostContentLoader } from '../../partials/ContentLoader';
import { OpenCategoryEffect } from '../../partials/OpenCategoryEffect';
import ErrorScreen from '../../general/ErrorScreen';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../config/mapping';

const PostPreview = ({ post, category }) => {
	const { t } = useTranslation();
	const parentCategoryId = category?.parentCategory?.id;

	useEffect(() => {
		setActiveCategoryVar(category.id);

		return () => {
			setActiveCategoryVar(null);
		};
	}, [category.id]);

	// const changeSize = (type) => {
	// 	let width = null;

	// 	if (type === 'mobile') {
	// 		width = 540;
	// 	} if (type === 'tablet') {
	// 		width = 720;
	// 	} if (type === 'desktop') {
	// 		width = 1080;
	// 	}

	// 	if (width) {
	// 		window.resizeTo(width, window.outerHeight);
	// 	}
	// };

	useTrackPageViewEffect(`Post: ${post.name}`);
	useTrackInternalPostOpenEffect(post);

	return (
		<>
			<OpenCategoryEffect parentCategoryId={parentCategoryId} />
			<div className="flex justify-between items-center px-6 py-2 bowl-post-button-wrapper print:p-0">
				<div className="mb-1 text-2xl font-bold leading-7 font-secondary text-bowl-typo">
					{t('post_preview')}
				</div>
				{/* <div className="flex">
					<button type="button" onClick={() => changeSize('mobile')} className="relative inline-flex items-center cursor-pointer rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
						<span className="sr-only">Phone</span>
						<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path d="M8 16.25a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z" />
							<path fillRule="evenodd" d="M4 4a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm4-1.5v.75c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V2.5h1A1.5 1.5 0 0114.5 4v12a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 16V4A1.5 1.5 0 017 2.5h1z" clipRule="evenodd" />
						</svg>
					</button>
					<button type="button" onClick={() => changeSize('tablet')} className="relative inline-flex items-center cursor-pointer rounded-tb-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
						<span className="sr-only">Tablet</span>
						<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path fillRule="evenodd" d="M5 1a3 3 0 00-3 3v12a3 3 0 003 3h10a3 3 0 003-3V4a3 3 0 00-3-3H5zM3.5 4A1.5 1.5 0 015 2.5h10A1.5 1.5 0 0116.5 4v12a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 16V4zm5.25 11.5a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z" clipRule="evenodd" />
						</svg>
					</button>
					<button type="button" onClick={() => changeSize('desktop')} className="relative inline-flex items-center cursor-pointer rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
						<span className="sr-only">Desktop</span>
						<svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path fillRule="evenodd" d="M2 4.25A2.25 2.25 0 014.25 2h11.5A2.25 2.25 0 0118 4.25v8.5A2.25 2.25 0 0115.75 15h-3.105a3.501 3.501 0 001.1 1.677A.75.75 0 0113.26 18H6.74a.75.75 0 01-.484-1.323A3.501 3.501 0 007.355 15H4.25A2.25 2.25 0 012 12.75v-8.5zm1.5 0a.75.75 0 01.75-.75h11.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75H4.25a.75.75 0 01-.75-.75v-7.5z" clipRule="evenodd" />
						</svg>
					</button>
				</div> */}
			</div>
			<MappedComponent typeComponentMap={mapping} post={post} mapName="postItem" type={post.template} />
			<MappedComponent typeComponentMap={mapping} post={post} mapName="post" type={post.type} />
		</>
	);
};

const PostPreviewWrapper = () => {
	const { id } = useParams();
	const { data, loading, error, called } = useQuery(QUERY_POST_PREVIEW_BY_ID, { variables: { id }});
	const post = data?.postPreview;
	const category = post?.category;

	if (error) {
		return (<ErrorScreen error={error} />);
	}

	if (called && !loading && !error && post && category) {
		return <PostPreview post={post} category={category} />;
	}

	if (called && !loading && !post && !error) {
		return (<Navigate to="/" replace />);
	}

	return (<PostContentLoader />);
};

export default PostPreviewWrapper;
