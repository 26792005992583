import OrignalContentLoader from 'react-content-loader';

export const CategoryContentLoader = () => (
	<OrignalContentLoader
		speed={1}
		height={140}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ width: '100%' }}
	>
		<rect x="210" y="10" rx="3" ry="3" width="50%" height="14" />
		<rect x="210" y="40" rx="3" ry="3" width="20%" height="7" />
		<rect x="210" y="60" rx="3" ry="3" width="25%" height="7" />
		<rect x="210" y="80" rx="3" ry="3" width="30%" height="7" />
		<rect x="210" y="100" rx="3" ry="3" width="20%" height="7" />
		<rect x="0" y="10" rx="3" ry="3" width="200" height="100" />
	</OrignalContentLoader>
);

export const PostContentLoader = () => (
	<OrignalContentLoader
		speed={1}
		height={500}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ width: '100%' }}
	>
		<rect x="5%" y="420" rx="3" ry="3" width="50%" height="20" />
		<rect x="5%" y="460" rx="3" ry="3" width="70%" height="10" />
		<rect x="5%" y="480" rx="3" ry="3" width="85%" height="10" />
		<rect x="5%" y="500" rx="3" ry="3" width="50%" height="10" />
		<rect x="5%" y="520" rx="3" ry="3" width="65%" height="10" />
		<rect x="5%" y="540" rx="3" ry="3" width="45%" height="10" />
		<rect x="5%" y="560" rx="3" ry="3" width="25%" height="10" />
		<rect x="5%" y="10" rx="3" ry="3" width="90%" height="400" />
	</OrignalContentLoader>
);

export const DashboardContentLoader = () => (
	<OrignalContentLoader
		speed={1}
		height={67}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ width: '100%', marginBottom: '5px' }}
	>
		<rect x="0" y="5" rx="3" ry="3" width="20%" height="16" />
		<rect x="0" y="25" rx="3" ry="3" width={`${Math.floor(Math.random() * (80 - 40 + 1)) + 40}%`} height="16" />
		<rect x="0" y="46" rx="3" ry="3" width="40%" height="16" />
	</OrignalContentLoader>
);

export const SimpleContentLoader = () => (
	<OrignalContentLoader
		speed={1}
		height={30}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ width: '100%', marginBottom: '5px' }}
	>
		<rect x="0" y="10" rx="3" ry="3" width="100%" height="16" />
	</OrignalContentLoader>
);

export const SingleLineContentLoader = () => (
	<OrignalContentLoader
		speed={1}
		height={20}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ width: '100%', marginBottom: '5px' }}
	>
		<rect x="0" y="4" rx="3" ry="3" width={`${Math.floor(Math.random() * (80 - 40 + 1)) + 40}%`} height="16" />
	</OrignalContentLoader>
);
