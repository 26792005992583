import { gql } from '@apollo/client';

export const QUERY_IS_CALENDAR_FILTER_OPEN = gql`
	query isCalendarFilterOpen {
		isCalendarFilterOpen @client
	}
`;

export const QUERY_INACTIVE_SUBCALENDARS = gql`
	query inactiveSubcalendars {
		inactiveSubcalendars @client
	}
`;

export const QUERY_IS_EVENT_SHARE_MENU_OPEN = gql`
	query eventShareMenu {
		isEventShareMenuOpen @client
		eventShareMenuId @client
	}
`;

export const FRAGMENT_EVENT_FIELDS = gql`
	fragment EventFields on Event {
		id
		title
		description
		content
		start
		end
		endCorrect
		allDay
		color
		subcalendarSortOrder
		pinned
		subcalendar {
			id
			name
			color
		}
		connectedPosts {
			id
			postId
			name
			teaserText
			teaserImageUrl
			template
			displayDate
			releasedAt
			publishedAt
			type
			isVideo
			content
			url
			pdfUrl
			docUrl
			channel {
				id
				name
				imageUrl
			}
			category {
				id
				increasesBadgeCount
			}
		}
	}
`;

export const QUERY_PINNED_EVENTS = gql`
	query {
		pinnedEvents {
			...EventFields
		}
	}
	${FRAGMENT_EVENT_FIELDS}
`;

export const QUERY_ALL_EVENTS = gql`
	query {
		events {
			...EventFields
		}
	}
	${FRAGMENT_EVENT_FIELDS}
`;

export const QUERY_LATEST_EVENTS = gql`
	query LatestEvents {
		latestEvents {
			...EventFields
		}
	}
	${FRAGMENT_EVENT_FIELDS}
`;

export const QUERY_EVENTS_BY_SUBCALENDAR_IDS = gql`
	query eventsBySubcalendarIds($subcalendarIds: [ID!]){
		eventsBySubcalendarIds(subcalendarIds: $subcalendarIds) {
			events {
				...EventFields
			},
			dates
		}
	}
	${FRAGMENT_EVENT_FIELDS}
`;

export const QUERY_EVENT_BY_ID = gql`
	query Event($id: ID!) {
		event(id: $id) {
			...EventFields
		}
	}
	${FRAGMENT_EVENT_FIELDS}
`;

export const QUERY_LATEST_EVENTS_TODAY = gql`
	query LatestEventsToday {
		latestEventsToday {
			...EventFields
		}
	}
	${FRAGMENT_EVENT_FIELDS}
`;

export const QUERY_LATEST_EVENTS_SOON = gql`
	query LatestEventsSoon {
		latestEventsSoon {
			...EventFields
		}
	}
	${FRAGMENT_EVENT_FIELDS}
`;

export const FRAGMENT_SUBCALENDAR_FIELDS = gql`
	fragment SubcalendarFields on Subcalendar {
		id
		name
		color
	}
`;

export const QUERY_ALL_SUBCALENDARS = gql`
	query {
		subcalendars {
			...SubcalendarFields
		}
	}
	${FRAGMENT_SUBCALENDAR_FIELDS}
`;

export const QUERY_SUBCALENDAR_BY_ID = gql`
	query Subcalendar($id: ID!) {
		subcalendar(id: $id) {
			...SubcalendarFields
		}
	}
	${FRAGMENT_SUBCALENDAR_FIELDS}
`;
