import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetReadPost } from '../../../../../../BOWL-Common/hooks/user/useSetReadPost';
import { useSetUnreadPost } from '../../../../../../BOWL-Common/hooks/user/useSetUnreadPost';
import usePostHasBeenRead from '../../../../../../BOWL-Common/hooks/posts/usePostHasBeenRead';
import { useIsPostMenuOpen } from '../../../../../../BOWL-Common/hooks/global/useIsPostMenuOpen';
import { setIsNoteModalOpenVar, setIsPostMenuOpenVar } from '../../../../../../BOWL-Common/apollo/extensions/global';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../../../../hooks/useOnKeydownEscape';
import Transition from '../../../../../partials/Transition';
import { DotsHorizontalIcon } from '../../../../../partials/Icons';
import PostId from '../../partials/PostId';

const PostMenu = ({ post, showPostId = false, showHasBeenRead = true }) => {
	const { t } = useTranslation();

	const { category } = post;
	const hasBeenRead = usePostHasBeenRead({ postId: post.id });

	const { isPostMenuOpen, postId } = useIsPostMenuOpen();
	const isThisPostMenuOpen = isPostMenuOpen && postId === post.id;

	const ref = useRef();
	useOnClickOutside(ref, () => setIsPostMenuOpenVar(false));
	useOnKeydownEscape(() => setIsPostMenuOpenVar(false));

	const showPostMenu = showPostId || category?.increasesBadgeCount;

	const [userHasReadPost] = useSetReadPost();
	const [userHasUnreadPost] = useSetUnreadPost();

	if (showPostMenu) {
		return (
			<div className={`${isThisPostMenuOpen ? 'mb-18' : ''}`}>
				<Transition
					show={isThisPostMenuOpen}
					enter="transition ease-out duration-200"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					{/* TODO: Change to variable width or max-w? */}
					<div ref={ref} className="absolute z-10 w-48 origin-top-right rounded-md shadow-xl right-1 top-1 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby={`post-menu-${postId}`}>
						<div className="bg-white rounded-md shadow-xs" role="none">
							<div className="py-1" role="none">
								{showPostId && (
									<PostMenuItemWrapper>
										<PostId postId={post.postId} big postMenu />
									</PostMenuItemWrapper>
								)}
								{category?.increasesBadgeCount && hasBeenRead && showHasBeenRead && (
									<PostMenuItemWrapper>
										<PostMenuItemButton title={t('mark_as_unread')} callback={() => userHasUnreadPost({ variables: { postId: post.id }})} />
									</PostMenuItemWrapper>
								)}
								{category?.increasesBadgeCount && !hasBeenRead && showHasBeenRead && (
									<PostMenuItemWrapper>
										<PostMenuItemButton title={t('mark_as_read')} callback={() => userHasReadPost({ variables: { postId: post.id }})} />
									</PostMenuItemWrapper>
								)}
								<PostMenuItemWrapper>
									<PostMenuItemButton title={t('create_bookmark')} callback={() => setIsNoteModalOpenVar(true, null, post.id)} />
								</PostMenuItemWrapper>
							</div>
						</div>
					</div>
				</Transition>
				<ButtonOpenPostMenu post={post} />
			</div>
		);
	}

	return null;
};


const PostMenuItemButton = ({ title, callback }) => {
	const handleClick = () => {
		if (callback) {
			setIsPostMenuOpenVar(false);
			callback();
		}
	};
	return (
		<button
			type="button"
			onClick={() => handleClick()}
			className="flex flex-row items-center flex-grow h-full px-4 py-2 focus:outline-none"
		>
			{title}
		</button>
	);
};

const PostMenuItemWrapper = ({ children }) => (
	<div
		role="menuitem"
		className="flex items-center w-full h-10 text-sm text-left group text-bowl-typo hover:bg-bowl-light"
	>
		{children}
	</div>
);


const ButtonOpenPostMenu = ({ post }) => (
	<button
		type="button"
		onClick={() => setIsPostMenuOpenVar(true, post.id)}
		className={`inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md ${post.template !== 'alert' ? 'text-bowl-topbar hover-hover:hover:text-bowl-topbar focus:text-bowl-topbar hover-hover:hover:bg-bowl-light focus:bg-bowl-light' : 'text-white hover-hover:hover:text-bowl-alert focus:text-white hover-hover:hover:bg-white focus:bg-white'} focus:outline-none  print:hidden`}
		aria-labelledby={`open-bk-${post.id}-post-menu`}
		aria-expanded="false"
	>
		<DotsHorizontalIcon />
	</button>
);


export default PostMenu;
