import { useQuery } from '@apollo/client';
import { QUERY_ALL_SUBCALENDARS, QUERY_EVENTS_BY_SUBCALENDAR_IDS, QUERY_INACTIVE_SUBCALENDARS } from '../../graphql/calendar';

export const useGetEvents = () => {
	const {
		data: inactiveSubcalendarsData,
		loading: inactiveSubcalendarsLoading,
		error: inactiveSubcalendarsError,
	} = useQuery(QUERY_INACTIVE_SUBCALENDARS);

	const inactiveSubcalendars = inactiveSubcalendarsData?.inactiveSubcalendars;

	const {
		data: subcalendarsData,
		loading: allSubcalendarsLoading,
		error: allSubcalendarsError,
	} = useQuery(QUERY_ALL_SUBCALENDARS, {
		skip: (inactiveSubcalendarsLoading || inactiveSubcalendarsError),
	});

	const subcalendars = subcalendarsData?.subcalendars;
	const subcalendarIds = subcalendars?.map((subcalendar) => subcalendar.id);
	const neededSubcalendarIds = subcalendarIds?.filter((subcalendarId) => !inactiveSubcalendars.includes(subcalendarId));

	const {
		data: eventsFilteredData,
		loading: eventsBySubcalendarLoading,
		error: eventsBySubcalendarError,
	} = useQuery(QUERY_EVENTS_BY_SUBCALENDAR_IDS, {
		variables: { subcalendarIds: neededSubcalendarIds },
		skip: (inactiveSubcalendarsLoading || inactiveSubcalendarsError || allSubcalendarsLoading || allSubcalendarsError),
	});
	const events = eventsFilteredData?.eventsBySubcalendarIds;

	const data = { events: events?.events, dates: events?.dates };
	const loading = inactiveSubcalendarsLoading || allSubcalendarsLoading || eventsBySubcalendarLoading;
	const error = inactiveSubcalendarsError ?? allSubcalendarsError ?? eventsBySubcalendarError;

	return { data, loading, error };
};
