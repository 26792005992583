import { useRef } from 'react';
import { setIsLanguageMenuOpenVar } from '../../../../../../BOWL-Common/apollo/extensions/global';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../../../../hooks/useOnKeydownEscape';
import Transition from '../../../../../partials/Transition';
import { useIsLanguageMenuOpen } from '../../../../../../BOWL-Common/hooks/global/useIsLanguageMenuOpen';
import { ArrowIcon, LanguageSwitchIcon } from '../../../../../partials/Icons';
import { FlagBulgaria, FlagChina, FlagFrance, FlagGermany, FlagGreece, FlagPoland, FlagPortugual, FlagRomania, FlagRussia, FlagSpain, FlagTurkey, FlagUk, FlagUkraine } from '../../../../../partials/FlagIcons';

const LanguageMenu = ({ callback, loading }) => {
	const { isLanguageMenuOpen } = useIsLanguageMenuOpen();

	const ref = useRef();
	useOnClickOutside(ref, () => setIsLanguageMenuOpenVar(false));
	useOnKeydownEscape(() => setIsLanguageMenuOpenVar(false));

	return (
		<div className={`${isLanguageMenuOpen ? 'relative' : 'relative'}`}>
			<Transition
				show={isLanguageMenuOpen}
				enter="transition ease-out duration-200"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				{/* TODO: Change to variable width or max-w? */}
				<div ref={ref} className="absolute z-10 origin-top-right rounded-md shadow-xl -left-[40px] top-2 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="language-menu">
					{loading && (
						<div className="absolute w-full h-full bg-white-90 flex justify-center items-center">
							<div className="w-6 h-6 spinner" />
						</div>
					)}
					<div className="bg-gray-50 rounded-md border border-gray-100 shadow-xs p-4" role="none">
						<div className="flex">
							<div className="grid grid-cols-3 gap-x-2 gap-y-2 w-36" role="none">
								<LanguageItem callback={callback} language="en-US" />
								<LanguageItem callback={callback} language="de" />
								<LanguageItem callback={callback} language="fr" />
								<LanguageItem callback={callback} language="es" />
								<LanguageItem callback={callback} language="pt-PT" />
								<LanguageItem callback={callback} language="tr" />
								<LanguageItem callback={callback} language="pl" />
								<LanguageItem callback={callback} language="uk" />
								<LanguageItem callback={callback} language="bg" />
								<LanguageItem callback={callback} language="ru" />
								<LanguageItem callback={callback} language="ro" />
							</div>
						</div>
					</div>
				</div>
			</Transition>
			<ButtonOpenLanguageMenu />
		</div>
	);
};

const LanguageItem = ({ language, callback }) => (
	<button type="button" className="w-10" onClick={() => callback({ language })}>
		{language === 'tr' && <FlagTurkey w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'de' && <FlagGermany w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'en-US' && <FlagUk w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'bg' && <FlagBulgaria w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'uk' && <FlagUkraine w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'el' && <FlagGreece w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'es' && <FlagSpain w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'fr' && <FlagFrance w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'pl' && <FlagPoland w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'ro' && <FlagRomania w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'ru' && <FlagRussia w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'zh' && <FlagChina w="w-full" h="h-auto" misc="shadow-md" />}
		{language === 'pt-PT' && <FlagPortugual w="w-full" h="h-auto" misc="shadow-md" />}
	</button>
);

const ButtonOpenLanguageMenu = () => (
	<button
		type="button"
		onClick={() => setIsLanguageMenuOpenVar(true)}
		className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar focus:text-bowl-topbar hover-hover:hover:bg-bowl-light focus:bg-bowl-light focus:outline-none print:hidden"
		aria-labelledby="open-bk-language-menu"
		aria-expanded="false"
	>
		<div className="flex items-center">
			<LanguageSwitchIcon />
			<ArrowIcon w="w-4" h="h-4" direction="rotate-0" />
		</div>
	</button>
);

export default LanguageMenu;
