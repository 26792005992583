import { gql } from '@apollo/client';

export const QUERY_ACTIVE_AZ_INDEX = gql`
	query activeAzIndex {
		activeAzIndex @client
	}
`;

export default {
	QUERY_ACTIVE_AZ_INDEX,
};
