import { useEffect } from 'react';
import { usePrevious } from '../usePrevious';
import { useTrackCategoryOpen } from './useTrackCategoryOpen';

export const useTrackCategoryOpenEffect = (category) => {
	const trackCategoryOpen = useTrackCategoryOpen();
	const prevCategory = usePrevious(category);

	useEffect(() => {
		if (category) {
			if (prevCategory?.id !== category?.id) {
				trackCategoryOpen(category);
			}
		}
	}, [category, trackCategoryOpen, prevCategory?.id]);
};
