import { useTranslation } from 'react-i18next';
import { useTrackPageViewEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import LatestEvents from './widgets/LatestEvents';
import LatestNotes from './widgets/LatestNotes';
import LatestPosts from './widgets/LatestPosts';
// import Links from './widgets/Links';

const Dashboard = () => {
	const { t } = useTranslation();

	useTrackPageViewEffect(t('dashboard'));

	return (
		<div className="relative h-full flex flex-wrap content-start p-2">
			<div className="w-full pb-8">
				<LatestEvents />
			</div>
			<div className="w-full pb-8">
				<LatestPosts />
			</div>
			<div className="w-full pb-8">
				<LatestNotes />
			</div>
			{/* <div className="w-full lg:w-1/3 pb-8">
				<Links />
			</div> */}
		</div>
	);
};

export default Dashboard;
