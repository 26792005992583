import { useRef } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useSinglePost from '../../../../../BOWL-Common/hooks/posts/useSinglePost';
import useTopTextLine from '../../../../../BOWL-Common/hooks/posts/useTopTextLine';
import useParseContentRows from '../../../../../BOWL-Common/hooks/posts/useParseContentRows';
import MappedComponent from '../../../../../BOWL-Common/components/MappedComponent';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../../../config/mapping';
// eslint-disable-next-line import/no-cycle
import ConnectedPosts from './ConnectedPosts';
import { H1 } from './Elements';
import { ArrowIcon, DocumentIcon, DownloadIcon, ExternalLinkIcon, PrintIcon } from '../../../../partials/Icons';
import PostId from '../partials/PostId';
import useOpenPost from '../../../../../hooks/useOpenPost';
import SubmitButton from '../../../../public/screens/partials/SubmitButton';
import PostMenu from '../tiles/partials/PostMenu';
import { useTranslatePost } from '../../../../../BOWL-Common/hooks/posts/useTranslatePost';
import LanguageMenu from '../tiles/partials/LanguageMenu';

const PostDetail = ({ post }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const openPost = useOpenPost();

	const { category } = post;
	const { singlePost } = useSinglePost({ category });

	const { topTextLine } = useTopTextLine({ post });

	const [translatePost, { loading: loadingTranslation }] = useTranslatePost();

	const componentRef = useRef();
	const handlePrint = () => window.print();
	const { contentRows, parsingError } = useParseContentRows({ post });

	const { backToLatest } = useLocation();

	const renderContent = () => contentRows?.map((contentRow) => (
		<MappedComponent key={uuidv1()} typeComponentMap={mapping} mapName="postDetail" type={contentRow.type} {...contentRow} />
	));

	const handleBack = () => {
		if (backToLatest) {
			navigate('/latest');
		} else {
			navigate(`/category/${category.id}`);
		}
	};

	const handleOpenPost = () => {
		openPost(post);
	};

	const translateNow = ({ language }) => {
		translatePost({ variables: { postId: post.id, language }});
	};

	return (
		<div className="p-6 pt-0 bowl-post-button-wrapper print:p-0">
			{parsingError && (
				<h4>
					Error occured parsing post:
					<br />
					{parsingError}
					<br />
					{post?.content}
				</h4>
			)}
			{!parsingError && (
				<>
					<div className="flex py-2 -mx-4 print:p-0">
						{!singlePost && (
							<button
								type="button"
								onClick={() => handleBack()}
								className="p-2 transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-light focus:outline-none focus:bg-bowl-light focus:text-bowl-topbar print:hidden"
								aria-labelledby={t('back')}
								aria-expanded="false"
								title={t('back')}
							>
								<div className="flex flex-row items-center text-sm">
									<ArrowIcon direction="rotate-90" />
									<span className="mb-px mr-1">{t('back')}</span>
								</div>
							</button>
						)}
						<div className="flex items-center justify-between ml-auto mr-8">
							<PostId postId={post.postId} big />
							<LanguageMenu callback={translateNow} loading={loadingTranslation} />
							<button
								type="button"
								onClick={() => handlePrint()}
								className="p-2 ml-1 transition duration-150 ease-in-out rounded-md text-bowl-topbar hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-light focus:outline-none focus:bg-bowl-light focus:text-bowl-topbar print:hidden"
								aria-labelledby="print"
								aria-expanded="false"
								title="Print"
							>
								<PrintIcon />
							</button>
							<div className="absolute ml-auto top-1 right-1">
								<PostMenu post={post} showHasBeenRead={false} />
							</div>
						</div>
					</div>
					<div ref={componentRef} className="relative">
						{loadingTranslation && (
							<div className="absolute h-full w-full text-center max-w-165 text-black flex items-center justify-center">
								<div className="w-6 h-6 spinner" />
								<div className="ml-4">
									{t('loading')}
								</div>
							</div>
						)}
						<div className={loadingTranslation ? 'opacity-5' : ''}>
							<div className="mb-1 mr-8 text-xs leading-4 text-bowl-date">
								{topTextLine}
							</div>
							<H1 value={post.name} />
							{contentRows && (
								<div className="flex flex-col max-w-165 print:max-w-none">
									{renderContent()}
								</div>
							)}
							{post.type !== 'CONTENT' && (
								<div>
									<PostTypeInfo post={post} />
									<SubmitButton type="button" title={t('open_post')} handleClick={() => handleOpenPost()} />
								</div>
							)}
						</div>
					</div>
					<ConnectedPosts connectedPosts={post.connectedPosts} />
				</>
			)}
		</div>
	);
};

const PostTypeInfo = ({ post }) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-row items-center leading-4 mb-6">
			{post?.type === 'LINK' && (
				<>
					<ExternalLinkIcon />
					<div className="ml-1">{t('post_type_external_link')}</div>
				</>
			)}
			{post?.type === 'PDF' && (
				<>
					<DocumentIcon />
					<div className="ml-1">{t('post_type_pdf')}</div>
				</>
			)}
			{post?.type === 'DOC' && (
				<>
					<DownloadIcon />
					<div className="ml-1">{t('post_type_download')}</div>
				</>
			)}
		</div>
	);
};

export default PostDetail;
