import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLatestEventsToday } from '../../../../../BOWL-Common/hooks/calendar/useLatestEventsToday';
import { useLatestEventsSoon } from '../../../../../BOWL-Common/hooks/calendar/useLatestEventsSoon';
import { formatDatetime } from '../../../../../BOWL-Common/utils/dates';
import { DashboardContentLoader } from '../../../../partials/ContentLoader';
import { ArrowIcon, CalendarIcon } from '../../../../partials/Icons';
import WidgetBox from '../partials/WidgetBox';

const LatestEvents = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [isFilterActive, setIsFilterActive] = useState(true);

	const { events: latestEventsToday, loading: loadingLatestEventsToday, error: errorLatestEventsToday } = useLatestEventsToday();
	const { events: latestEventsSoon, loading: loadingLatestEventsSoon, error: errorLatestEventsSoon } = useLatestEventsSoon();

	const events = isFilterActive ? latestEventsToday : latestEventsSoon;
	const loadingEvents = isFilterActive ? loadingLatestEventsToday : loadingLatestEventsSoon;
	const errorEvents = isFilterActive ? errorLatestEventsToday : errorLatestEventsSoon;

	const icon = () => (
		<CalendarIcon w="w-8" h="h-8" color="text-white" />
	);

	const openEvent = useCallback((e, event) => {
		e.preventDefault();
		e.stopPropagation();
		navigate(`/calendar/${event.id}`);
	}, [navigate]);

	return (
		<WidgetBox title="activities" icon={icon} moreText="calendar_title" moreLink="/calendar" backgroundColor="bg-bowl-main">
			<div className="">
				<div className="flex pb-4">
					<button
						type="button"
						role="tab"
						aria-selected={isFilterActive}
						onClick={() => setIsFilterActive(true)}
						className={`px-1 text-center border-b-2 border-solid focus:outline-none ${isFilterActive ? ' border-white text-white' : 'border-transparent text-white-60 hover-hover:hover:text-white-90 hover-hover:hover:border-white-60'}`}
						aria-labelledby={t('this_week')}
						aria-controls={t('this_week')}
						title={t('this_week')}
					>
						<h2 className="pb-0.5 font-bold font-secondary text-xl">
							{t('this_week')}
						</h2>
					</button>
					<button
						type="button"
						role="tab"
						aria-selected={!isFilterActive}
						onClick={() => setIsFilterActive(false)}
						className={`ml-6 px-1 text-center border-b-2 border-solid focus:outline-none ${!isFilterActive ? ' border-white text-white' : 'border-transparent text-white-60 hover-hover:hover:text-white-90 hover-hover:hover:border-white-60'}`}
						aria-labelledby={t('next_week')}
						aria-controls={t('next_week')}
						title={t('next_week')}
					>
						<h2 className="pb-0.5 font-bold font-secondary text-xl">
							{t('next_week')}
						</h2>
					</button>
				</div>
				<div className="p-2 px-4 mb-4 overflow-hidden bg-white rounded-lg shadow">
					{loadingEvents && (
						<>
							<DashboardContentLoader />
							<DashboardContentLoader />
							<DashboardContentLoader />
						</>
					)}
					{!loadingEvents && !errorEvents && events && events.length > 0 && (
						<div className="focus:outline-none">
							<div className="">
								{events.map((event) => (
									<a href={`calendar/${event.id}`} key={event.id} className="flex items-center w-full pb-2 mb-2 text-left border-b last:mb-0 last:pb-0 border-b-gray-100 last:border-none" onClick={(e) => openEvent(e, event)}>
										<div className="flex-grow overflow-hidden border-l-[5px] pl-2 border-b-gray-100 mr-1" style={{ borderLeftColor: event?.color ?? '' }}>
											<div className="text-base leading-4 font-secondary text-bowl-main">
												{`${formatDatetime(event?.start)}${event?.end ? ` - ${formatDatetime(event.end)}` : ''} `}
											</div>
											{event?.title && (
												<div className="overflow-hidden text-base font-bold leading-5 font-secondary text-bowl-main whitespace-nowrap text-ellipsis">
													{event?.title}
												</div>
											)}
										</div>
										<div className="w-6">
											<ArrowIcon w="w-6" h="h-6" />
										</div>
									</a>
								))}
							</div>
						</div>
					)}
					<div className="">
						{!loadingEvents && !errorEvents && events && events.length === 0 && <CategoryMessage title={t('no_events')} />}
					</div>
				</div>
			</div>
		</WidgetBox>
	);
};

const CategoryMessage = ({ title }) => (
	<div className="m-2">
		<p className="text-base bowl-post-teaser-text">{title}</p>
	</div>
);

export default LatestEvents;
