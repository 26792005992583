import { makeVar } from '@apollo/client';

const initialState = {
	isCalendarFilterOpen: false,
	inactiveSubcalendars: [],
	isEventShareMenuOpen: false,
	eventShareMenuId: null,
};

export const isCalendarFilterOpenVar = makeVar(initialState.isCalendarFilterOpen);
export const setIsCalendarFilterOpenVar = (open) => isCalendarFilterOpenVar(open);

export const inactiveSubcalendarsVar = makeVar(initialState.inactiveSubcalendars);
export const setInactiveSubcalendarsVar = (subcalendarId) => {
	inactiveSubcalendarsVar([...inactiveSubcalendarsVar(), subcalendarId]);
};
export const setAllInactiveSubcalendarsVar = (subcalendarIds) => {
	inactiveSubcalendarsVar(subcalendarIds);
};
export const removeInactiveSubcalendarsVar = (subcalendarId) => {
	inactiveSubcalendarsVar(
		inactiveSubcalendarsVar().filter(
			(id) => id !== subcalendarId,
		),
	);
};
export const removeAllInactiveSubcalendarsVar = () => {
	inactiveSubcalendarsVar([]);
};

export const isEventShareMenuOpenVar = makeVar(initialState.isEventShareMenuOpen);
export const eventShareMenuIdVar = makeVar(initialState.eventShareMenuId);
export const setIsEventShareMenuOpenVar = (open, id = null) => {
	isEventShareMenuOpenVar(open);
	eventShareMenuIdVar(id);
};

export const calendar = {
	name: 'Calendar',
	initialState: () => {
		isCalendarFilterOpenVar(initialState.isCalendarFilterOpen);
		inactiveSubcalendarsVar(initialState.inactiveSubcalendars);
		isEventShareMenuOpenVar(initialState.isEventShareMenuOpen);
	},
	typePolicies: {
		Query: {
			fields: {
				isCalendarFilterOpen: {
					read: () => isCalendarFilterOpenVar(),
				},
				inactiveSubcalendars: {
					read: () => inactiveSubcalendarsVar(),
				},
				isEventShareMenuOpen: {
					read: () => isEventShareMenuOpenVar(),
				},
				eventShareMenuId: {
					read: () => eventShareMenuIdVar(),
				},
			},
		},
	},
};

export default calendar;
