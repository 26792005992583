import useOpenPost from '../../../../../hooks/useOpenPost';
import { DownloadIcon } from '../../../../partials/Icons';
import PostUnreadBadge from './partials/PostUnreadBadge';
import PostMenu from './partials/PostMenu';

const PostPlaner = ({ post }) => {
	const openPost = useOpenPost();

	return (
		<div className="mb-3 bowl-post-button-wrapper">
			<div className="absolute ml-auto top-1 right-1">
				<PostMenu post={post} showPostId />
			</div>
			<button type="button" onClick={() => openPost(post)} className="py-2 bowl-post-button-inner">
				<div className="flex flex-row">
					<div className="flex-shrink-0 mt-2 mr-2">
						<DownloadIcon />
					</div>
					<div className="flex flex-row items-start justify-center flex-grow">
						<div className="mt-[7.5px]"><PostUnreadBadge post={post} /></div>
						<h3 className="flex-grow mr-8 text-base bowl-post-headline">
							{post.name}
						</h3>
					</div>
				</div>
			</button>
		</div>
	);
};

export default PostPlaner;
