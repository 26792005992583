import { Routes, Navigate, Route, BrowserRouter } from 'react-router-dom';
import PublicRedirectRoute from './PublicRedirectRoute';
import PublicLayout from '../public/layouts/PublicLayout';
import SetDocumentTitle from './SetDocumentTitle';
import Login from '../public/screens/Login';
import ForgotPassword from '../public/screens/ForgotPassword';
import ResetPassword from '../public/screens/ResetPassword';
import AppMeta from '../public/screens/AppMeta';
import NotFound from '../partials/NotFound';
import PrivateAppMeta from '../private/screens/AppMeta';
import PrivateRoute from './PrivateRoute';
import PrivateLayout from '../private/layouts/PrivateLayout';
import Post from '../private/screens/Post';
import PostPreview from '../private/screens/PostPreview';
import DefaultCategory from '../private/screens/category/DefaultCategory';
import CategoryById from '../private/screens/category/CategoryById';
import Latest from '../private/screens/Latest';
import PasswordExpired from '../public/screens/PasswordExpired';
import Calendar from '../private/screens/calendar/Calendar';
import Event from '../private/screens/calendar/Event';
import Dashboard from '../private/screens/dashboard/Dashboard';
import Surveys from '../private/screens/surveys/Surveys';
import SurveyDetail from '../private/screens/surveys/SurveyDetail';
import NoteOverview from '../private/screens/notes/NoteOverview';

const RootRouter = () => (
	<BrowserRouter>
		<Routes>

			<Route
				path="/login"
				element={(
					<PublicRedirectRoute>
						<PublicLayout>
							<SetDocumentTitle type="login" />
							<Login />
						</PublicLayout>
					</PublicRedirectRoute>
				)}
			/>

			<Route
				path="/forgot-password"
				element={(
					<PublicRedirectRoute>
						<PublicLayout>
							<SetDocumentTitle type="forgot-password" />
							<ForgotPassword />
						</PublicLayout>
					</PublicRedirectRoute>
				)}
			/>

			<Route
				path="/reset-password/:token"
				element={(
					<PublicRedirectRoute>
						<PublicLayout>
							<SetDocumentTitle type="reset-password" />
							<ResetPassword />
						</PublicLayout>
					</PublicRedirectRoute>
				)}
			/>

			<Route
				path="/imprint"
				element={(
					<PublicRedirectRoute>
						<PublicLayout>
							<SetDocumentTitle type="imprint" />
							<AppMeta type="imprint" />
						</PublicLayout>
					</PublicRedirectRoute>
				)}
			/>


			<Route
				path="/password-expired"
				element={(
					<PrivateRoute passwordExpirationCheck={false}>
						<PublicLayout>
							<SetDocumentTitle type="password-expired" />
							<PasswordExpired />
						</PublicLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				exact
				path="/"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="category" />
							<DefaultCategory />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/category/:id"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="category" />
							<CategoryById />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/post/:id"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="post" />
							<Post />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/preview/:id"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="post-preview" />
							<PostPreview />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/disclaimer"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="imprint" />
							<PrivateAppMeta type="imprint" />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/overview"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="dashboard" />
							<Dashboard />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/privacy-notice"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="privacy" />
							<PrivateAppMeta type="privacy" />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/calendar"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="calendar" />
							<Calendar />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/calendar/:id"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="event" />
							<Event />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/surveys"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="surveys" />
							<Surveys />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/surveys/:id"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="surveys" />
							<SurveyDetail />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/notes"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="notes" />
							<NoteOverview />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/latest"
				element={(
					<PrivateRoute>
						<PrivateLayout>
							<SetDocumentTitle type="latest" />
							<Latest />
						</PrivateLayout>
					</PrivateRoute>
				)}
			/>

			<Route
				path="/"
				element={(
					<PublicRedirectRoute path={['/']}>
						<PublicLayout>
							<Navigate to={{ pathname: '/login' }} replace />
						</PublicLayout>
					</PublicRedirectRoute>
				)}
			/>

			<Route
				path="/404"
				element={(
					<>
						<SetDocumentTitle type="404" />
						<NotFound />
					</>
				)}
			/>

			<Route path="*" element={<Navigate replace to="/404" />} />
		</Routes>
	</BrowserRouter>
);

export default RootRouter;
