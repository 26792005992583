import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipboardCheckIcon, ClipboardCopyIcon } from '../../../../partials/Icons';

const PostId = ({ postId, big = false, postMenu = false }) => {
	const { t } = useTranslation();
	const [isPostIdCopied, setIsPostIdCopied] = useState(false);

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(`${postId}`);
		setIsPostIdCopied(true);
		setTimeout(() => setIsPostIdCopied(false), 2000);
	};

	return (
		<button
			type="button"
			onClick={() => handleCopyToClipboard()}
			className={`flex flex-row items-center ${!postMenu ? `${big ? 'p-3' : 'ml-1 mt-1 p-1'}` : 'px-4 py-2 flex-grow h-full'} transition duration-150 ease-in-out rounded-md group text-bowl-topbar ${isPostIdCopied ? '' : 'hover-hover:hover:bg-bowl-light hover-hover:hover:text-bowl-topbar'} focus:outline-none focus:bg-bowl-light focus:text-bowl-topbar`}
			aria-labelledby="copy-to-clipboard"
			aria-expanded="false"
			title={t('copy_to_clipboard')}
			disabled={isPostIdCopied}
		>
			<span className={`leading-none ${big ? 'text-sm' : 'text-xs'} ${isPostIdCopied ? 'pr-0' : 'hover-hover:pr-4 hover-hover:group-hover:pr-0 group-focus:pr-0'}`}>
				{postId}
			</span>
			<div className="h-4 ml-1">
				{!isPostIdCopied && <ClipboardCopyIcon misc="hover-hover:hidden hover-hover:group-hover:block group-focus:block" />}
				{isPostIdCopied && <ClipboardCheckIcon />}
			</div>
		</button>

	);
};

export default PostId;
