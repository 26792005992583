import { useParams, Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTrackPageViewEffect } from '../../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import EventDetail from './EventDetail';
import ErrorScreen from '../../../general/ErrorScreen';
import { PostContentLoader } from '../../../partials/ContentLoader';
import { QUERY_EVENT_BY_ID } from '../../../../BOWL-Common/graphql/calendar';

const Event = ({ event }) => {
	useTrackPageViewEffect(`Event: ${event.title}`);
	// TODO: track event needed?
	// useTrackInternalEventOpenEffect(event);

	return (
		<EventDetail event={event} />
	);
};

const EventWrapper = () => {
	const { id } = useParams();
	const { data, loading, error, called } = useQuery(QUERY_EVENT_BY_ID, { variables: { id }});
	const event = data?.event;

	if (error) {
		return (<ErrorScreen error={error} />);
	}

	if (called && !loading && !error && event) {
		return <Event event={event} />;
	}

	if (called && !loading && !event && !error) {
		return (<Navigate to="/" replace />);
	}

	return (<PostContentLoader />);
};

export default EventWrapper;
