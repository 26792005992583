import { gql } from '@apollo/client';

export const QUERY_IS_SIDEBAR_OPEN = gql`
	query isSidebarOpen {
		isSidebarOpen @client
	}
`;

export const QUERY_IS_USER_MENU_OPEN = gql`
	query isUserMenuOpen {
		isUserMenuOpen @client
	}
`;

export const QUERY_IS_ALERT_WINDOW_OPEN = gql`
	query isAlertWindowOpen {
		isAlertWindowOpen @client
	}
`;

export const QUERY_IS_SEARCH_OVERLAY_OPEN = gql`
	query isSearchOverlayOpen {
		isSearchOverlayOpen @client
	}
`;

export const QUERY_IS_ALERT_MODAL_OPEN = gql`
	query alertModal {
		isAlertModalOpen @client
		alertModalId @client
	}
`;

export const QUERY_IS_POST_MENU_OPEN = gql`
	query postMenu {
		isPostMenuOpen @client
		postMenuId @client
	}
`;

export const QUERY_IS_LANGUAGE_MENU_OPEN = gql`
	query languageMenu {
		isLanguageMenuOpen @client
	}
`;

export const QUERY_IS_CHANGE_PASSWORD_MODAL_OPEN = gql`
	query changePasswordModal {
		isChangePasswordModalOpen @client
	}
`;

export const QUERY_IS_UPDATE_SKIPPED = gql`
	query isUpdateSkipped {
		isUpdateSkipped @client
	}
`;

export const QUERY_IS_SCREEN_LOCKED = gql`
	query screenLocked {
		isScreenLocked @client
		screenLockedMessage @client
	}
`;

export const QUERY_IS_NOTE_MODAL_OPEN = gql`
	query noteModal {
		isNoteModalOpen @client
		noteModalId @client
		noteModalPostId @client
	}
`;

export const QUERY_IS_IMAGE_ZOOM_MODAL_OPEN = gql`
	query imageZoomModal {
		isImageZoomModalOpen @client
		imageZoomModalImgPath @client
	}
`;
