import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { setIsSidebarOpenVar } from '../../../BOWL-Common/apollo/extensions';

// TODO: Get title + image from BACKEND
// TODO: Unread badge?
const NavItemStatic = ({ title, navigateTo, icon }) => {
	const { t } = useTranslation();
	const showNavImages = process.env.REACT_APP_NAVIGATION_SHOW_IMAGES === 'true';
	const showNavColor = process.env.REACT_APP_NAVIGATION_USE_COLORS === 'true';
	const classNames = 'w-full mt-2 flex items-center px-3 py-2 text-left text-base leading-6 transition duration-150 ease-in-out md:text-sm md:leading-5 group focus:outline-none';

	return (
		<dl>
			<div>
				<dt>
					<NavLink
						onClick={() => setIsSidebarOpenVar(false)}
						to={navigateTo}
						className={({ isActive }) => (isActive ? `text-bowl-activecategorytypo bg-bowl-activecategory focus:bg-bowl-activecategory ${classNames}` : `text-bowl-categorytypo hover-hover:hover:text-bowl-activecategorytypo hover-hover:hover:bg-bowl-activecategory focus:text-bowl-activecategorytypo focus:bg-bowl-activecategory ${classNames}`)}
					>
						{showNavImages && (
							<div className="flex items-center justify-center flex-shrink-0 mr-3 transition duration-150 ease-in-out md:mr-3">
								{icon}
							</div>
						)}
						<span className={`flex items-center min-h-6 flex-1 ${showNavColor ? 'border-l-4 border-solid pl-1' : ''}`} style={showNavColor ? { borderColor: 'transparent' } : {}}>
							<span className="text-xl font-bold font-secondary">
								{t(title)}
							</span>
						</span>
					</NavLink>
				</dt>
			</div>
		</dl>
	);
};

export default NavItemStatic;
