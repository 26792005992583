import { gql } from '@apollo/client';
import { FRAGMENT_POST_FIELDS } from './posts';

export const QUERY_POST_SEARCH_RESULT = gql`
	query SearchPosts($search: String, $page: Int!, $perPage: Int!) {
		searchPosts(search: $search, page: $page, perPage: $perPage) {
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
			}
			data {
				...PostFields
			}
		}
	}
	${FRAGMENT_POST_FIELDS}
`;
