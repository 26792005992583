export default {
	test: 'en',

	// general
	loading: 'Loading...',
	error: 'An error occured.',
	go_home: 'Back to the homepage',
	back: 'Back',

	not_found_title: '404 Page not found',
	imprint_title: 'Imprint',
	privacy_title: 'Privacy Notice',
	instructions_title: 'Instructions',
	latest_title: 'Latest',
	calendar_title: 'Calendar',

	// errors
	invalid_token: 'Your token expired or is invalid.',
	invalid_credentials: 'Invalid username or password.',
	error_network: 'An network error occured. Most likley you have no internet connection.',
	error_graphql: 'There was an unexpected application error. Please try again or contact an admin.',
	error_page_not_found: 'Oops... the requested page could not be found.',

	// login
	login_site_title: 'Login',
	login_title: 'Welcome back!',
	login_subtitle: "Let's sign in.",
	login_email: 'Email',
	login_email_placeholder: 'Enter your email address here.',
	login_password: 'Password',
	login_password_placeholder: 'Enter your password here.',
	login_remember: 'Remember me',
	login_forgot: 'Forgot your password?',
	login_sign_in: 'Sign in',

	// forgot password
	forgot_password_title: 'Forgot your password?',
	forgot_password_subtitle: 'Please enter your email address and we will send you a password reset link.',
	forgot_password_email: 'E-Mail',
	forgot_password_email_placeholder: 'Enter your email address here.',
	back_to_login: 'Back to Login',
	forgot_password_button: 'Reset Password',
	forgot_password_success: "We've sent you an password reset link via email. Please check your inbox.",

	// reset password
	reset_password_title: 'Password reset',
	reset_password_subtitle: 'Please choose your new password.',
	reset_password_email: 'E-Mail',
	reset_password_email_placeholder: 'Enter your email address here.',
	reset_password_password: 'New password',
	reset_password_password_placeholder: 'Choose a new password.',
	reset_password_password_confirmation: 'New password confirmation',
	reset_password_password_confirmation_placeholder: 'Retype your new password.',
	reset_password_button: 'Set new password',
	reset_password_success: 'Your password has been changed successfully.',

	// change password
	change_password_continue: 'Continue to the app',
	change_password: 'Change password',
	change_password_title: 'Change password',
	change_password_expired_title: 'Passwort expired',
	change_password_subtitle: 'Choose your new password.',
	change_password_expired_subtitle: 'Your old password has expired.',
	change_password_email: 'E-Mail',
	change_password_email_placeholder: 'Enter your email address here.',
	change_password_old_password: 'Old password',
	change_password_old_password_placeholder: 'Enter your old password here.',
	change_password_password: 'New password',
	change_password_password_placeholder: 'Enter your new password here.',
	change_password_password_confirmation: 'New password confirmation',
	change_password_password_confirmation_placeholder: 'Retype your new password.',
	change_password_button: 'Set new password',
	change_password_success: 'Your password has been changed successfully.',
	change_password_error_same: 'Your new password cannot be the same as the old one.',

	// top bar
	top_bar_search: 'Search',
	top_bar_sign_out: 'Sign out',

	// app update
	app_update_title: 'Update available',
	app_update_message: 'A new version is available.',
	app_update_confirm: 'Update now',
	app_update_skip: 'Later',

	// alert
	alert_close: 'Close',
	alert_read_message: 'Read message',
	alert_window_title: 'Notifications',
	alert_no_notifications: 'You have no notifications yet',
	alert_new: 'NEW',

	// posts
	related_posts: 'Linked attachments',
	shared: 'shared with the',
	mark_as_unread: 'Mark as unread',
	mark_as_read: 'Mark as read',
	copy_to_clipboard: 'Copy to clipboard',
	post_preview: 'Post preview',

	// search
	search_for_articles: 'Search for articles',
	search_no_result: 'No result',

	// post type
	post_type_external_link: 'External link',
	post_type_pdf: 'PDF',
	post_type_download: 'Download',
	post_type_article: 'Article',
	post_type_video: 'Video',

	// buttons
	mark_all_as_read: 'Mark all as read',
	filter_by_unread: 'Filter by unread',
	show_all: 'Show all',

	// category
	category_no_unread_posts: 'You have no unread posts in this category.',
	category_no_posts: 'This category has no posts.',

	// latest
	latest_posts: 'Latest',
	unread_posts: 'Unread',
	all_posts: 'All',

	open_post: 'Open now',

	// calendar
	sub_calendars: 'All subcalendars',
	event_close: 'Close',
	period: 'Period: ',
	all_day: 'All day',
	end: 'End',
	no_events: 'No events',
	file: 'file',
	january: 'January',
	february: 'February',
	march: 'March',
	april: 'April',
	may: 'May',
	june: 'June',
	july: 'July',
	august: 'August',
	september: 'September',
	october: 'October',
	november: 'November',
	december: 'December',
	januaryShort: 'Jan.',
	februaryShort: 'Feb.',
	marchShort: 'Mar.',
	aprilShort: 'Apr.',
	mayShort: 'May',
	juneShort: 'June',
	julyShort: 'July',
	augustShort: 'Aug.',
	septemberShort: 'Sept.',
	octoberShort: 'Oct.',
	novemberShort: 'Nov.',
	decemberShort: 'Dec.',
	sunday: 'Sunday',
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	sundayShort: 'S',
	mondayShort: 'M',
	tuesdayShort: 'T',
	wednesdayShort: 'W',
	thursdayShort: 'T',
	fridayShort: 'F',
	saturdayShort: 'S',
	today: 'Today',
	upcoming: 'Upcoming',
	this_week: 'This Week',
	next_week: 'Next Week',

	// access
	// access_title: 'Access needed',
	// access_message: 'BK Manager needs access to your your calendar and contacts to use this feature. For this purpose go to your settings app and allow the use of your calendar and contacts.',
	access_calendar_title: 'Calendar access needed',
	access_calendar_message: 'BK Manager needs access to your calendar to use this feature. For this purpose go to your settings app and allow the use of your calendar.',
	// access_contacts_title: 'Contacts access needed',
	// access_contacts_message: 'BK Manager needs access to your contacts to use this feature. For this purpose go to your settings app and allow the use of your contacts.',
	access_go_to_settings: 'Go to settings',
	access_close: 'Close',

	// pdf printing
	pdf_print_prepare: 'Preparing print process...',

	// dashboard
	overview: 'Overview',
	notes: 'Notes',
	note: 'Note',
	notes_and_bookmarks: 'Notes & Bookmarks',
	bookmark: 'Bookmark',
	all_notes: 'All Notes',
	events: 'Events',
	activities: 'Activities & Calendar',
	dashboard: 'Dashboard',
	timeframe: 'Timeframe',
	links: 'Links',

	// survey
	survey_submit: 'Send',
	survey_completed: 'Thank you for participating!',
	surveys_title: 'Surveys',
	no_surveys: 'No surveys available',
	survey_new: 'New',
	survey: 'Survey',
	survey_answer: 'Answer',

	// notes
	notes_title: 'Notes & Bookmarks',
	edit_note: 'Edit note',
	create_note: 'Create note',
	note_cancel: 'Cancel',
	note_close: 'Close',
	note_save: 'Save',
	no_note: '-',
	no_notes: 'No notes available.',
	note_edit: 'Edit',
	note_delete: 'Delete',
	note_placeholder: 'Please enter your note here.',
	create_bookmark: 'Create Bookmark',
	note_last_update: 'from',
};
