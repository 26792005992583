import { useTranslation } from 'react-i18next';
import { useTrackPageViewEffect } from '../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import ErrorScreen from '../general/ErrorScreen';

const NotFound = () => {
	const { t } = useTranslation();
	useTrackPageViewEffect(t('not_found_title'));
	return <ErrorScreen error={{ message: t('error_page_not_found') }} goHome showHeadline={false} />;
};

export default NotFound;
