import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useLazyQuerySearchPosts } from '../../../BOWL-Common/hooks/search/useLazyQuerySearchPosts';
import { useIsSearchOverlayOpen } from '../../../BOWL-Common/hooks/global/useIsSearchOverlayOpen';
import { setIsSearchOverlayOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import SearchOverlay from './SearchOverlay';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { ClearIcon, SearchGlassIcon } from '../../partials/Icons';

const SearchBar = () => {
	const { t } = useTranslation();
	const { isSearchOverlayOpen } = useIsSearchOverlayOpen();
	const [doSearch, { loading, posts }] = useLazyQuerySearchPosts();

	const [searchValue, setSearchValue] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const [isTyping, setIsTyping] = useState(false);

	const debouncedSearchInput = useDebouncedCallback(
		(search) => {
			setIsTyping(false);
			doSearch({ variables: { search, page: 1, perPage: 30 }, fetchPolicy: 'network-only' });
		},
		350,
	);

	const onChange = (value) => {
		setSearchValue(value);
		setIsTyping(true);
		debouncedSearchInput(value);
	};

	const onBlur = () => {
		if (isSearchOverlayOpen) {
			setIsSearchOverlayOpenVar(false);
			setIsFocused(false);
		}
	};

	const onFocus = () => {
		setIsSearchOverlayOpenVar(true);
		setIsFocused(true);
	};

	const ref = useRef();
	useOnClickOutside(ref, () => onBlur());

	const clear = () => {
		onBlur();
		onChange('');
	};

	return (
		<div ref={ref}>
			<span className="SearchBar">
				<label htmlFor="search" className="sr-only">
					{t('top_bar_search')}
				</label>
				<div className="relative">
					<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
						<SearchGlassIcon />
					</div>
					<input
						id="search"
						className="block w-full py-2 pl-10 pr-10 leading-5 transition duration-150 ease-in-out border border-transparent rounded-full font-primary bg-bowl-topbar-secondary text-bowl-topbar placeholder-bowl-topbar focus:outline-none focus:bg-white focus:text-bowl-typo focus:placeholder-bowl-typo sm:text-sm"
						placeholder={t('top_bar_search')}
						autoComplete="off"
						autoCorrect="off"
						onFocus={() => onFocus()}
						onChange={(e) => onChange(e.target.value || '')}
						value={searchValue}
					/>
					{(searchValue || isFocused) && (
						<button onClick={() => clear()} type="button" className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none">
							<ClearIcon />
						</button>
					)}
				</div>
			</span>
			<SearchOverlay posts={posts} searchValue={searchValue} loading={loading} isTyping={isTyping} />
		</div>
	);
};

export default SearchBar;
