import { v4 as uuidv4 } from 'uuid';
import { makeVar } from '@apollo/client';

const initialState = {
	notificationStack: [],
};

export const notificationStackVar = makeVar(initialState.notificationStack);
export const pushToNotificationStackVar = (message = '', type = '') => notificationStackVar([
	...notificationStackVar(),
	{
		uuid: uuidv4(),
		message,
		type,
	},
]);

export const popFromNotificationStackVar = (uuid) => notificationStackVar([
	...notificationStackVar().filter(
		(notification) => notification.uuid !== uuid,
	),
]);

export const notifications = {
	name: 'Notifications',
	initialState: () => {
		notificationStackVar(initialState.notificationStack);
	},
	typePolicies: {
		Query: {
			fields: {
				notificationStack: {
					read: () => notificationStackVar(),
				},
			},
		},
	},
};
export default notifications;
