import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { setIsNoteModalOpenVar } from '../../../../BOWL-Common/apollo/extensions';
import { formatDatetime } from '../../../../BOWL-Common/utils/dates';
import { BookmarkIcon, NoteIcon } from '../../../partials/Icons';

const NoteListItem = ({ note }) => {
	const { t } = useTranslation();

	const { id, updatedAt, text, post } = note;

	const openNote = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsNoteModalOpenVar(true, id);
	}, [id]);

	return (
		<button type="button" className="block w-full pb-2 mb-2 text-left border-b border-gray-100 last:border-none border-b-gray-100 last:mb-0" onClick={(e) => openNote(e)}>
			<div className="text-xs text-bowl-notes-item-date">
				{updatedAt ? formatDatetime(updatedAt) : ''}
			</div>
			<div className="flex flex-col mb-2">
				<h3 className="flex-grow overflow-hidden text-lg font-bold font-secondary text-bowl-main whitespace-nowrap text-ellipsis">
					{post?.name ?? text?.split(/\r?\n/)[0] ?? ''}
				</h3>
				<h4 className="overflow-hidden text-sm leading-none font-secondary text-bowl-main whitespace-nowrap text-ellipsis">
					{post?.name ? text?.split(/\r?\n/)[0] : ''}
				</h4>
			</div>
			<div className="flex items-center">
				{post && (
					<div className="flex items-center mr-6">
						<div className="mr-1">
							<BookmarkIcon w="w-3" h="h-3" color="text-bowl-main" />
						</div>
						<div className="text-2xs text-bowl-main">
							{t('bookmark')}
						</div>
					</div>
				)}
				{text && (
					<div className="flex items-center">
						<div className="mr-1">
							<NoteIcon w="w-3" h="h-3" color="text-bowl-main" />
						</div>
						<div className="text-2xs text-bowl-main">
							{t('note')}
						</div>
					</div>
				)}
			</div>
		</button>
	);
};

export default NoteListItem;
