import { makeVar } from '@apollo/client';

const initialState = {
	isSidebarOpen: false,
	isUserMenuOpen: false,
	isAlertWindowOpen: false,
	isSearchOverlayOpen: false,
	isAlertModalOpen: false,
	alertModalId: null,
	isPostMenuOpen: false,
	postMenuId: null,
	isLanguageMenuOpen: false,
	isChangePasswordModalOpen: false,
	isUpdateSkipped: false,
	isScreenLocked: false,
	screenLockedMessage: null,
	isNoteModalOpen: false,
	noteModalId: null,
	noteModalPostId: null,
	isImageZoomModalOpen: false,
	imageZoomModalImgPath: null,
};

export const isSidebarOpenVar = makeVar(initialState.isSidebarOpen);
export const setIsSidebarOpenVar = (open) => isSidebarOpenVar(open);

export const isUserMenuOpenVar = makeVar(initialState.isUserMenuOpen);
export const setIsUserMenuOpenVar = (open) => isUserMenuOpenVar(open);

export const isAlertWindowOpenVar = makeVar(initialState.isAlertWindowOpen);
export const setIsAlertWindowOpenVar = (open) => isAlertWindowOpenVar(open);

export const isAlertModalOpenVar = makeVar(initialState.isAlertModalOpen);
export const alertModalIdVar = makeVar(initialState.alertModalId);
export const setIsAlertModalOpenVar = (open, id = null) => {
	isAlertModalOpenVar(open);
	alertModalIdVar(id);
};

export const isPostMenuOpenVar = makeVar(initialState.isPostMenuOpen);
export const postMenuIdVar = makeVar(initialState.postMenuId);
export const setIsPostMenuOpenVar = (open, id = null) => {
	isPostMenuOpenVar(open);
	postMenuIdVar(id);
};

export const isLanguageMenuOpenVar = makeVar(initialState.isLanguageMenuOpen);
export const setIsLanguageMenuOpenVar = (open) => isLanguageMenuOpenVar(open);

export const isSearchOverlayOpenVar = makeVar(initialState.isSearchOverlayOpen);
export const setIsSearchOverlayOpenVar = (open) => isSearchOverlayOpenVar(open);

export const isChangePasswordModalOpenVar = makeVar(initialState.isChangePasswordModalOpen);
export const setIsChangePasswordModalOpenVar = (open) => isChangePasswordModalOpenVar(open);

export const isUpdateSkippedVar = makeVar(initialState.isUpdateSkipped);
export const setIsUpdateSkippedVar = (skipped) => isUpdateSkippedVar(skipped);

export const isScreenLockedVar = makeVar(initialState.isScreenLocked);
export const screenLockedMessageVar = makeVar(initialState.screenLockedMessage);
export const setIsScreenLockedVar = (locked, message = null) => {
	isScreenLockedVar(locked);
	screenLockedMessageVar(message);
};

export const isNoteModalOpenVar = makeVar(initialState.isNoteModalOpen);
export const noteModalIdVar = makeVar(initialState.noteModalId);
export const noteModalPostIdVar = makeVar(initialState.noteModalPostId);
export const setIsNoteModalOpenVar = (open, noteId = null, postId = null) => {
	isNoteModalOpenVar(open);
	noteModalIdVar(noteId);
	noteModalPostIdVar(postId);
};

export const isImageZoomModalOpenVar = makeVar(initialState.isImageZoomModalOpen);
export const imageZoomModalImgPathVar = makeVar(initialState.imageZoomModalImgPath);
export const setIsImageZoomModalOpenVar = (open, id = null) => {
	isImageZoomModalOpenVar(open);
	imageZoomModalImgPathVar(id);
};

export const global = {
	name: 'Global',
	initialState: () => {
		isSidebarOpenVar(initialState.isSidebarOpen);
		isUserMenuOpenVar(initialState.isUserMenuOpen);
		isAlertWindowOpenVar(initialState.isAlertWindowOpen);
		isAlertModalOpenVar(initialState.isAlertModalOpen);
		isPostMenuOpenVar(initialState.isPostMenuOpen);
		isLanguageMenuOpenVar(initialState.isLanguageMenuOpen);
		isSearchOverlayOpenVar(initialState.isSearchOverlayOpen);
		isChangePasswordModalOpenVar(initialState.isChangePasswordModalOpen);
		isUpdateSkippedVar(initialState.isUpdateSkipped);
		isScreenLockedVar(initialState.isScreenLocked);
		isNoteModalOpenVar(initialState.isNoteModalOpen);
		isImageZoomModalOpenVar(initialState.isImageZoomModalOpen);
	},
	typePolicies: {
		Query: {
			fields: {
				isSidebarOpen: {
					read: () => isSidebarOpenVar(),
				},
				isUserMenuOpen: {
					read: () => isUserMenuOpenVar(),
				},
				isAlertWindowOpen: {
					read: () => isAlertWindowOpenVar(),
				},
				isAlertModalOpen: {
					read: () => isAlertModalOpenVar(),
				},
				alertModalId: {
					read: () => alertModalIdVar(),
				},
				isPostMenuOpen: {
					read: () => isPostMenuOpenVar(),
				},
				postMenuId: {
					read: () => postMenuIdVar(),
				},
				isLanguageMenuOpen: {
					read: () => isLanguageMenuOpenVar(),
				},
				isSearchOverlayOpen: {
					read: () => isSearchOverlayOpenVar(),
				},
				isChangePasswordModalOpen: {
					read: () => isChangePasswordModalOpenVar(),
				},
				isUpdateSkipped: {
					read: () => isUpdateSkippedVar(),
				},
				isScreenLocked: {
					read: () => isScreenLockedVar(),
				},
				screenLockedMessage: {
					read: () => screenLockedMessageVar(),
				},
				isNoteModalOpen: {
					read: () => isNoteModalOpenVar(),
				},
				noteModalId: {
					read: () => noteModalIdVar(),
				},
				noteModalPostId: {
					read: () => noteModalPostIdVar(),
				},
				isImageZoomModalOpen: {
					read: () => isImageZoomModalOpenVar(),
				},
				imageZoomModalImgPath: {
					read: () => imageZoomModalImgPathVar(),
				},
			},
		},
	},
};

export default global;
