import { setContext } from '@apollo/link-context';
import Axios from 'axios';

let xsrfPromise = null;
const setupFetchXsrfCookieLink = ({ csrfTokenEndpointPath }) => setContext((request, previousContext) => {
	if (previousContext.fetchToken && !xsrfPromise) {
		xsrfPromise = Axios.get(
			csrfTokenEndpointPath,
			{
				withCredentials: true,
			},
		);
	}
	if (xsrfPromise) {
		return xsrfPromise;
	}

	return null;
});

export default setupFetchXsrfCookieLink;
