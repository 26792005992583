import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useResetPasswordMutation } from '../../../BOWL-Common/hooks/auth/useResetPasswordMutation';
import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';

import SubmitButton from './partials/SubmitButton';
import InputField from './partials/InputField';
import ErrorMessage from './partials/ErrorMessage';
import TextLink from './partials/TextLink';
import { H2, H3 } from './partials/Headlines';

const ResetPassword = () => {
	const { t } = useTranslation();
	useTrackPageViewEffect(t('reset_password_title'));

	const params = useParams();

	const [resetPassword, { data, loading, error }] = useResetPasswordMutation();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const [uiState, setUiState] = useState('idle');
	const [errorMessage, setErrorMessage] = useState(null);
	const status = data?.resetPassword?.status ?? '';

	useEffect(() => {
		if (loading === true) {
			setUiState('loading');
		} else if (status === '') {
			setUiState('idle');
		} else if (status === 'PASSWORD_UPDATED') {
			setUiState('success');
		} else if (status === 'PASSWORD_NOT_UPDATED') {
			setUiState('error');
			setErrorMessage(data.resetPassword.message);
		}
	}, [status, data, loading]);

	useEffect(() => {
		if (error) {
			const { graphQLErrors } = error;
			if (graphQLErrors[0].extensions.category === 'validation') {
				const validationErrors = graphQLErrors[0].extensions.validation;
				setErrorMessage(Object.values(Object.values(validationErrors).map((validationError) => validationError.join('<br />'))).join('<br />'));
			} else {
				setErrorMessage(error.message);
			}
		} else {
			setErrorMessage(null);
		}
	}, [error]);

	const handleResetPassword = () => {
		setErrorMessage(null);
		resetPassword({ variables: { token: params.token, email, password, password_confirmation: passwordConfirmation }});
	};

	return (
		<div className="flex flex-col justify-center min-h-screen py-12 sm:pb-48 sm:px-6 lg:px-8">
			<div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
				<img className="w-auto h-12 mx-auto" src={`${process.env.REACT_APP_BASE_URL}/logo`} alt="Logo" />
				{ uiState !== 'success' && (
					<>
						<H2 title={t('reset_password_title')} className="mt-8 text-bowl-typo-secondary" />
						<H3 title={t('reset_password_subtitle')} className="mt-2 text-bowl-typo-secondary" />
					</>
				)}
				{ uiState === 'success' && (
					<>
						<H3 title={t('reset_password_success')} className="mt-8 text-bowl-typo-secondary" />
						<div className="mt-8 text-center">
							<TextLink href="/login" text={t('back_to_login')} title={t('back_to_login')} />
						</div>
					</>
				)}
			</div>

			{ uiState !== 'success' && (
				<div className="relative mx-4 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
						<form action="#" method="POST" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
							<InputField fieldName="email" label={t('reset_password_email')} type="email" value={email} placeholder={t('reset_password_email_placeholder')} handleChange={setEmail} />

							<div className="mt-6">
								<InputField fieldName="password" label={t('reset_password_password')} type="password" value={password} placeholder={t('reset_password_password_placeholder')} handleChange={setPassword} />
							</div>

							<div className="mt-6">
								<InputField fieldName="password_confirmation" label={t('reset_password_password_confirmation')} type="password" value={passwordConfirmation} placeholder={t('reset_password_password_confirmation_placeholder')} handleChange={setPasswordConfirmation} />
							</div>
							<ErrorMessage message={errorMessage} isHtml />
							<div className="mt-6">
								<SubmitButton type="submit" title={t('reset_password_button')} loading={loading} disabled={password === '' || passwordConfirmation === '' || email === ''} handleClick={handleResetPassword} />
							</div>

						</form>
						<div className="flex items-center justify-between mx-1 mt-8">
							<div className="flex items-center">
								<TextLink href="/login" text={t('back_to_login')} title={t('back_to_login')} />
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ResetPassword;
