import { Navigate, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useUserMe } from '../../BOWL-Common/hooks/user/useUserMe';
import { useAuthStatus } from '../../BOWL-Common/hooks/auth/useAuthStatus';

// A wrapper for <Route> that redirects to the login if the user is not authenticated
const PrivateRoute = ({ children, passwordExpirationCheck = true }) => {
	const { data = {}, loading } = useAuthStatus();
	const client = useApolloClient();
	const { isAuthenticated = false } = data;
	const location = useLocation();
	const { user, loading: userLoading } = useUserMe();

	if (!loading && !isAuthenticated) {
		client.resetStore();
		return <Navigate to={{ pathname: '/login', state: { from: location }}} replace />;
	}

	if (!loading && !userLoading && isAuthenticated) {
		if (passwordExpirationCheck && user?.passwordNeedsChange === true) {
			return <Navigate to={{ pathname: '/password-expired', state: { from: 'password-needs-change' }}} replace />;
		}

		return (
			<div>{ children }</div>
		);
	}

	return null;
};

export default PrivateRoute;
