import { setActiveAzIndexVar } from '../../../../../../BOWL-Common/apollo/extensions/azindex';

const AzIndex = ({ indices = []}) => (
	<div className="flex flex-wrap">
		{indices.map((index) => (
			<button
				key={`az_index_${index}`}
				type="button"
				onClick={() => {
					setActiveAzIndexVar(index);
				}}
				className="flex justify-center w-8 mb-2 mr-2 text-base border border-gray-100 rounded shadow text-bowl-main focus:outline-none font-secondary"
			>
				{index.toUpperCase()}
			</button>
		))}
	</div>
);

export default AzIndex;
