import { useNavigate } from 'react-router-dom';
import useSinglePost from '../../../BOWL-Common/hooks/posts/useSinglePost';
import useCategoryUnreadCount from '../../../BOWL-Common/hooks/categories/useCategoryUnreadCount';
import { setIsSidebarOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import { setOpenCategoriesVar, removeOpenCategoriesVar } from '../../../BOWL-Common/apollo/extensions/categories';
import { useOpenCategories } from '../../../BOWL-Common/hooks/categories/useOpenCategories';
import useOpenPost from '../../../hooks/useOpenPost';
import { ArrowIcon, ExternalLinkIcon } from '../../partials/Icons';

const NavItem = ({ category, level, renderSubCats }) => {
	const showTopLevel = process.env.REACT_APP_NAVIGATION_SHOW_TOPLEVEL === 'true';
	const showNavImages = process.env.REACT_APP_NAVIGATION_SHOW_IMAGES === 'true';
	const showNavColor = process.env.REACT_APP_NAVIGATION_USE_COLORS === 'true' && category.color !== '';

	const navigate = useNavigate();
	const changeRoute = (categoryId) => {
		setIsSidebarOpenVar(false);
		navigate(`/category/${categoryId}`);
	};

	const { data: openCategorieData } = useOpenCategories();
	const isOpenCategory = showTopLevel ? openCategorieData?.openCategories.includes(category.id) : true;
	const isToggleMenu = category.subCategories.length !== 0;
	const { singlePost } = useSinglePost({ category });
	const unreadPostCountByUser = useCategoryUnreadCount({ level, category });

	const openPost = useOpenPost();
	const categoryClicked = () => {
		if (isToggleMenu) {
			if (isOpenCategory) {
				removeOpenCategoriesVar(category.id);
			} else {
				setOpenCategoriesVar(category.id);
			}
		} else if (singlePost) {
			setIsSidebarOpenVar(false);
			openPost(singlePost);
		} else {
			changeRoute(category.id);
		}
	};

	return (
		<dl>
			<div>
				{(showTopLevel || level !== 0) && (
					<dt>
						<button
							type="button"
							onClick={() => categoryClicked()}
							className={`${
								category.active
									? 'text-bowl-activecategorytypo bg-bowl-activecategory focus:bg-bowl-activecategory'
									: 'text-bowl-categorytypo hover-hover:hover:text-bowl-activecategorytypo hover-hover:hover:bg-bowl-activecategory focus:text-bowl-activecategorytypo focus:bg-bowl-activecategory'
							} w-full 
								${level === 0 ? 'mt-2' : ''}
								${showTopLevel && level > 0 ? 'pl-12' : ''}
								flex items-center px-3 py-2 text-left text-base leading-6 transition duration-150 ease-in-out md:text-sm md:leading-5 group focus:outline-none`}
						>
							{showNavImages && category.imageUrl && (
								<div className="flex items-center justify-center flex-shrink-0 w-6 h-6 mr-3 transition duration-150 ease-in-out md:mr-3">
									<img src={`${process.env.REACT_APP_BASE_URL}/storage/${category.imageUrl}`} alt={category.name} />
								</div>
							)}
							<span className={`flex items-center min-h-6 ${level === 0 ? 'flex-1' : ''} ${showNavColor ? 'border-l-4 border-solid pl-1' : ''}`} style={showNavColor ? { borderColor: category.color } : {}}>
								<span className={`font-secondary ${level === 0 ? 'text-xl font-bold' : 'text-15'}`}>
									{category.name}
								</span>
							</span>
							{category.increasesBadgeCount === true && unreadPostCountByUser > 0 && (
								<span className="flex items-center justify-center flex-shrink-0 w-6 h-6 ml-3 text-xs leading-4 text-white transition duration-150 ease-in-out bg-red-600 rounded-full">
									{unreadPostCountByUser}
								</span>
							)}
							{!isToggleMenu && singlePost && (singlePost.type === 'LINK' || singlePost.type === 'PDF') && (
								<span className="ml-auto">
									<ExternalLinkIcon />
								</span>
							)}
							{isToggleMenu && (
								<span className="flex items-center h-6 ml-2">
									<ArrowIcon direction={isOpenCategory ? '-rotate-180' : 'rotate-0'} />
								</span>
							)}

						</button>
					</dt>
				)}
				{isToggleMenu && isOpenCategory && (
					<dd>
						{renderSubCats()}
					</dd>
				)}
				{((!showTopLevel && isToggleMenu)) && <hr className="h-px my-2 border-0 text-bowl-topbar bg-bowl-topbar" />}
			</div>
		</dl>
	);
};

export default NavItem;
