import { gql } from '@apollo/client';

export const FRAGMENT_PAGINATOR_INFO_FIELDS = gql`
	fragment PaginatorInfoFields on PaginatorInfo {
		count
		currentPage
		firstItem
		hasMorePages
		lastItem
		lastPage
		perPage
		total
	}
`;
