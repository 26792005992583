import { useAlerts } from './useAlerts';

const usePinnedAlerts = () => {
	const { alerts, loading, error } = useAlerts();
	let pinnedAlerts = null;
	if (alerts) {
		pinnedAlerts = alerts.filter((alert) => alert.pinned);
	}

	return { alerts: pinnedAlerts, loading, error };
};

export default usePinnedAlerts;
