import { RetryLink } from '@apollo/link-retry';

const setupXsrfRetryLink = () => new RetryLink({
	attempts: {
		max: 2,
		retryIf: (error) => !!error && error.statusCode === 419,
	},
});

export default setupXsrfRetryLink;
