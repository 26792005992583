import { gql } from '@apollo/client';
import { FRAGMENT_USER_FIELDS } from './users';
import { setIsAuthenticatedVar, setAuthTokenVar } from '../apollo/extensions/auth';

export const IS_AUTHENTICATED = gql`
	query isAuthenticated {
		isAuthenticated @client
	}
`;

export const AUTH_TOKEN = gql`
	query authToken {
		authToken @client
	}
`;

export const MUTATE_LOGIN = gql`
	mutation login($email: String!, $password: String!, $rememberMe: Boolean) {
		login(input: { email: $email, password: $password, rememberMe: $rememberMe }) {
			user {
				...UserFields
			}
		}
	}
	${FRAGMENT_USER_FIELDS}
`;

export const mutateLogin = {
	mutation: MUTATE_LOGIN,
	update: () => setIsAuthenticatedVar(true),
	onError: () => null,
};

export const MUTATE_APP_LOGIN = gql`
	mutation appLogin($email: String!, $password: String!, $rememberMe: Boolean) {
		appLogin(input: { email: $email, password: $password, rememberMe: $rememberMe }) {
			token
		}
	}
`;

export const mutateAppLogin = {
	mutation: MUTATE_APP_LOGIN,
	update: (cache, { data: { appLogin }}) => {
		setIsAuthenticatedVar(true);
		setAuthTokenVar(appLogin?.token);
	},
	onError: () => null,
};


export const MUTATE_LOGOUT = gql`
	mutation logout {
		logout {
			status
			message
		}
	}
`;

export const mutateLogout = {
	mutation: MUTATE_LOGOUT,
	update: () => {
		setIsAuthenticatedVar(false);
		setAuthTokenVar(false);
	},
	onError: () => null,
};

export const logout = (client) => {
	client.mutate({
		mutation: MUTATE_LOGOUT,
		onError: () => {},
		update: () => {
			setIsAuthenticatedVar(false);
			setAuthTokenVar(false);

			client.cache.reset();
		},
	});
};

export const MUTATE_FORGOT_PASSWORD = gql`
	mutation forgotPassword($email: String!) {
		forgotPassword(input: { email: $email }) {
			status
			message
		}
	}
`;

export const mutateForgotPassword = {
	mutation: MUTATE_FORGOT_PASSWORD,
	onError: () => null,
};

export const MUTATE_RESET_PASSWORD = gql`
	mutation resetPassword($token: String!, $email: String!, $password: String!, $password_confirmation: String!) {
		resetPassword(input: { token: $token, email: $email, password: $password, password_confirmation: $password_confirmation }) {
			status
			message
		}
	}
`;

export const mutateResetPassword = {
	mutation: MUTATE_RESET_PASSWORD,
	onError: () => null,
};

export const MUTATE_RESET_OLD_PASSWORD = gql`
	mutation resetOldPassword($old_password: String!, $password: String!, $password_confirmation: String!) {
		resetOldPassword(input: { old_password: $old_password, password: $password, password_confirmation: $password_confirmation }) {
			status
			message
			user {
				id
				passwordNeedsChange
			}
		}
	}
`;

export const mutateResetOldPassword = {
	mutation: MUTATE_RESET_OLD_PASSWORD,
	onError: () => null,
};

export const QUERY_CHECK_SESSION = gql`
	query checkSession {
		session
	}
`;

export const queryCheckSession = (client, callback) => {
	client.query({
		query: QUERY_CHECK_SESSION,
		fetchPolicy: 'no-cache',
	}).then(({ data }) => {
		const isSessionValid = data?.session ?? false;
		setIsAuthenticatedVar(isSessionValid);
		callback(isSessionValid);
	}).catch(() => {
		setIsAuthenticatedVar(false);
		callback(false);
	});
};
