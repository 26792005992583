import { memo } from 'react';
import { Link } from 'react-router-dom';

const TextLink = ({ href = '', text = '', title = '', handleClick }) => (
	<>
		{handleClick && (
			<a href={href} onClick={(e) => handleClick(e)} className="text-sm leading-5 text-bowl-main transition duration-150 ease-in-out hover-hover:hover:text-bowl-dark focus:outline-none focus:underline">
				{text}
			</a>
		)}
		{!handleClick && (
			<Link to={href} title={title} className="text-sm leading-5 text-bowl-main transition duration-150 ease-in-out hover-hover:hover:text-bowl-dark focus:outline-none focus:underline">
				{text}
			</Link>
		)}
	</>
);

export default memo(TextLink);
