import React from 'react';

const MappedComponent = (props) => {
	const { typeComponentMap, mapName, type, children } = props;
	const Component = typeComponentMap?.[mapName]?.[type] ?? typeComponentMap?.[mapName]?.default ?? null;
	const listType = type === 'list_featured_item' ? 'featuredItemList' : null;
	return <Component {...props} listType={listType}>{children}</Component>;
};

export default MappedComponent;
