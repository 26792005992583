import { useUserMe } from '../user/useUserMe';

const useCategoryUnreadCount = ({ level, category }) => {
	const { user } = useUserMe();
	const readPosts = user?.readPosts;

	if (category?.increasesBadgeCount && readPosts) {
		if (level === 0) {
			const subCategoriesBadgeCount = category.subCategories.filter((subCategory) => subCategory.increasesBadgeCount).reduce((prevBadgeCount, curCategory) => {
				if (curCategory?.postIds) {
					return prevBadgeCount + curCategory.postIds.filter((postId) => !readPosts.includes(postId)).length;
				}
				return prevBadgeCount + 0;
			}, 0);

			return subCategoriesBadgeCount + category.postIds.filter((postId) => !readPosts.includes(postId)).length;
		}

		if (category?.postIds) {
			return category.postIds.filter((postId) => !readPosts.includes(postId)).length;
		}
	}

	return 0;
};

export default useCategoryUnreadCount;
