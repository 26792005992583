import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useLocation } from 'react-router-dom';
import { useSetReadPost } from '../../../../../BOWL-Common/hooks/user/useSetReadPost';
import { ArrowIcon } from '../../../../partials/Icons';
import PostUnreadBadge from './partials/PostUnreadBadge';
import PostMenu from './partials/PostMenu';

const PostCollapsible = ({ post }) => {
	const { state: locationState } = useLocation();
	const scrollToPostId = locationState?.scrollToPost?.id;
	const [isCollapsed, setIsCollapsed] = useState(post.id !== scrollToPostId);
	const [userHasReadPost] = useSetReadPost();

	const postClicked = () => {
		if (isCollapsed) {
			userHasReadPost({ variables: { postId: post.id }});
		}
		setIsCollapsed(!isCollapsed);
	};

	return (
		<div className="bowl-post-button-wrapper">
			<div className="absolute ml-auto top-1 right-1">
				<PostMenu post={post} showPostId />
			</div>
			<button type="button" onClick={() => postClicked()} className="bowl-post-button-inner">
				<div className="flex flex-row">
					<div className="flex-shrink-0">
						{post.teaserImageUrl && <TeaserImage post={post} />}
					</div>
					<div className="flex flex-row self-center justify-center flex-grow -mt-0.5">
						<PostUnreadBadge post={post} />
						<h3 className="flex-grow bowl-post-headline">
							{post.name}
						</h3>
					</div>
					<span className="flex-shrink-0 mt-1 ml-4 mr-8">
						<ArrowIcon direction={isCollapsed ? 'rotate-0' : '-rotate-180'} color="text-bowl-typo" />
					</span>
				</div>
				<AnimateHeight duration={300} height={isCollapsed ? 0 : 'auto'}>
					<p className="mt-2 bowl-post-teaser-text">
						{post.teaserText}
					</p>
				</AnimateHeight>
			</button>
		</div>
	);
};

const TeaserImage = ({ post }) => (
	<div className="bowl-post-teaser-image-wrapper">
		<img className="object-cover w-8 h-8" src={`${process.env.REACT_APP_BASE_URL}/api/img/${post.teaserImageUrl}?p=small`} alt="Teaser" />
	</div>
);

export default PostCollapsible;
