import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { QUERY_ACTIVE_CATEGORY } from '../../BOWL-Common/graphql/categories';
import { QUERY_POST_BY_ID, QUERY_POST_PREVIEW_BY_ID } from '../../BOWL-Common/graphql/posts';

export const setDocumentTitle = (titleSegments) => {
	const title = titleSegments.join(' - ');
	const mainTitle = process.env.REACT_APP_SITE_TITLE;
	document.title = `${title}${title && ' - '}${mainTitle}`;
};

const SetDocumentTitleCategory = () => {
	const { data: categoryData } = useQuery(QUERY_ACTIVE_CATEGORY);
	if (categoryData?.activeCategory?.name) {
		setDocumentTitle([categoryData?.activeCategory?.name]);
	}
	return null;
};

const useDocumentTitlePostSegments = (customPostQuery = null) => {
	const { id } = useParams();
	const { data: categoryData } = useQuery(QUERY_ACTIVE_CATEGORY);

	const postQuery = customPostQuery || QUERY_POST_BY_ID;
	const { data: postData } = useQuery(postQuery, { variables: { id }});

	let titleSegments = [];
	if (postData?.post?.name) {
		titleSegments = [...titleSegments, postData?.post?.name];
	}

	if (categoryData?.activeCategory?.name) {
		titleSegments = [...titleSegments, categoryData?.activeCategory?.name];
	}

	return titleSegments;
};

const SetDocumentTitlePost = () => {
	const titleSegments = useDocumentTitlePostSegments();

	setDocumentTitle(titleSegments);
	return null;
};

const SetDocumentTitlePostPreview = () => {
	const { t } = useTranslation();
	const titleSegmentsPost = useDocumentTitlePostSegments(QUERY_POST_PREVIEW_BY_ID);
	const titleSegments = [
		`${t('post_preview')} `,
		...titleSegmentsPost,
	];

	setDocumentTitle(titleSegments);
	return null;
};

const SetDocumentTitleStatic = ({ type }) => {
	const { t } = useTranslation();

	switch (type) {
	case 'login':
		setDocumentTitle([t('login_site_title')]);
		break;
	case 'forgot-password':
		setDocumentTitle([t('forgot_password_title')]);
		break;
	case 'reset-password':
		setDocumentTitle([t('reset_password_title')]);
		break;
	case 'password-expired':
		setDocumentTitle([t('change_password_expired_title')]);
		break;
	case '404':
		setDocumentTitle([t('not_found_title')]);
		break;
	case 'imprint':
		setDocumentTitle([t('imprint_title')]);
		break;
	case 'privacy':
		setDocumentTitle([t('privacy_title')]);
		break;
	case 'latest':
		setDocumentTitle([t('latest_title')]);
		break;
	case 'calendar':
		setDocumentTitle([t('calendar_title')]);
		break;
	case 'dashboard':
		setDocumentTitle([t('dashboard')]);
		break;
	case 'notes':
		setDocumentTitle([t('notes_title')]);
		break;
	case 'surveys':
		setDocumentTitle([t('surveys_title')]);
		break;
	default:
		setDocumentTitle([]);
		break;
	}
	return null;
};

// TODO: Document title for survey detail, event detail
const SetDocumentTitle = ({ type }) => {
	if (type === 'category') {
		return <SetDocumentTitleCategory />;
	} if (type === 'post') {
		return <SetDocumentTitlePost />;
	} if (type === 'post-preview') {
		return <SetDocumentTitlePostPreview />;
	}

	return <SetDocumentTitleStatic type={type} />;
};

export default memo(SetDocumentTitle);
