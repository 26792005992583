import { useUserMe } from '../user/useUserMe';

const usePostHasBeenRead = ({ postId }) => {
	const { user } = useUserMe();
	const readPosts = user?.readPosts;

	if (readPosts?.includes(postId)) {
		return true;
	}

	return false;
};

export default usePostHasBeenRead;
