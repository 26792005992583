import { useNavigate } from 'react-router-dom';
import { setIsNoteModalOpenVar } from '../BOWL-Common/apollo/extensions';
import { useTrackDocPostOpen } from '../BOWL-Common/hooks/tracking/useTrackDocPostOpen';
import { useTrackExternalPostOpen } from '../BOWL-Common/hooks/tracking/useTrackExternalPostOpen';
import { useTrackPdfPostOpen } from '../BOWL-Common/hooks/tracking/useTrackPdfPostOpen';
import { useSetReadPost } from '../BOWL-Common/hooks/user/useSetReadPost';

const useOpenPost = (backToLatest = false) => {
	const navigate = useNavigate();
	const trackExternalPostOpen = useTrackExternalPostOpen();
	const trackPdfPostOpen = useTrackPdfPostOpen();
	const trackDocPostOpen = useTrackDocPostOpen();
	const [userHasReadPost] = useSetReadPost();

	const openPost = (post) => {
		userHasReadPost({ variables: { postId: post.id }});
		if (post?.template === 'collapsible') {
			navigate(`/category/${post.category.id}`, { scrollToPost: post });
		} else {
			switch (post.type) {
			case 'LINK':
				if (post.url) {
					trackExternalPostOpen(post);
					window.open(post.url, '_blank');
				}
				break;
			case 'PDF':
				if (post.pdfUrl) {
					trackPdfPostOpen(post);
					window.open(`${process.env.REACT_APP_BASE_URL}/storage/${post.pdfUrl}`, '_blank');
				}
				break;
			case 'DOC':
				if (post.docUrl) {
					trackDocPostOpen(post);
					window.open(`${process.env.REACT_APP_BASE_URL}/api/download/${post.docUrl}`, '_blank');
				}
				break;
			default:
				navigate({ pathname: `/post/${post.id}`, backToLatest });
			}
		}
		setIsNoteModalOpenVar(false);
	};

	return openPost;
};

export default useOpenPost;
