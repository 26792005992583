import { useEffect } from 'react';
import { setActiveCategoryVar } from '../../apollo/extensions';

const useSetActiveCategory = ({ categoryId }) => {
	useEffect(() => {
		if (categoryId) {
			setActiveCategoryVar(categoryId);
		} else {
			setActiveCategoryVar(null);
		}
	}, [categoryId]);
};

export default useSetActiveCategory;
