import { useUserMe } from '../user/useUserMe';

const useFilterUnreadPosts = ({ posts }) => {
	const { user } = useUserMe();
	const readPosts = user?.readPosts;

	if (readPosts && posts) {
		return posts.filter((post) => !readPosts.includes(post.id) && post?.category?.increasesBadgeCount);
	}

	return posts;
};

export default useFilterUnreadPosts;
