import LazyLoad from 'react-lazyload';
import useTopTextLine from '../../../../../BOWL-Common/hooks/posts/useTopTextLine';
import useOpenPost from '../../../../../hooks/useOpenPost';
import ChannelWrapper from './partials/ChannelWrapper';
import PostUnreadBadge from './partials/PostUnreadBadge';
import PostMenu from './partials/PostMenu';

const PostDefault = ({ post }) => {
	const openPost = useOpenPost();
	const { topTextLine } = useTopTextLine({ post });

	return (
		<div className={`bowl-post-button-wrapper ${post.template === 'alert' ? 'bg-bowl-alert ' : ''}`}>
			<div className="absolute ml-auto top-1 right-1">
				<PostMenu post={post} showPostId={!post.channel} />
			</div>
			<button type="button" onClick={() => openPost(post)} className="bowl-post-button-inner">
				<div className="flex flex-col sm:flex-row">
					<div className="flex-shrink-0 order-2 sm:order-1">
						{post.template !== 'press' && post.teaserImageUrl && <TeaserImage post={post} />}
					</div>
					<div className="flex flex-col flex-grow order-1 sm:order-2">
						<div className="flex flex-row items-center mb-1">
							<PostUnreadBadge post={post} />
							{topTextLine && <div className={`mr-8 text-xs leading-4 ${post.template === 'alert' ? 'text-white' : 'text-bowl-date'}`}>{topTextLine}</div>}
						</div>
						<div className={`${!topTextLine ? 'mr-8' : ''}`}>
							<h3 className={`bowl-post-headline ${post.template === 'alert' ? 'text-white' : ''}`}>
								{post.name}
							</h3>
						</div>
						{post.teaserText && (
							<p className={`mt-2 bowl-post-teaser-text ${post.template === 'alert' ? 'text-white' : ''}`}>
								{post.teaserText}
							</p>
						)}
					</div>
				</div>
			</button>
			<div className={`${post.template === 'alert' ? 'bg-white rounded-lg rounded-t-none' : ''}`}>
				{post.channel && <ChannelWrapper post={post} />}
			</div>
		</div>
	);
};

const TeaserImage = ({ post }) => (
	<div className={`bowl-post-teaser-image-wrapper sm:w-60 max-h-40 mt-3 mr-0 sm:mr-4 sm:mt-0 ${post.template === 'alert' ? 'border-bowl-alert' : ''}`}>
		<LazyLoad height="100%" once placeholder={(<div className="lazy-load-wrapper"><img src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt={post.name} /></div>)}>
			<div className="lazy-load-wrapper">
				<img src={`${process.env.REACT_APP_BASE_URL}/api/img/${post.teaserImageUrl}?p=small`} alt={post.name} />
			</div>
		</LazyLoad>
	</div>
);

export default PostDefault;
