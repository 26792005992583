import { useEffect } from 'react';

export default function useOnKeydownEscape(handler) {
	useEffect(() => {
		const handleEsc = (event) => {
			if (event.keyCode === 27) {
				handler();
			}
		};
		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, [handler]);
}
