import { Route, Navigate, useLocation } from 'react-router-dom';
import { useAuthStatus } from '../../BOWL-Common/hooks/auth/useAuthStatus';
import { useTrackLoginSuccess } from '../../BOWL-Common/hooks/tracking/useTrackLoginSuccess';

// A wrapper for <Route> that redirects to the homepage if the user is authenticated
const PublicRedirectRoute = ({ children, ...rest }) => {
	const { data = {}, loading } = useAuthStatus();
	const trackLoginSuccess = useTrackLoginSuccess();

	const { isAuthenticated = false } = data;
	const location = useLocation();

	if (!loading && isAuthenticated) {
		trackLoginSuccess();
		return <Navigate to={{ pathname: '/', state: { from: location, test: 'public' }}} replace />;
	}

	if (!loading && !isAuthenticated) {
		return (
			<div>{ children }</div>
		);
	}

	return null;
};

export default PublicRedirectRoute;
