import { createInstance } from '@jonkoops/matomo-tracker-react';
import { useEffect, useState } from 'react';

const useInitMatomo = (matomoSiteUrl, matomoAppId) => {
	const [matomoInstance, setMatomoInstance] = useState(null);

	useEffect(() => {
		const matomo = createInstance({
			urlBase: matomoSiteUrl,
			siteId: matomoAppId,
			linkTracking: false,
		});

		setMatomoInstance(matomo);
	}, []);

	return matomoInstance;
};

export default useInitMatomo;
