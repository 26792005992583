import { TransformComponent, TransformWrapper } from '@kokarn/react-zoom-pan-pinch';
import { useRef } from 'react';
import { setIsImageZoomModalOpenVar } from '../../../../../BOWL-Common/apollo/extensions';
import { useIsImageZoomModalOpen } from '../../../../../BOWL-Common/hooks/global/useIsImageZoomModalOpen';

import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../../../hooks/useOnKeydownEscape';
import Modal from '../../../../general/Modal';
import { CloseIcon } from '../../../../partials/Icons';

const ImageZoomModalInner = ({ imgPath }) => {
	const ref = useRef();
	useOnClickOutside(ref, () => setIsImageZoomModalOpenVar(false));
	useOnKeydownEscape(() => setIsImageZoomModalOpenVar(false));

	return (
		<div ref={ref} className="inline-block px-4 pt-12 pb-4 my-4 overflow-hidden align-middle transition-all transform rounded-lg shadow-xl sm:mx-4 bg-bowl-light" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<ImageZoomCloseIconButton clickHandler={() => setIsImageZoomModalOpenVar(false)} />
			<TransformWrapper>
				<TransformComponent>
					<img className="object-cover w-full h-full" src={`${process.env.REACT_APP_BASE_URL}/api/img/${imgPath}?p=big`} alt="" />
				</TransformComponent>
			</TransformWrapper>
		</div>
	);
};

const ImageZoomCloseIconButton = ({ clickHandler, isPinned }) => (
	<div className="absolute top-0 right-0 pt-4 pr-4">
		<button type="button" onClick={clickHandler} className={`transition duration-150 ease-in-out ${!isPinned ? 'text-bowl-dark hover-hover:hover:text-bowl-main focus:text-bowl-main' : 'text-white hover-hover:hover:text-orange-100 focus:text-orange-100'} focus:outline-none `} aria-labelledby="close">
			<CloseIcon />
		</button>
	</div>
);

const ImageZoomModal = () => {
	const { isImageZoomModalOpen, imgPath } = useIsImageZoomModalOpen();

	return (
		<Modal show={isImageZoomModalOpen}>
			{/* This element is to trick the browser into centering the modal contents. */}
			<span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
			{ imgPath && <ImageZoomModalInner imgPath={imgPath} /> }
		</Modal>
	);
};

export default ImageZoomModal;
