import { useEffect, useState } from 'react';

const useParseContentRows = ({ post }) => {
	const [contentRows, setContentRows] = useState([]);
	const [parsingError, setParsingError] = useState(null);

	useEffect(() => {
		let rows = [];
		try {
			rows = JSON.parse(post.content);
		} catch (err) {
			setParsingError(err.toString());
		}
		setContentRows(rows);
	}, [post]);

	return { contentRows, parsingError };
};

export default useParseContentRows;
