import { useCallback } from 'react';

export const useIndices = (category, postsOfCategory) => {
	const idxs = useCallback(() => {
		let indices = [];
		const hasAzIndex = category.showAzList;
		if (hasAzIndex === true && postsOfCategory) {
			const letters = postsOfCategory.reduce((prevChars, current) => {
				const currentFirstChar = current.name[0];
				if (!prevChars.includes(currentFirstChar)) {
					return [...prevChars, currentFirstChar];
				}

				return prevChars;
			}, []);
			indices = letters;
		}

		return indices;
	}, [category, postsOfCategory]);

	return idxs();
};

export default useIndices;
