import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { QUERY_APP_META } from '../../../BOWL-Common/graphql/appmeta';
import TextLink from './partials/TextLink';
import { H1 } from './partials/Headlines';
import LoadingScreen from '../../general/LoadingScreen';

const AppMeta = ({ isPrivate = false, type }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { data, loading } = useQuery(QUERY_APP_META);

	let title = '';
	let content = '';

	switch (type) {
	case 'imprint':
		title = 'imprint_title';
		content = data?.appMeta?.imprint;
		break;
	case 'privacy':
		title = 'privacy_title';
		content = data?.appMeta?.privacy;
		break;
	default:
		break;
	}

	if (!loading && !content) {
		if (isPrivate) {
			return <Navigate to={{ pathname: '/login', state: { from: location }}} replace />;
		}
		return <Navigate to={{ pathname: '/', state: { from: location, test: 'public' }}} replace />;
	}

	if (!loading && content) {
		return (
			<div className="flex flex-col justify-start min-h-screen py-6 sm:px-6 lg:px-8">
				<div className="mx-4 sm:mx-auto sm:w-full">
					<div className="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
						{!isPrivate	&&	(
							<div className="mb-4">
								<TextLink href="/login" text={t('back_to_login')} title={t('back_to_login')} />
							</div>
						)}
						<H1 title={t(title)} />
						<article className="w-full prose-sm prose sm:prose">
							<div dangerouslySetInnerHTML={{ __html: content }} className="text-bowl-typo" />
						</article>
					</div>
				</div>
			</div>
		);
	}

	return <LoadingScreen />;
};

export default AppMeta;
