import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowIcon } from '../../../../partials/Icons';

const WidgetBox = ({
	children, title, icon: Icon, moreText, moreLink, actionButton: ActionButton, backgroundColor = 'bg-bowl-background',
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={`${backgroundColor} rounded-lg px-4 pt-4 shadow-lg flex flex-col`}>
			<div className="flex justify-between items-center mb-2">
				{title && (
					<div className="text-white font-secondary font-bold text-2xl">
						{t(title)}
					</div>
				)}
				{Icon && (
					<button type="button" className="cursor-pointer" onClick={() => navigate(moreLink)}>
						<Icon />
					</button>
				)}
			</div>
			<div className="flex-grow">
				{children}
			</div>
			{(ActionButton || moreLink) && (
				<div className="flex justify-between items-end pb-4">
					<div className="h-8">
						{ActionButton && (
							<ActionButton />
						)}
					</div>
					<div className="">
						{moreLink && moreText && (
							<button type="button" className="cursor-pointer" onClick={() => navigate(moreLink)}>
								<div className="flex justify-center items-center">
									<div className="text-white text-sm pr-1">
										{t(moreText)}
									</div>

									<ArrowIcon color="text-white" />
								</div>
							</button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default WidgetBox;
