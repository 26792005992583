import { useQuery } from '@apollo/client';
import { QUERY_NOTE_BY_ID } from '../../graphql/notes';

export const useNoteWithId = ({ noteId }) => {
	const { data, loading, error } = useQuery(QUERY_NOTE_BY_ID, {
		variables: { id: noteId },
		skip: noteId === null,
	});

	const note = data?.note ?? null;
	return { note, loading, error };
};
