import { useAllCategoriesForNavigation } from '../../../BOWL-Common/hooks/categories/useAllCategoriesForNavigation';
import { useAllSurveys } from '../../../BOWL-Common/hooks/surveys/useAllSurveys';
import LoadingScreen from '../../general/LoadingScreen';
import { CalendarIcon, DashboardIcon, LatestIcon, NotesIcon, SurveyIcon } from '../../partials/Icons';
import NavItem from './NavItem';
import NavItemStatic from './NavItemStatic';

const renderCategories = (
	categories,
	categoriesById,
	level = 0,
) => categories.map((category) => {
	const renderSubCats = () => {
		if (category.subCategories) {
			const subCategories = category.subCategories.map(
				(subCategory) => categoriesById[subCategory.id],
			);
			const newLevel = level + 1;
			return renderCategories(
				subCategories,
				categoriesById,
				newLevel,
			);
		}
		return null;
	};

	return <NavItem key={`nav_item_cat_${category.id}`} category={category} level={level} renderSubCats={renderSubCats} />;
});

const Navigation = () => {
	const { data, loading } = useAllCategoriesForNavigation();
	const { surveys, loading: loadingSurveys } = useAllSurveys();

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<>
			<NavItemStatic title="overview" navigateTo="/overview" icon={<DashboardIcon color="text-bowl-categorytypo" />} />
			<NavItemStatic title="latest_title" navigateTo="/latest" icon={<LatestIcon color="text-bowl-categorytypo" />} />
			{renderCategories(
				data?.topLevelCategories,
				data?.categoriesById,
				0,
			)}
			<NavItemStatic title="calendar_title" navigateTo="/calendar" icon={<CalendarIcon color="text-bowl-categorytypo" />} />
			<NavItemStatic title="notes_title" navigateTo="/notes" icon={<NotesIcon color="text-bowl-categorytypo" />} />
			{!loadingSurveys && surveys?.length > 0 && (
				<NavItemStatic title="surveys_title" navigateTo="/surveys" icon={<SurveyIcon color="text-bowl-categorytypo" />} />
			)}
		</>
	);
};

export default Navigation;
