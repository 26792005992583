import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDatetime } from '../../../../BOWL-Common/utils/dates';
import { ArrowIcon } from '../../../partials/Icons';

const SurveyListItem = ({ survey }) => {
	const navigate = useNavigate();

	const { id, startDate, title, description } = survey;

	const openSurvey = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();
		navigate(`/surveys/${id}`);
	}, [navigate, id]);

	return (
		<a href={`surveys/${id}`} className="block pb-2 mb-2 border-b border-gray-100 last:border-none border-b-gray-100 last:mb-0" onClick={(e) => openSurvey(e)}>
			<div className="text-xs text-bowl-surveys-list-date">
				{startDate ? formatDatetime(startDate) : ''}
			</div>
			<div className="flex items-center w-full mb-1">
				<div className="flex-grow">
					<h3 className="flex-grow overflow-hidden text-lg font-bold font-secondary text-bowl-main whitespace-nowrap text-ellipsis">
						{title ?? ''}
					</h3>
					<div className="">
						{description ?? ''}
					</div>
				</div>
				<div className="">
					<ArrowIcon />
				</div>
			</div>
		</a>
	);
};

export default SurveyListItem;
