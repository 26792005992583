import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import { pushToNotificationStackVar } from '../../../BOWL-Common/apollo/extensions/notifications';
import { useLoginUserMutation } from '../../../BOWL-Common/hooks/auth/useLoginUserMutation';

import SubmitButton from './partials/SubmitButton';
import InputField from './partials/InputField';
import ErrorMessage from './partials/ErrorMessage';
import TextLink from './partials/TextLink';
import { H3, H2 } from './partials/Headlines';

const Login = () => {
	const { t } = useTranslation();
	useTrackPageViewEffect(t('login_site_title'));

	const [login, { loading, error }] = useLoginUserMutation();

	const [email, setEmail] = useState(process.env.NODE_ENV === 'development' ? process.env.REACT_APP_USERNAME : '');
	const [password, setPassword] = useState(process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PASSWORD : '');
	// const [rememberMe, setRememberMe] = useState(false);
	const [rememberMe] = useState(false);

	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		const lParam = new URLSearchParams(window.location.search.substring(1)).get('l');
		if (lParam) {
			let messageKey = '';

			switch (lParam) {
			case '2':
				messageKey = 'session_expired';
				break;
			case '3':
				messageKey = 'unauthenticated';
				break;
			case '1':
			default:
				messageKey = 'logged_out';
				break;
			}

			pushToNotificationStackVar(messageKey);
		}
	}, []);

	useEffect(() => {
		if (error) {
			const { graphQLErrors } = error;
			if (graphQLErrors?.[0]?.extensions?.category === 'validation') {
				const validationErrors = graphQLErrors[0].extensions.validation;
				setErrorMessage(Object.values(Object.values(validationErrors).map((validationError) => validationError.join('<br />'))).join('<br />'));
			} else if (graphQLErrors?.[0]?.extensions?.category === 'authentication') {
				setErrorMessage(t('invalid_credentials'));
			} else {
				setErrorMessage(error.message);
			}
		} else {
			setErrorMessage(null);
		}
	}, [error, t]);

	const handleLogin = () => {
		setErrorMessage(null);
		login({ variables: { email, password, rememberMe }});
	};

	// const handleRememberMe = (e) => {
	// 	setRememberMe(e.target.checked);
	// };

	return (
		<div className="flex flex-col justify-center min-h-screen py-12 sm:pb-48 sm:px-6 lg:px-8">
			<div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
				<img className="w-auto h-12 mx-auto" src={`${process.env.REACT_APP_BASE_URL}/logo`} alt="Logo" />
				<H2 title={t('login_title')} className="mt-8 text-bowl-typo-secondary" />
				<H3 title={t('login_subtitle')} className="mt-2 text-bowl-typo-secondary" />
			</div>

			<div className="mx-4 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
					<form action="#" method="POST" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
						<InputField fieldName="email" label={t('login_email')} type="email" value={email} placeholder={t('login_email_placeholder')} handleChange={setEmail} />

						<div className="mt-6">
							<InputField fieldName="password" label={t('login_password')} type="password" value={password} placeholder={t('login_password_placeholder')} handleChange={setPassword} />
							<ErrorMessage message={errorMessage} />
						</div>

						<div className="flex items-center justify-between mx-1 mt-6">
							<div className="flex items-center">
								<TextLink href="/imprint" text={t('imprint_title')} title={t('imprint_title')} />
								{/* <input
									id="remember_me"
									type="checkbox"
									checked={rememberMe}
									onChange={(e) => handleRememberMe(e)}
									className="w-4 h-4 transition duration-150 ease-in-out text-bowl-main form-checkbox"
								/>
								<label htmlFor="remember_me" className="block ml-2 text-sm leading-5 text-bowl-typo">
									{t('login_remember')}
								</label> */}
							</div>

							<TextLink href="/forgot-password" text={t('login_forgot')} title={t('login_forgot')} />
						</div>

						<div className="mt-6">
							<SubmitButton type="submit" title={t('login_sign_in')} loading={loading} disabled={email === '' || password === ''} handleClick={handleLogin} />
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
