import { useTranslation } from 'react-i18next';
import { DocumentIcon, DownloadIcon, ExternalLinkIcon, NewspaperIcon, VideoIcon } from '../../../../../partials/Icons';
import PostId from '../../partials/PostId';

// TODO: Logik muss überarbeitet werden
const ChannelWrapper = ({ post, small, showCat = false }) => {
	const showTopLevel = process.env.REACT_APP_NAVIGATION_SHOW_TOPLEVEL;
	return (
		<div className={`flex flex-row ${small ? 'px-2 pb-0.5' : 'px-3 pb-1'} border-t border-gray-100`}>
			<div className="flex-grow">
				{small && showTopLevel && <Category post={post} />}
				{!(small && showTopLevel) && <Channel post={post} small={small} showCat={showCat} />}
			</div>
			<div className="flex-shrink-0" />
		</div>
	);
};

const Category = ({ post }) => {
	const categoryName = post?.category?.parentCategory?.name ?? post?.category?.name ?? null;
	const categoryImageUrl = post?.category?.parentCategory?.imageUrl ?? post?.category?.imageUrl ?? null;

	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center">
				<div className="flex-shrink-0 mb-auto">
					<img className="w-5 h-5" src={`${process.env.REACT_APP_BASE_URL}/storage/${categoryImageUrl}`} alt={categoryName} />
				</div>
				<div className="ml-1">
					<p className="mt-px text-xs leading-4 text-left text-bowl-typo">
						{categoryName}
					</p>
				</div>
			</div>
			<span className="mb-auto ml-2 text-xs leading-5 font-primary text-bowl-typo">
				{post.postId}
			</span>
		</div>
	);
};

const Channel = ({ post, small, showCat }) => {
	let name = '';
	let image = '';
	if (showCat) {
		name = post?.category?.parentCategory?.name ?? post?.category?.name ?? null;
		image = post?.category?.parentCategory?.imageUrl ?? post?.category?.imageUrl ?? null;
	} else if (post.channel) {
		name = post.channel.name;
		image = post.channel.imageUrl;
	}

	return (
		<div className="flex flex-wrap items-center">
			<div className={`flex flex-row items-center mr-6 ${small ? 'sm:mr-4 pt-0.5' : 'pt-1'}`}>
				<div className="flex-shrink-0">
					<img className={`${small ? 'w-5 h-5' : 'w-6 h-6'} ${showCat ? '' : 'rounded-full'}`} src={`${process.env.REACT_APP_BASE_URL}/storage/${image}`} alt={name} />
				</div>
				<div className="ml-1">
					<p className="mt-px text-xs leading-4 text-bowl-typo">
						{name}
					</p>
				</div>
			</div>
			<div className="flex">
				<PostType post={post} small={small} />
				<PostId postId={post.postId} />
			</div>
			<PostFilter post={post} small={small} />
		</div>
	);
};

const PostType = ({ post, small }) => {
	const { t } = useTranslation();

	return (
		<div className={`flex flex-row text-xs leading-4 text-bowl-typo ${small ? 'sm:ml-auto pt-0.5' : 'pt-1'}`}>
			<div className="flex flex-row items-center leading-4">
				{post.type === 'LINK' && (
					<>
						<ExternalLinkIcon />
						<div className="ml-1">{t('post_type_external_link')}</div>
					</>
				)}
				{post.type === 'PDF' && (
					<>
						<DocumentIcon />
						<div className="ml-1">{t('post_type_pdf')}</div>
					</>
				)}
				{post.type === 'DOC' && (
					<>
						<DownloadIcon />
						<div className="ml-1">{t('post_type_download')}</div>
					</>
				)}
				{post.type === 'CONTENT' && (
					<>
						<NewspaperIcon />
						<div className="ml-1">{t('post_type_article')}</div>
					</>
				)}
				{post.isVideo && (
					<>
						<span className="mx-1">&middot;</span>
						<VideoIcon />
						<div className="ml-1">{t('post_type_video')}</div>
					</>
				)}
			</div>
		</div>
	);
};

const PostFilter = ({ post, small }) => {
	if (post?.filters && post?.filters.length > 0) {
		return (
			<div className={`flex justify-end flex-grow ${small ? 'pt-0.5' : 'pt-1'} ml-6`}>
				{post?.filters.map((filter) => {
					if (filter?.title) {
						return (
							<div key={`filter_${filter?.id}`} className={`mr-3 leading-none uppercase whitespace-nowrap border-2 border-l-0 border-r-0 text-bowl-typo-secondary font-secondary ${small ? 'text-sm' : 'text-base'} border-bowl-typo last:mr-0`}>
								{filter?.title}
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	}

	return null;
};

export default ChannelWrapper;
