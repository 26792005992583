import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { setIsAlertModalOpenVar } from '../../../BOWL-Common/apollo/extensions';
import { CalendarIcon, SpeakerPhoneIcon } from '../../partials/Icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { formatDatetime } from '../../../BOWL-Common/utils/dates';

const AlertBanner = ({ alerts, events }) => {
	const navigate = useNavigate();

	const openAlert = (alertId) => {
		setIsAlertModalOpenVar(true, alertId);
	};

	const openEvent = useCallback((eventId) => {
		navigate(`/calendar/${eventId}`);
	}, [navigate]);

	return (
		<div className="block fixed left-0 right-0 z-10 w-full mx-auto group bg-bowl-alert top-16 max-w-7xl md:pl-72 hover-hover:hover:bg-bowl-alert-secondary focus:bg-bowl-alert-secondary focus:outline-none print:hidden">
			<div className="mx-auto max-w-7xl">
				<div className="flex flex-wrap items-center justify-between">
					<div className="w-full flex">
						{(alerts?.length > 0 || events?.length > 0) && (
							<Carousel axis="vertical" autoFocus={false} autoPlay={alerts?.length > 0 || events?.length > 0} interval={2500} dynamicHeight centerMode infiniteLoop showArrows={false} showThumbs={false} showStatus={false} showIndicators={false} swipeable>
								{alerts.map((alert) => (
									<div key={`alert${alert.id}`} className="flex items-center w-full px-3 py-3 sm:px-6 lg:px-8">
										<div className="flex p-2 bg-white rounded-lg text-bowl-alert">
											<SpeakerPhoneIcon />
										</div>
										<div className="flex pl-3 flex-1 flex-grow overflow-hidden">
											<div className="overflow-hidden h-10 flex items-center">
												<button type="button" onClick={() => openAlert(alert?.id)} className="w-full text-base text-left text-white leading-tight font-secondary focus:outline-none">
													{ alert?.title }
												</button>
											</div>
										</div>
									</div>
								))}
								{events?.map((event) => (
									<div key={`event${event.id}`} className="flex items-center w-full px-3 py-3 sm:px-6 lg:px-8">
										<div className="flex p-2 bg-white rounded-lg text-bowl-alert">
											<CalendarIcon />
										</div>
										<div className="flex pl-3 flex-1 flex-grow overflow-hidden">
											<div className="overflow-hidden h-10 flex items-center">
												<button type="button" onClick={() => openEvent(event?.id)} className="w-full text-base text-left text-white leading-tight font-secondary focus:outline-none">
													<div>
														{`${formatDatetime(event?.start)}${event?.endCorrect ? ` - ${formatDatetime(event.endCorrect)}` : ''} `}
													</div>
													<div>
														{ `${event?.title}`}
													</div>
												</button>
											</div>
										</div>
									</div>
								))}
							</Carousel>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AlertBanner;
