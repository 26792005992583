import { useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';

import useOpenLatestAlert from '../../../BOWL-Common/hooks/alerts/useOpenLatestAlert';
import { setIsAlertModalOpenVar } from '../../../BOWL-Common/apollo/extensions';

import usePinnedAlerts from '../../../BOWL-Common/hooks/alerts/usePinnedAlerts';
import TopBar from '../navigation/TopBar';
import Sidebar from '../navigation/Sidebar';
import AlertModal from '../alerts/AlertModal';
import AlertWindow from '../alerts/AlertWindow';
import AlertBanner from '../alerts/AlertBanner';
import ChangePasswordModal from '../screens/ChangePasswordModal';
import NoteModal from '../screens/notes/NoteModal';
import SurveyDisruptor from '../screens/surveys/SurveyDisruptor';
import { usePinnedEvents } from '../../../BOWL-Common/hooks/calendar/usePinnedEvents';
import ImageZoomModal from '../screens/posts/detail/ImageZoomModal';

const ScrollToTopEffect = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const PrivateLayout = ({ children }) => {
	const { alerts: pinnedAlerts, loading: loadingAlerts } = usePinnedAlerts();
	const { events: pinnedEvents, loading: loadingEvents } = usePinnedEvents();

	useOpenLatestAlert((alertId) => setIsAlertModalOpenVar(true, alertId));

	return (
		<>
			<ScrollToTopEffect />
			<div className="relative bg-gray-200 top-16 print:top-0 print:bg-white">
				<SurveyDisruptor />
				<div className="relative min-h-screen print:min-h-0">
					<TopBar />
					<AlertWindow />
					<div className="relative mx-auto shadow-lg max-w-7xl bg-bowl-background print:bg-white print:shadow-none print:max-w-none">
						<div className="flex h-full">
							<Sidebar />
							<div className="flex flex-col flex-1 w-0 py-4 overflow-hidden md:pl-72 print:p-0">
								{!loadingEvents && !loadingAlerts && (pinnedAlerts?.length > 0 || pinnedEvents?.length > 0) && (<AlertBanner alerts={pinnedAlerts} events={pinnedEvents} />)}
								<main className={`relative z-0 flex-1 min-h-screen ${(pinnedAlerts?.length > 0 || pinnedEvents?.length > 0) ? 'pt-16' : ''} overflow-y-auto focus:outline-none print:p-0 print:min-h-0`}>
									<div className="h-full max-w-5xl mx-auto sm:px-4 print:max-w-none print:p-0">
										{children}
									</div>
								</main>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ChangePasswordModal />
			<AlertModal />
			<NoteModal />
			<ImageZoomModal />
		</>
	);
};
export default memo(PrivateLayout);
