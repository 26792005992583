import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import MappedComponent from '../../../BOWL-Common/components/MappedComponent';
import useFilterUnreadPosts from '../../../BOWL-Common/hooks/posts/useFilterUnreadPosts';
import { CategoryContentLoader } from '../../partials/ContentLoader';
// eslint-disable-next-line import/no-cycle
import mapping from '../../../config/mapping';
import { useNewestPosts } from '../../../BOWL-Common/hooks/posts/useNewestPosts';

const Latest = () => {
	const { t } = useTranslation();
	const [isFilterActive, setIsFilterActive] = useState(true);

	const { posts: newestPosts, loading, error } = useNewestPosts({ count: 30 });
	const unreadPosts = useFilterUnreadPosts({ posts: newestPosts });

	const posts = isFilterActive ? unreadPosts : newestPosts;

	useEffect(() => {
		setIsFilterActive(true);
	}, []);

	useTrackPageViewEffect(t('latest_posts'));

	return (
		<div className="px-2 sm:px-4">
			<div className="flex items-center justify-start pb-4">
				<button
					type="button"
					role="tab"
					aria-selected={isFilterActive}
					onClick={() => setIsFilterActive(true)}
					className={`sm:w-48 px-1 text-center border-b-2 border-solid focus:outline-none ${isFilterActive ? ' border-bowl-main text-bowl-typo-secondary' : 'border-transparent text-bowl-grayscale-2 hover-hover:hover:text-bowl-grayscale-4 hover-hover:hover:border-bowl-grayscale-1'}`}
					aria-labelledby={t('unread_posts')}
					aria-controls={t('unread_posts')}
					title={t('unread_posts')}
				>
					<h2 className="py-2 font-bold font-secondary text-33">
						{t('unread_posts')}
					</h2>
				</button>
				<button
					type="button"
					role="tab"
					aria-selected={!isFilterActive}
					onClick={() => setIsFilterActive(false)}
					className={`ml-4 px-6 sm:ml-6 sm:w-48 sm:px-1 text-center border-b-2 border-solid focus:outline-none ${!isFilterActive ? ' border-bowl-main text-bowl-typo-secondary' : 'border-transparent text-bowl-grayscale-2 hover-hover:hover:text-bowl-grayscale-4 hover-hover:hover:border-bowl-grayscale-1'}`}
					aria-labelledby={t('all_posts')}
					aria-controls={t('all_posts')}
					title={t('all_posts')}
				>
					<h2 className="py-2 font-bold font-secondary text-33">
						{t('all_posts')}
					</h2>
				</button>
			</div>
			{loading && (
				<>
					<CategoryContentLoader />
					<CategoryContentLoader />
					<CategoryContentLoader />
					<CategoryContentLoader />
					<CategoryContentLoader />
				</>
			)}
			{!loading && !error && posts && posts.length > 0 && (
				<div className="focus:outline-none">
					<div className="">
						{posts.map((post) => (
							<div key={`post_${post.id}`}>
								<MappedComponent typeComponentMap={mapping} post={post} mapName="postItem" type="latest" />
							</div>
						))}
					</div>
				</div>
			)}
			{!loading && !error && posts && posts.length === 0 && isFilterActive && <CategoryMessage title={t('category_no_unread_posts')} />}
			{!loading && !error && posts && posts.length === 0 && !isFilterActive && <CategoryMessage title={t('category_no_posts')} />}
		</div>
	);
};

const CategoryMessage = ({ title }) => (
	<div className="my-8">
		<p className="text-base bowl-post-teaser-text">{title}</p>
	</div>
);
export default Latest;
