import { NavLink } from 'react-router-dom';
import { setIsUserMenuOpenVar } from '../../../BOWL-Common/apollo/extensions';

export const ButtonTopBar = ({ route, title, type }) => {
	const classNames = 'block w-full px-3 py-2 mb-3 md:mb-0 md:mr-4 text-base md:text-sm leading-5 text-left transition duration-150 ease-in-out rounded-md hover-hover:hover:text-bowl-topbar hover-hover:hover:bg-bowl-topbar-secondary focus:outline-none focus:text-bowl-topbar focus:bg-bowl-topbar-secondary';

	if (type === 'pdf') {
		return (
			<div className="flex text-bowl-topbar-secondary">
				<button
					type="button"
					onClick={() => { setIsUserMenuOpenVar(false); window.open(`${process.env.REACT_APP_BASE_URL}/storage/${route}`, '_blank'); }}
					className={classNames}
					aria-labelledby={`open-${title}`}
				>
					{title}
				</button>
			</div>
		);
	}

	return (
		<div className="flex text-bowl-topbar-secondary">
			<NavLink
				onClick={() => setIsUserMenuOpenVar(false)}
				to={route}
				className={({ isActive }) => (isActive ? `text-bowl-topbar bg-bowl-topbar-secondary ${classNames}` : classNames)}
			>
				{title}
			</NavLink>
		</div>
	);
};
