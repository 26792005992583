import { useState, useEffect } from 'react';
import { formatDate } from '../../utils/dates';

const useTopTextLine = ({ post }) => {
	const [topTextLine, setTopTextLine] = useState('');

	useEffect(() => {
		if (post?.displayDate === 'PUBLISHED_DATE') {
			setTopTextLine(formatDate(post?.publishedAt));
		} else if (post?.displayDate === 'RELEASED_DATE') {
			setTopTextLine(formatDate(post?.releasedAt));
		} else if (post?.displayDate === 'TEASER') {
			setTopTextLine(post?.teaserText);
		} else {
			setTopTextLine('');
		}
	}, [post]);

	return { topTextLine };
};

export default useTopTextLine;
