export default {
	test: 'de',

	// general
	loading: 'Lädt...',
	error: 'Es ist ein Fehler aufgetreten.',
	go_home: 'Zurück zur Startseite',
	back: 'Zurück',

	not_found_title: '404 Seite nicht gefunden',
	imprint_title: 'Impressum',
	privacy_title: 'Datenschutz',
	instructions_title: 'Anleitung',
	latest_title: 'Aktuelles',
	calendar_title: 'Kalender',

	// errors
	invalid_token: 'Your token expired or is invalid.',
	invalid_credentials: 'Ihre Anmeldedaten sind leider nicht korrekt.',
	error_network: 'An network error occured. Most likley you have no internet connection.',
	error_graphql: 'There was an unexpected application error. Please try again or contact an admin.',
	error_page_not_found: 'Oops... Diese Seite wurde nicht gefunden.',

	// login
	login_site_title: 'Jetzt Anmelden',
	login_title: 'Willkommen zurück!',
	login_subtitle: 'Melden Sie sich jetzt an.',
	login_email: 'E-Mail',
	login_email_placeholder: 'Tragen Sie hier Ihre E-Mail Adresse ein.',
	login_password: 'Passwort',
	login_password_placeholder: 'Tragen Sie hier Ihr Passwort ein.',
	login_remember: 'An mich erinnern',
	login_forgot: 'Passwort vergessen?',
	login_sign_in: 'Anmelden',

	// forgot password
	forgot_password_title: 'Passwort vergessen?',
	forgot_password_subtitle: 'Bitte tragen Sie Ihre E-Mail Adresse ein, damit wir Ihnen einen Link zum Zurücksetzen Ihres Passworts senden können.',
	forgot_password_email: 'E-Mail',
	forgot_password_email_placeholder: 'Tragen Sie hier Ihre E-Mail Adresse ein.',
	back_to_login: 'Zurück zum Login',
	forgot_password_button: 'Passwort zurücksetzen',
	forgot_password_success: 'Wir haben Ihnen einen Link zum Zurücksetzen Ihres Passworts per E-Mail gesendet.',

	// reset password
	reset_password_title: 'Neues Passwort wählen',
	reset_password_subtitle: 'Bitte wählen Sie Ihr neues Passwort.',
	reset_password_email: 'E-Mail',
	reset_password_email_placeholder: 'Tragen Sie hier Ihre E-Mail Adresse ein.',
	reset_password_password: 'Neues Passwort',
	reset_password_password_placeholder: 'Wählen Sie ein neues Passwort.',
	reset_password_password_confirmation: 'Neues Passwort bestätigen',
	reset_password_password_confirmation_placeholder: 'Wiederholen Sie Ihr neues Passwort.',
	reset_password_button: 'Neues Passwort setzen',
	reset_password_success: 'Ihr Passwort wurde erfolgreich geändert.',

	// change password
	change_password_continue: 'Weiter zur App',
	change_password: 'Passwort ändern',
	change_password_title: 'Passwort ändern',
	change_password_expired_title: 'Passwort abgelaufen',
	change_password_subtitle: 'Wählen Sie Ihr neues Passwort.',
	change_password_expired_subtitle: 'Ihr altes Passwort ist abgelaufen.',
	change_password_email: 'E-Mail',
	change_password_email_placeholder: 'Tragen Sie hier Ihre E-Mail Adresse ein.',
	change_password_old_password: 'Altes Passwort',
	change_password_old_password_placeholder: 'Tragen Sie hier Ihr altes Passwort ein.',
	change_password_password: 'Neues Passwort',
	change_password_password_placeholder: 'Tragen Sie hier Ihr neues Passwort ein.',
	change_password_password_confirmation: 'Neues Passwort bestätigen',
	change_password_password_confirmation_placeholder: 'Wiederholen Sie Ihr neues Passwort.',
	change_password_button: 'Neues Passwort setzen',
	change_password_success: 'Ihr Passwort wurde erfolgreich geändert.',
	change_password_error_same: 'Ihr neues Passwort darf nicht mit dem alten übereinstimmen.',

	// top bar
	top_bar_search: 'Suche',
	top_bar_sign_out: 'Abmelden',

	// app update
	app_update_title: 'Update verfügbar',
	app_update_message: 'Es ist eine neue Version verfügbar.',
	app_update_confirm: 'Jetzt updaten',
	app_update_skip: 'Später',

	// alert
	alert_close: 'Schließen',
	alert_read_message: 'Mehr',
	alert_window_title: 'Benachrichtigungen',
	alert_no_notifications: 'Sie haben bisher keine Benachrichtigungen',
	alert_new: 'NEU',

	// posts
	related_posts: 'Verlinkte Anhänge',
	shared: 'geteilt aus der',
	mark_as_unread: 'Als ungelesen markieren',
	mark_as_read: 'Als gelesen markieren',
	copy_to_clipboard: 'In die Zwischenablage kopieren',
	post_preview: 'Artikelvorschau',

	// search
	search_for_articles: 'Suche nach Artikeln',
	search_no_result: 'Kein Ergebnis',

	// post type
	post_type_external_link: 'Externer Link',
	post_type_pdf: 'PDF',
	post_type_download: 'Download',
	post_type_article: 'Artikel',
	post_type_video: 'Video',

	// filter
	mark_all_as_read: 'Alle als gelesen markieren',
	filter_by_unread: 'Filtern nach ungelesen',
	show_all: 'Zeige alle',

	// category
	category_no_unread_posts: 'Sie haben keine ungelesenen Beiträge in dieser Kategorie.',
	category_no_posts: 'Diese Kategorie hat keine Beiträge.',

	// latest
	latest_posts: 'Aktuelles',
	unread_posts: 'Ungelesene',
	all_posts: 'Alle',

	open_post: 'Jetzt öffnen',

	// calendar
	sub_calendars: 'Alle Unterkalender',
	event_close: 'Schließen',
	period: 'Zeitraum: ',
	all_day: 'Ganztägig',
	end: 'Ende',
	no_events: 'Keine Ereignisse',
	file: 'Datei',
	january: 'Januar',
	february: 'Februar',
	march: 'März',
	april: 'April',
	may: 'Mai',
	june: 'Juni',
	july: 'Juli',
	august: 'August',
	september: 'September',
	october: 'Oktober',
	november: 'November',
	december: 'Dezember',
	januaryShort: 'Jan.',
	februaryShort: 'Feb.',
	marchShort: 'März',
	aprilShort: 'Apr.',
	mayShort: 'Mai',
	juneShort: 'Juni',
	julyShort: 'Juli',
	augustShort: 'Aug.',
	septemberShort: 'Sept.',
	octoberShort: 'Okt.',
	novemberShort: 'Nov.',
	decemberShort: 'Dez.',
	sunday: 'Sonntag',
	monday: 'Montag',
	tuesday: 'Dienstag',
	wednesday: 'Mittwoch',
	thursday: 'Donnerstag',
	friday: 'Freitag',
	saturday: 'Samstag',
	sundayShort: 'S',
	mondayShort: 'M',
	tuesdayShort: 'D',
	wednesdayShort: 'M',
	thursdayShort: 'D',
	fridayShort: 'F',
	saturdayShort: 'S',
	today: 'Heute',
	'weekly overview': 'Wochenübersicht',
	upcoming: 'Bevorstehend',
	this_week: 'Diese Woche',
	next_week: 'Nächste Woche',

	// access
	// access_title: 'Freigaben benötigt',
	// access_message: 'BK Manager benötigt Zugriff auf Ihrem Kalender und auf Ihre Kontakte, um diese Funktionalität nutzen zu können. Geben Sie hierfür Ihren Kalender und Ihre Kontakte in den Einstellungen frei.',
	access_calendar_title: 'Kalenderfreigabe benötigt',
	access_calendar_message: 'BK Manager benötigt Zugang zu Ihrem Kalender, um diese Funktionalität nutzen zu können. Geben Sie hierfür Ihren Kalender in den Einstellungen frei.',
	// access_contacts_title: 'Kontaktfreigabe benötigt',
	// access_contacts_message: 'BK Manager benötigt Zugang zu Ihre Kontakte, um diese Funktionalität nutzen zu können. Geben Sie hierfür Ihre Kontakte in den Einstellungen frei.',
	access_go_to_settings: 'Zu den Einstellungen',
	access_close: 'Schließen',

	// pdf printing
	pdf_print_prepare: 'Druckvorgang vorbereiten...',

	// dashboard
	overview: 'Übersicht',
	notes: 'Notizen',
	notes_and_bookmarks: 'Notizen & Lesezeichen',
	note: 'Notiz',
	bookmark: 'Lesezeichen',
	all_notes: 'Alle Notizen',
	events: 'Ereignisse',
	activities: 'Aktionen & Kalender',
	dashboard: 'Dashboard',
	timeframe: 'Zeitraum',
	links: 'Links',

	// survey
	survey_submit: 'Absenden',
	survey_completed: 'Vielen Dank für die Teilnahme!',
	surveys_title: 'Umfragen',
	no_surveys: 'Aktuell sind keine Umfragen verfügbar.',
	survey_new: 'Neue',
	survey: 'Umfrage',
	survey_answer: 'Antwort',

	// notes
	notes_title: 'Notizen & Lesezeichen',
	edit_note: 'Notiz bearbeiten',
	create_note: 'Notiz erstellen',
	note_cancel: 'Abbrechen',
	note_close: 'Schließen',
	note_save: 'Speichern',
	no_note: '-',
	no_notes: 'Es sind bisher keine Notitzen vorhanden.',
	note_edit: 'Bearbeiten',
	note_delete: 'Löschen',
	note_placeholder: 'Bitte geben Sie hier Ihre Notiz ein.',
	create_bookmark: 'Lesezeichen anlegen',
	note_last_update: 'vom',
};
