import { gql } from '@apollo/client';
import { FRAGMENT_POST_FIELDS } from './posts';

export const FRAGMENT_CATEGORY_FIELDS = gql`
	fragment CategoryFields on Category {
		id
		name
		increasesBadgeCount
		postIds
		imageUrl
		sortBy
		displayDate
		showAzList
		type
		color
		singlePost {
			...PostFields
		}
		parentCategory {
			id
		}
		subCategories {
			id
			increasesBadgeCount
			postIds
		}
		active @client
	}
	${FRAGMENT_POST_FIELDS}
`;

export const FRAGMENT_CATEGORY_POSTS = gql`
	fragment CategoryPosts on Category {
		posts {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_ALL_CATEGORIES = gql`
	query {
		categories {
			...CategoryFields
		}
	}
	${FRAGMENT_CATEGORY_FIELDS}
`;

export const QUERY_CATEGORY_BY_ID = gql`
	query Category($id: ID!) {
		category(id: $id) {
			...CategoryFields
		}
	}
	${FRAGMENT_CATEGORY_FIELDS}
`;

export const QUERY_CATEGORY_DEFAULT = gql`
	query CategoryDefault {
		categoryDefault {
			...CategoryFields
		}
	}
	${FRAGMENT_CATEGORY_FIELDS}
`;

export const QUERY_ACTIVE_CATEGORY = gql`
	query activeCategory {
		activeCategory @client {
			id
			name
		}
	}
`;

export const QUERY_OPEN_CATEGORIES = gql`
	query openCategories {
		openCategories @client
	}
`;
