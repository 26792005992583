import { composeCacheExtensions as composeCacheExtensionsInternal,
	composeCacheInitalState as composeCacheInitalStateInternal } from './compose';

let extensions = {};

export const addExtensions = (newExtensions) => {
	extensions = {
		...extensions,
		...newExtensions,
	};
};

export const getExtentions = () => (extensions);

export const composeCacheExtensions = () => composeCacheExtensionsInternal(extensions);
export const composeCacheInitalState = () => composeCacheInitalStateInternal(extensions);
