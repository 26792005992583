import { useQuery } from '@apollo/client';
import { QUERY_USER_ME } from '../../graphql/users';

export const useUserMe = () => {
	const { data, loading, error } = useQuery(QUERY_USER_ME, {
		pollInterval: 86400000,
	});

	const user = data?.me ?? {};
	return { user, loading, error };
};
