import usePostHasBeenRead from '../../../../../../BOWL-Common/hooks/posts/usePostHasBeenRead';

const PostUnreadBadge = ({ post }) => {
	const { category } = post;
	const hasBeenRead = usePostHasBeenRead({ postId: post.id });
	const showBadge = !hasBeenRead && category.increasesBadgeCount;

	if (showBadge) {
		return (
			<div className="flex-shrink-0 float-left w-3 h-3 mr-2 bg-red-600 rounded-full" />
		);
	}

	return null;
};

export default PostUnreadBadge;
