import { useEffect, useState } from 'react';

const useSinglePost = ({ category }) => {
	const [singlePost, setSinglePost] = useState(null);
	useEffect(() => {
		if (category.singlePost && category.singlePost[0]) {
			setSinglePost(category.singlePost[0]);
		} else {
			setSinglePost(null);
		}
	}, [category.singlePost]);

	return { singlePost };
};

export default useSinglePost;
