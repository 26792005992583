import { gql } from '@apollo/client';

export const FRAGMENT_USER_FIELDS = gql`
	fragment UserFields on User {
		id
		name
		email
		readPosts
		seenAlerts
		passwordNeedsChange
	}
`;

export const QUERY_USER_ME = gql`
	query Me {
		me {
			...UserFields
		}
	}
	${FRAGMENT_USER_FIELDS}
`;

export const MUTATE_USER_SEEN_CATEGORY = gql`
	mutation UserHasSeenCategory($categoryId: ID!) {
		userHasSeenCategory(input: {categoryId: $categoryId}){
			id
			lastSeen
			unreadPostCount
		}
	}
`;

export const MUTATE_USER_SEEN_ALERT = gql`
	mutation UserHasSeenAlert($alertId: ID!) {
		userHasSeenAlert(input: {alertId: $alertId}){
			id
			lastSeen
		}
	}
`;

export const MUTATE_USER_READ_POST = gql`
	mutation UserHasReadPost($postId: ID!) {
		userHasReadPost(input: {postId: $postId}){
			id
			readPosts
		}
	}
`;

export const MUTATE_USER_UNREAD_POST = gql`
	mutation UserHasUnreadPost($postId: ID!) {
		userHasUnreadPost(input: {postId: $postId}){
			id
			readPosts
		}
	}
`;

export const MUTATE_USER_HAS_READ_ALL_POSTS_OF_CATEGORY = gql`
	mutation userHasReadAllPostsOfCategory($categoryId: ID!) {
		userHasReadAllPostsOfCategory(input: {categoryId: $categoryId}){
			id
			readPosts
		}
	}
`;
