import { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIsAlertModalOpen } from '../../../BOWL-Common/hooks/global/useIsAlertModalOpen';
import { setIsAlertModalOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import { useAlertWithId } from '../../../BOWL-Common/hooks/alerts/useAlertWithId';
import { useSetSeenAlert } from '../../../BOWL-Common/hooks/alerts/useSetSeenAlert';

import useOpenPost from '../../../hooks/useOpenPost';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useOnKeydownEscape from '../../../hooks/useOnKeydownEscape';
import { CloseIcon, NotificationBellIcon, SpeakerPhoneIcon } from '../../partials/Icons';
import Modal from '../../general/Modal';

const AlertModalInner = ({ alertId }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { alert } = useAlertWithId(alertId);
	const openPost = useOpenPost();

	const [userHasSeenAlert] = useSetSeenAlert(alertId);
	useEffect(() => {
		userHasSeenAlert({ variables: { alertId }});
	}, [alertId, userHasSeenAlert]);

	const readMoreClicked = useCallback((e) => {
		setIsAlertModalOpenVar(false);
		e.preventDefault();
		e.stopPropagation();

		if (alert?.event) {
			const { event } = alert;
			navigate(`/calendar/${event.id}`);
		} else if (alert?.post) {
			const { post } = alert;
			openPost(post);
		}
	}, [alert, navigate, openPost]);

	const ref = useRef();
	useOnClickOutside(ref, () => setIsAlertModalOpenVar(false));
	useOnKeydownEscape(() => setIsAlertModalOpenVar(false));

	const isPinned = alert && alert.pinned;

	return (
		<div ref={ref} className={`inline-block align-middle px-4 pt-5 pb-4 overflow-hidden transition-all transform rounded-lg shadow-xl ${!isPinned ? 'bg-bowl-light' : 'bg-bowl-alert'} my-8 sm:min-w-25 sm:max-w-lg sm:w-full sm:p-6`} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			<AlertCloseIconButton clickHandler={() => setIsAlertModalOpenVar(false)} isPinned={isPinned} />
			<div className="sm:flex sm:items-start">
				<div className={`flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto ${!isPinned ? 'bg-red-300 text-white rounded-full' : 'bg-white text-bowl-alert rounded-lg'} sm:mx-0 sm:h-10 sm:w-10`}>
					{isPinned && <SpeakerPhoneIcon />}
					{!isPinned && <NotificationBellIcon />}
				</div>
				<div className={`mt-4 sm:mt-0 sm:ml-4 ${!isPinned ? 'text-bowl-typo-secondary' : 'text-white'} text-left`}>
					<h3 className="text-base leading-6 font-secondary sm:mr-4" id="modal-headline">
						{alert && alert.title}
					</h3>
					<div className="mt-3">
						<p className="text-sm leading-5">
							{alert && alert.message}
						</p>
					</div>
				</div>
			</div>
			<div className="items-center mt-4 sm:mt-6 sm:flex sm:w-full">
				<div className={`flex w-full ${alert?.post || alert?.event ? 'mb-2 sm:w-1/2 sm:mr-2 sm:mb-0' : ''}`}>
					<span className="flex w-full mt-3 rounded-md shadow-sm sm:mt-0">
						<button type="button" onClick={() => setIsAlertModalOpenVar(false)} className={`inline-flex justify-center w-full px-4 py-2 text-base leading-6 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm text-bowl-typo ${!isPinned ? '' : 'hover-hover:hover:bg-orange-100 focus:bg-orange-100'} hover-hover:hover:text-bowl-typo-secondary focus:outline-none focus:text-bowl-typo-secondary sm:text-sm sm:leading-5`}>
							{t('alert_close')}
						</button>
					</span>
				</div>
				{(alert?.post || alert?.event) && (
					<div className="flex w-full sm:w-1/2">
						<span className="flex w-full rounded-md shadow-sm">
							<button type="button" onClick={(e) => readMoreClicked(e)} className="inline-flex justify-center w-full px-4 py-2 text-base leading-6 transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm text-bowl-light bg-bowl-main hover-hover:hover:bg-bowl-dark focus:outline-none focus:bg-bowl-dark sm:text-sm sm:leading-5">
								{t('alert_read_message')}
							</button>
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

const AlertCloseIconButton = ({ clickHandler, isPinned }) => (
	<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
		<button type="button" onClick={clickHandler} className={`transition duration-150 ease-in-out ${!isPinned ? 'text-bowl-dark hover-hover:hover:text-bowl-main focus:text-bowl-main' : 'text-white hover-hover:hover:text-orange-100 focus:text-orange-100'} focus:outline-none `} aria-labelledby="close">
			<CloseIcon />
		</button>
	</div>
);

const AlertModal = () => {
	const { isAlertModalOpen, alertId } = useIsAlertModalOpen();

	return (
		<Modal show={isAlertModalOpen}>
			{/* This element is to trick the browser into centering the modal contents. */}
			<span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
			{ alertId && <AlertModalInner alertId={alertId} /> }
		</Modal>
	);
};

export default AlertModal;
