import { makeVar } from '@apollo/client';

const initialState = {
	activeAzIndex: null,
};

export const activeAzIndexVar = makeVar(initialState.activeAzIndex);
export const setActiveAzIndexVar = (azIndex) => activeAzIndexVar(azIndex);
export const clearActiveAzIndexVar = () => activeAzIndexVar(null);

export const azindex = {
	name: 'AzIndex',
	initialState: () => {
		activeAzIndexVar(initialState.activeAzIndex);
	},
	typePolicies: {
		Query: {
			fields: {
				activeAzIndex: {
					read: () => activeAzIndexVar(),
				},
			},
		},
	},
};

export default azindex;
