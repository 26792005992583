export const alerts = {
	name: 'Alerts',
	initialState: () => {
	},
	typePolicies: {
		Query: {
			fields: {
				alert(existingData, { args, toReference }) {
					return existingData || toReference({ __typename: 'Alert', id: args.id });
				},
			},
		},
	},
};

export default alerts;
