import { gql } from '@apollo/client';
import { FRAGMENT_PAGINATOR_INFO_FIELDS } from './paginatorInfo';

export const FRAGMENT_POST_FIELDS = gql`
	fragment PostFields on Post {
		id
		postId
		name
		teaserText
		teaserImageUrl
		template
		displayDate
		releasedAt
		publishedAt
		type
		isVideo
		content
		url
		pdfUrl
		docUrl
		connectedPosts {
			id
			postId
			name
			teaserText
			teaserImageUrl
			template
			displayDate
			releasedAt
			publishedAt
			type
			isVideo
			content
			url
			pdfUrl
			docUrl
			channel {
				id
				name
				imageUrl
			}
			category {
				id
				increasesBadgeCount
			}
		}
		channel {
			id
			name
			imageUrl
		}
		category {
			id
			name
			imageUrl
			showAzList
			increasesBadgeCount
			singlePost {
				id
			}
			parentCategory {
				id
				name
				imageUrl
			}
		}
		filters {
			id
			title
		}
	}
`;

export const QUERY_ALL_POSTS = gql`
	query {
		posts {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_ALL_POSTS_OF_CATEGORY_PAGINATED = gql`
	query PaginatedPosts($category_id: ID!, $page: Int) {
		paginatedPosts(category_id: $category_id, page: $page) {
			paginatorInfo {
				...PaginatorInfoFields
			}
			data {
				...PostFields
			}
		}
	}
	${FRAGMENT_PAGINATOR_INFO_FIELDS}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_ALL_POSTS_OF_CATEGORY = gql`
	query PostsByCategoryId($category_id: ID!) {
		postsByCategoryId(category_id: $category_id) {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_POST_BY_ID = gql`
	query Post($id: ID!) {
		post(id: $id) {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_POST_PREVIEW_BY_ID = gql`
	query PostPreview($id: ID!) {
		postPreview(id: $id) {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_NEWEST_POSTS = gql`
	query NewestPosts($count: Int!) {
		newestPosts(count: $count) {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_NEWEST_UNREAD_POSTS = gql`
	query NewestUnreadPosts {
		newestUnreadPosts {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;

export const QUERY_TRANSLATED_POST = gql`
	query translatePost($postId: ID!, $language: String!) {
		translatePost(postId: $postId, language: $language) {
			...PostFields
		}
	}
	${FRAGMENT_POST_FIELDS}
`;
