import { memo } from 'react';

export const Headline1 = ({ title, className = '', textAlign = 'text-center' }) => (
	<h1 className={`font-secondary font-bold text-3xl leading-10 text-bowl-typo ${textAlign} ${className}`}>
		{title}
	</h1>
);

export const Headline2 = ({ title, className = '', textAlign = 'text-center' }) => (
	<h2 className={`font-secondary text-2xl leading-9 text-bowl-typo ${textAlign} ${className}`}>
		{title}
	</h2>
);

export const Headline3 = ({ title, className = '', textAlign = 'text-center' }) => (
	<h3 className={`font-secondary text-lg leading-8 text-bowl-typo ${textAlign} ${className}`}>
		{title}
	</h3>
);

export const H1 = memo(Headline1);
export const H2 = memo(Headline2);
export const H3 = memo(Headline3);
