import { useTranslation } from 'react-i18next';
import { useIsSearchOverlayOpen } from '../../../BOWL-Common/hooks/global/useIsSearchOverlayOpen';
import { setIsSearchOverlayOpenVar } from '../../../BOWL-Common/apollo/extensions/global';
import Transition from '../../partials/Transition';
import ChannelWrapper from '../screens/posts/tiles/partials/ChannelWrapper';
import useOpenPost from '../../../hooks/useOpenPost';

const SearchOverlay = ({ posts, searchValue, loading, isTyping }) => {
	const { t } = useTranslation();
	const openPost = useOpenPost();
	const { isSearchOverlayOpen } = useIsSearchOverlayOpen();

	const postClicked = (post) => {
		setIsSearchOverlayOpenVar(false);
		openPost(post);
	};

	const renderSearchResults = () => {
		const showTopLevel = process.env.REACT_APP_NAVIGATION_SHOW_TOPLEVEL;
		if (!searchValue) {
			return (<span className="block px-4 py-2 text-sm leading-5 text-center text-bowl-typo-secondary h-9" role="listitem">{t('search_for_articles')}</span>);
		}

		if (isTyping || loading) {
			return (<span className="block px-4 py-2 text-sm leading-5 text-center text-bowl-typo-secondary h-9" role="listitem" />);
		}

		if (posts.length > 0) {
			return (
				<>
					{posts.map((post) => {
						if (post.category) {
							return (
								<div className="flex flex-col m-3 overflow-hidden bg-white border border-gray-100 rounded-lg shadow" key={`post_${post.id}`} role="listitem">
									<button type="button" onClick={() => postClicked(post)} className="focus:outline-none">
										<div className="flex flex-col items-center flex-1">
											<div className="flex flex-col w-full px-3 py-2 text-left font-secondary text-bowl-typo-secondary hover-hover:hover:bg-gray-50 hover-hover:hover:text-bowl-typo-secondary focus:outline-none focus:bg-gray-50 focus:text-bowl-typo-secondary">
												<span className="text-2xs">{post.category.name}</span>
												<span className="text-xs font-bold leading-4">{post.name}</span>
											</div>
											<div className="w-full">
												{(post.channel || showTopLevel) && <ChannelWrapper post={post} small />}
											</div>
										</div>
									</button>
								</div>
							);
						}
						return null;
					})}
				</>
			);
		}

		return (<span className="block px-4 py-2 text-sm leading-5 text-center text-bowl-typo-secondary" role="listitem">{t('search_no_result')}</span>);
	};

	return (
		<Transition
			show={isSearchOverlayOpen}
			enter="transition ease-out duration-100 origin-top"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-100 origin-top"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<div className="absolute left-0 w-full overflow-auto shadow-lg bg-bowl-background h-searchOverlay sm:rounded-md sm:left-auto sm:max-w-72 top-16 sm:top-auto sm:h-auto">
				<div className="w-full overflow-auto sm:shadow-xs sm:rounded-md sm:max-h-topBarList">
					<div role="list" aria-labelledby="search-result-list">
						{renderSearchResults()}
					</div>
				</div>
			</div>
		</Transition>
	);
};

export default SearchOverlay;
