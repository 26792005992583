import { makeVar } from '@apollo/client';

const initialState = {
	activeCategoryId: null,
	openCategories: [],
};

export const activeCategoryIdVar = makeVar(initialState.activeCategoryId);
export const setActiveCategoryVar = (categoryId) => activeCategoryIdVar(categoryId);
export const clearActiveCategoryVar = () => activeCategoryIdVar(initialState.activeCategoryId);

export const openCategoriesVar = makeVar(initialState.openCategories);
export const setOpenCategoriesVar = (categoryId) => {
	openCategoriesVar([...openCategoriesVar(), categoryId]);
};
export const removeOpenCategoriesVar = (categoryId) => {
	openCategoriesVar(
		openCategoriesVar().filter(
			(id) => id !== categoryId,
		),
	);
};

export const category = {
	name: 'Category',
	initialState: () => {
		activeCategoryIdVar(initialState.activeCategoryId);
		openCategoriesVar(initialState.openCategories);
	},
	typePolicies: {
		Query: {
			fields: {
				category(existingData, { args, toReference }) {
					return existingData || toReference({ __typename: 'Category', id: args.id });
				},
				activeCategory: {
					read: (_, { toReference }) => toReference({ __typename: 'Category', id: activeCategoryIdVar() }),
				},
				openCategories: {
					read: () => openCategoriesVar(),
				},
			},
		},
		Category: {
			fields: {
				active: {
					read: (_, { readField }) => readField('id') === activeCategoryIdVar(),
				},
			},
		},
	},
};

export default category;
