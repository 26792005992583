import LazyLoad from 'react-lazyload';
import useOpenPost from '../../../../../hooks/useOpenPost';
import { ArrowIcon } from '../../../../partials/Icons';
import ChannelWrapper from './partials/ChannelWrapper';
import PostUnreadBadge from './partials/PostUnreadBadge';
import PostMenu from './partials/PostMenu';

const PostClinic = ({ post }) => {
	const openPost = useOpenPost();

	return (
		<div className="bowl-post-button-wrapper">
			<div className="absolute ml-auto top-1 right-1">
				<PostMenu post={post} showPostId={!post.channel} />
			</div>
			<button type="button" onClick={() => openPost(post)} className="bowl-post-button-inner">
				<div className="flex flex-row">
					<div className="flex-shrink-0">
						<TeaserImage post={post} />
					</div>
					<div className="flex flex-row items-center flex-grow">
						<div className="flex-grow">
							<PostUnreadBadge post={post} />
							<h3 className="flex-grow bowl-post-headline">
								{post.name}
							</h3>
						</div>
						<span className="flex-shrink-0 mt-2 ml-4 mr-8">
							<ArrowIcon color="text-bowl-typo" />
						</span>
					</div>
				</div>
			</button>
			{post.channel && <ChannelWrapper post={post} />}
		</div>
	);
};

const TeaserImage = ({ post }) => (
	<div className={`rounded-lg bowl-post-teaser-image-wrapper ${post?.teaserImageUrl ? '' : 'bg-bowl-iconbg bg-clip-padding'}`}>
		{post?.teaserImageUrl && (
			<LazyLoad height={80} once placeholder={(<div className="lazy-load-wrapper-clinic w-20 h-20"><img src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt={post.name} /></div>)}>
				<div className="lazy-load-wrapper-clinic w-20 h-20">
					<img src={`${process.env.REACT_APP_BASE_URL}/api/img/${post.teaserImageUrl}?p=small`} alt={post.name} />
				</div>
			</LazyLoad>
		)}
		{!post?.teaserImageUrl && <img className="object-contain w-20 h-20 p-2" src={`${process.env.REACT_APP_BASE_URL}/small-logo`} alt="Logo" />}
	</div>
);

export default PostClinic;
