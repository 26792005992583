import { ApolloProvider } from '@apollo/client';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';

import Storage from 'react-native-storage';
import useInitApollo from '../BOWL-Common/hooks/apollo/useInitApollo';
import useInitMatomo from '../BOWL-Common/hooks/tracking/useInitMatomo';
import { BowlStorage } from '../BOWL-Common/storage/storage';

import LoadingScreen from './general/LoadingScreen';
import RootRouter from './routing/RootRouter';

const storage = new Storage({
	// maximum capacity, default 1000 key-ids
	size: 1000,

	// Use AsyncStorage for RN apps, or window.localStorage for web apps.
	// If storageBackend is not set, data will be lost after reload.
	storageBackend: window.localStorage, // for web: window.localStorage

	// expire time, default: 1 day (1000 * 3600 * 24 milliseconds).
	// can be null, which means never expire.
	defaultExpires: null,

	// cache data in the memory. default is true.
	enableCache: true,

	// if data was not found in storage or expired data was found,
	// the corresponding sync method will be invoked returning
	// the latest data.
	sync: {
	// we'll talk about the details later.
	},
});
BowlStorage.storage = storage;

const App = () => {
	const apolloClient = useInitApollo({
		gqlEndpointUrl: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GRAPHQL_ENDPOINT_PATH}`,
		csrfTokenEndpointPath: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CSRF_TOKEN_ENDPOINT_PATH}`,
	});

	const matomoInstance = useInitMatomo(process.env.REACT_APP_MATOMO_SITE_URL, process.env.REACT_APP_MATOMO_APP_ID);

	if (apolloClient && matomoInstance) {
		return (
			<MatomoProvider value={matomoInstance}>
				<ApolloProvider client={apolloClient}>
					<RootRouter />
				</ApolloProvider>
			</MatomoProvider>
		);
	}

	return <LoadingScreen bgColor="bg-bowl-public" />;
};

export default App;
