import useOpenCategory from '../../../../BOWL-Common/hooks/categories/useOpenCategory';
import Category from './Category';

const CategoryWrapper = ({ category }) => {
	const showTopLevel = process.env.REACT_APP_NAVIGATION_SHOW_TOPLEVEL;

	useOpenCategory(category, showTopLevel);

	return (
		<Category category={category} />
	);
};

export default CategoryWrapper;
