import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';

export const useTrackPageViewEffect = (title) => {
	const { trackPageView } = useMatomo();
	useEffect(() => {
		if (title) {
			trackPageView({ documentTitle: title });
		}
	}, [title, trackPageView]);
};
