import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { addExtensions, composeCacheExtensions, composeCacheInitalState } from './cache';

let apolloClient;
export const setClient = (client) => { apolloClient = client; };
export const getClient = () => apolloClient;

export default async (options, links, extensions) => {
	addExtensions(extensions);
	const { typePolicies, typeDefs } = composeCacheExtensions();
	const cache = new InMemoryCache({ typePolicies });
	const link = ApolloLink.from(links);
	let clientOptions = { ...options, cache, link };

	if (typeDefs && typeDefs !== '') {
		clientOptions = {
			...clientOptions,
			typeDefs,
		};
	}

	const client = new ApolloClient(clientOptions);
	setClient(client);

	cache.writeInitialState = () => {
		composeCacheInitalState();
		cache.reset();
	};

	client.onResetStore(() => {
		cache.writeInitialState();
	});
	client.onClearStore(() => {
		cache.writeInitialState();
	});

	return client;
};
