import { gql } from '@apollo/client';

export const FRAGMENT_NOTE_FIELDS = gql`
	fragment NoteFields on Note {
		id
		text
		updatedAt
		post {
			id
			postId
			name
			teaserText
			teaserImageUrl
			template
			displayDate
			releasedAt
			publishedAt
			type
			isVideo
			content
			url
			pdfUrl
			docUrl
			channel {
				id
				name
				imageUrl
			}
			category {
				id
				name
				imageUrl
				showAzList
				increasesBadgeCount
				singlePost {
					id
				}
				parentCategory {
					id
					name
					imageUrl
				}
			}
			filters {
				id
				title
			}
		}
	}
`;

export const QUERY_ALL_NOTES = gql`
	query Notes {
		notes {
			id
			text
			updatedAt
			post {
				id
				postId
				name
				teaserText
				teaserImageUrl
				template
				displayDate
				releasedAt
				publishedAt
				type
				isVideo
				content
				url
				pdfUrl
				docUrl
			}
		}
	}
`;

export const QUERY_NOTE_BY_ID = gql`
	query Note($id: ID!) {
		note(id: $id) {
			id
			text
			updatedAt
			post {
				id
				postId
				name
				teaserText
				teaserImageUrl
				template
				displayDate
				releasedAt
				publishedAt
				type
				isVideo
				content
				url
				pdfUrl
				docUrl
				category {
					id
					increasesBadgeCount
				}
			}
		}
	}
`;

export const MUTATE_CREATE_NOTE = gql`
	mutation CreateNote($text: String, $postId: ID) {
		createNote(input: { text: $text, postId: $postId }) {
			id
			text
			updatedAt
			post {
				id
				postId
				name
				teaserText
				teaserImageUrl
				template
				displayDate
				releasedAt
				publishedAt
				type
				isVideo
				content
				url
				pdfUrl
				docUrl
			}
		}
	}
`;

export const MUTATE_DELETE_NOTE = gql`
	mutation DeleteNote($noteId: ID!) {
		deleteNote(input: { noteId: $noteId }) {
			success
		}
	}
`;

export const MUTATE_EDIT_NOTE = gql`
	mutation EditNote($noteId: ID!, $text: String!) {
		updateNote(input: { noteId: $noteId, text: $text }) {
			id
			text
			updatedAt
			post {
				id
				postId
				name
				teaserText
				teaserImageUrl
				template
				displayDate
				releasedAt
				publishedAt
				type
				isVideo
				content
				url
				pdfUrl
				docUrl
			}
		}
	}
`;
