import React from 'react';

import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import useSetActiveCategory from '../../../../BOWL-Common/hooks/categories/useSetActiveCategory';
import { QUERY_CATEGORY_BY_ID } from '../../../../BOWL-Common/graphql/categories';
import ErrorScreen from '../../../general/ErrorScreen';
import LoadingScreen from '../../../general/LoadingScreen';
import CategoryWrapper from './CategoryWrapper';
import useOpenPost from '../../../../hooks/useOpenPost';

const CategoryById = () => {
	const openPost = useOpenPost();

	const { id } = useParams();
	const { data, loading, error, called } = useQuery(QUERY_CATEGORY_BY_ID, { variables: { id }});
	const category = data?.category ?? null;
	const singlePost = category?.singlePost?.[0] ?? null;

	useSetActiveCategory({ categoryId: category?.id });

	if (error) {
		return (<ErrorScreen error={error} />);
	}

	if (called && !loading && !error && singlePost) {
		openPost(singlePost, true);
	}

	if (called && !loading && !singlePost && !error) {
		if (category) {
			return <CategoryWrapper category={category} />;
		}
		return (<Navigate to="/" replace />);
	}

	return (<LoadingScreen />);
};

export default CategoryById;
