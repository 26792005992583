import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordMutation } from '../../../BOWL-Common/hooks/auth/useForgotPasswordMutation';
import { useTrackPageViewEffect } from '../../../BOWL-Common/hooks/tracking/useTrackPageViewEffect';
import SubmitButton from './partials/SubmitButton';
import InputField from './partials/InputField';
import ErrorMessage from './partials/ErrorMessage';
import TextLink from './partials/TextLink';
import { H3, H2 } from './partials/Headlines';

const ForgotPassword = () => {
	const { t } = useTranslation();
	useTrackPageViewEffect(t('forgot_password_title'));

	const [forgotPassword, { data, loading, error }] = useForgotPasswordMutation();

	const [email, setEmail] = useState('');
	const [uiState, setUiState] = useState('idle');
	const [errorMessage, setErrorMessage] = useState(null);
	const status = data?.forgotPassword?.status ?? '';

	useEffect(() => {
		if (loading === true) {
			setUiState('loading');
		} else if (status === '') {
			setUiState('idle');
		} else if (status === 'EMAIL_SENT') {
			setUiState('success');
		} else if (status === 'EMAIL_NOT_SENT') {
			setUiState('error');
			setErrorMessage(data.forgotPassword.message);
		}
	}, [status, data, loading]);

	useEffect(() => {
		if (error) {
			const { graphQLErrors } = error;
			if (graphQLErrors[0].extensions.category === 'validation') {
				const validationErrors = graphQLErrors[0].extensions.validation;
				setErrorMessage(Object.values(Object.values(validationErrors).map((validationError) => validationError.join('<br />'))).join('<br />'));
			} else {
				setErrorMessage(error.message);
			}
		} else {
			setErrorMessage(null);
		}
	}, [error]);

	const handleForgotPassword = () => {
		setErrorMessage(null);
		forgotPassword({ variables: { email }});
	};

	return (
		<div className="flex flex-col justify-center min-h-screen py-12 sm:pb-48 sm:px-6 lg:px-8">
			<div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
				<img className="w-auto h-12 mx-auto" src={`${process.env.REACT_APP_BASE_URL}/logo`} alt="Logo" />
				{ uiState !== 'success' && (
					<>
						<H2 title={t('forgot_password_title')} className="mt-8 text-bowl-typo-secondary" />
						<H3 title={t('forgot_password_subtitle')} className="mt-2 text-bowl-typo-secondary" />
					</>
				)}
				{ uiState === 'success' && (
					<>
						<H3 title={t('forgot_password_success')} className="mt-8 text-bowl-typo-secondary" />
						<div className="mt-8 text-center">
							<TextLink href="/login" text={t('back_to_login')} title={t('back_to_login')} />
						</div>
					</>
				)}
			</div>
			{ uiState !== 'success' && (
				<div className="relative mx-4 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
						<form action="#" method="POST" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
							<div>
								<InputField fieldName="email" label={t('forgot_password_email')} type="email" value={email} placeholder={t('forgot_password_email_placeholder')} handleChange={setEmail} />
								<ErrorMessage message={errorMessage} />
							</div>

							<div className="mt-6">
								<SubmitButton type="submit" title={t('forgot_password_button')} loading={loading} disabled={email === ''} handleClick={handleForgotPassword} />
							</div>
						</form>
						<div className="flex items-center justify-between mx-1 mt-8">
							<div className="flex items-center">
								<TextLink href="/login" text={t('back_to_login')} title={t('back_to_login')} />
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ForgotPassword;
